<template>
    <div class="content-page">
      <div v-if="winners.length > 0" class="winners-content">
        <div class="fixed-header">
          <div class="arrow-title">
            <div class="arrow">
              <img @click="goBack" :src="require('@/assets/arrow.svg')"/>
            </div>
            <div class="title">{{ $t('winners.title') }}</div>            
          </div>
          <div class="winners-header">
            <div class="main-header">
              <div :class="{ active: type == label.type, [label.className]: true }" v-for="label in labels" :key="label" @click="selectMainPeriod(label)">
              {{ label.title }}
              </div>
            </div>
            <div class="content-sub-header">
              <div class="sub-header">
                <div :class="{'selected': selectedPeriod == i }" v-for="i in periods[type]" :key="i" @click="selectPeriod(i)">
                {{ labels[type] + ' ' + i }}
                </div>                    
              </div>
            </div>
          </div>
        </div>
        
        <div v-if="type =='WEEKLY' && winners.WEEKLY" class="winners-items">
            <WinnerItem v-for="winner in winners.WEEKLY.filter(f => f.nrReward == selectedPeriod).slice(0, 1)" :key="winner.id"
              :image="winner.urlImage"
              :name="winner.name"
              :amount="winner.amount"
              size="medium"
              :message="$t('winners.winner')">
              طەست
            </WinnerItem>
            <div class="winnes-small-row">
                <WinnerItem v-for="winner in winners.WEEKLY.filter(f => f.nrReward == selectedPeriod).slice(1, 4)" :key="winner.id"
                  :image="winner.urlImage"
                  :name="winner.name"
                  :amount="winner.amount"
                  size="small"
                  :message="$t('winners.winner')">
                </WinnerItem>
            </div>
        </div>
        <div v-else-if="type =='MONTHLY' && winners.MONTHLY" class="winners-items">
          <WinnerItem v-for="winner in winners.MONTHLY.filter(f => f.nrReward == selectedPeriod).slice(0, 1)" :key="winner.id"
            :image="winner.urlImage"
            :name="winner.name"
            :amount="winner.amount"
            size="large"
            :message="$t('winners.winner')">
          </WinnerItem>           
        </div>
        <div v-else-if="type =='GRAND' && winners.GRAND" class="winners-items">
          <WinnerItem v-for="winner in winners.GRAND.filter(f => f.nrReward == selectedPeriod).slice(0, 1)" :key="winner.id"
            :image="winner.urlImage"
            :name="winner.name"
            :amount="winner.amount"
            size="large"
            :message="$t('winners.winner')">
          </WinnerItem>           
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Service } from "../services";
  import i18n from "@/translation";
  import { store, notify } from "../store";
  import WinnerItem from "../components/WinnerItem.vue";
  
  export default {
    name: "WinnersComponent",
    components: {
        WinnerItem
    },
    data() {
      return {
        winners: [{'WEEKLY': [], 'MONTHLY': [], 'GRAND': []}],
        type: "WEEKLY",
        periods: {'WEEKLY': [], 'MONTHLY': [], 'GRAND': []},
        labels: {'WEEKLY': {title:this.$t("winners.week"),className:"Week", type:"WEEKLY"}, 'MONTHLY': {title:this.$t("winners.month"),className:"Month", type:"MONTHLY"}, 'GRAND': {title:this.$t("winners.grand"),className:"Grand",type:"GRAND"},},
        selectedPeriod: 1
      };
    },
    methods: {
      goBack() {
        this.$router.back();
      },  
      selectPeriod(pos){
            this.selectedPeriod = pos
        },
        selectMainPeriod(period){
            this.type = this.getTypeByLabel(period)
            this.selectedPeriod = 1
            document.querySelector('.content-sub-header').scrollLeft = 0
            this.getWinners()
        },
        getTypeByLabel(label) {
          return Object.keys(this.labels).find(key => this.labels[key] === label);
        },
        /*
        changeCloned(items, nrReward){
          function uuid(){ var dt = new Date().getTime(); var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) { var r = (dt + Math.random()*16)%16 | 0;dt = Math.floor(dt/16);return (c=='x' ? r :(r&0x3|0x8)).toString(16);}); return uuid;}
          return items.map((i) => { let obj = JSON.parse(JSON.stringify(i));obj.id = uuid();obj.nrReward = nrReward;obj.name = i.name + ' ' + nrReward; return obj })   
        },
        */
        async getWinners() {
            let response = await Service.getWinners(this.type, new Date().getFullYear());
            if (response && response.status === 200) {  
                console.log(response)
                let data = {[this.type]: response.data }
                //let data = {[this.type]: response.data[this.type]}
                //let cloned = JSON.parse(JSON.stringify(data[this.type]))
                //for(let i=2; i < 10; i++){ let _cloned = this.changeCloned(cloned, i); data[this.type] = data[this.type].concat(_cloned)}
                
                let sortedData = data[this.type].sort((a, b) => {
                    return (a.nrReward - b.nrReward) || (b.reward.value - a.reward.value);
                })
                let periods = {[this.type]: []}
                sortedData.forEach(s => {
                    s.amount = parseInt(s.reward.value).toLocaleString('en-US')
                    if(periods[this.type].indexOf(s.nrReward) < 0)
                        periods[this.type].push(s.nrReward)
                })
                this.$set(this.winners, this.type, sortedData)
                this.$set(this.periods, this.type, periods[this.type])
                //console.log(sortedData)
            }
        },
    },
    computed: {
      language() {
        return i18n.locale;
      },
    },
    mounted() {
      store.layout.showUserWallet = true;
      store.layout.footerItem = "play-match";
      store.layout.footerType = "FooterHome";
      store.layout.showGoBack = true;
      this.getWinners()
      store.layout.showLanguageSwitch = true

    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .content-page {
    justify-content: flex-start;
  }
  .winners-content {
    padding: 0px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .fixed-header {
    position: absolute;
    width: 100%;
    z-index: 100;
  }

  .arrow-title {
    display: flex;
    justify-items: center;
    align-items: center;
  }

  .arrow {
    padding-left: 15px;
    width: 55px;
  }

  .arrow-title img {
    vertical-align: middle;
  }

  .arrow-title .title {
    padding-right: 55px;
  }

  .title {
    background-size: cover;
    width: 100%;
    color: #ffffff;
    font-size: 26px;
    padding: 0;
  
    padding: 8px 0;
    background: none
  }
 
  .winners-header {
    display: flex;
    flex-direction: column;
  }

  .main-header div {
    padding: 2px 0;
    text-transform: capitalize;
  }
  
  .Month {
    width: 33%;
    border-right: #96979c solid 1px;
  }

  .Week {
    width: 34%;
    border-right: #96979c solid 1px;
  }
  
  .Grand {
    width: 33%;
  }
  
  .Week.active {
    background-color: #a9fe06;
    color: #2a3757;
    border-top-left-radius: 10px;
    border-bottom: none;
    border-right:none
  }
  
  .Month.active {
    background-color: #a9fe06;
    color: #2a3757;
    border-bottom: none;
    border-right:none
  }
  
  .Grand.active {
    background-color: #a9fe06;
    color: #2a3757;
    border-top-right-radius: 10px;
    border-bottom: none;
  }

  .sub-header {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  .sub-header::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }

  .sub-header {
    border: 1px solid #c2c1c1;
    border-top: none;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: space-between;
  }

  .sub-header div {
    text-transform: capitalize;
    height: 18px;
    margin: 5px 15px;
    flex: 0 0 auto;
  }

  .sub-header div.selected {
    border-bottom: 1px solid #a9fe06;
  }
  
  .winners-items {
    background-color: #232c41;
    color: #ffffff;
    padding-bottom: 0px;
    height: calc(100% - 90px);
    margin-top: 90px;
    width: 100%;
    overflow-y: scroll;
    padding-bottom: 20px;
    padding-top: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .winnes-small-row {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .main-header {
    background-color: #445C87;
    display: flex;
    width: 100%;
    color: #232C40;
    font-size: 19px;
    padding: 1px 0;
    padding-bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  </style>
  