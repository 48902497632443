<template>
  <span>
    <div id="app" v-loading.fullscreen.lock="showLoading" :style="{ direction: computedDirection }">

      <div class="content">
        <header-component v-if="showHeader"></header-component>
        <router-view :style="{ 'visibility': showLoading ? 'hidden' : 'visible' }"></router-view>

      </div>
      <get-coins-component></get-coins-component>



      <component :is="footerComponent"></component>
      <!-- <modal-more-component v-if="showModalMore"></modal-more-component> -->

    </div>
    <div v-if="showLogoutOverlay" class="overlay-content">
      <span @click="doLogout" class="btn-logout">
        {{ $t("general.logout") }}
      </span>
    </div>
  </span>
</template>

<script>
import { store } from "./store";
import HeaderComponent from "./components/Header.vue";
import FooterHome from "./components/FooterHome.vue";
import FooterInitial from "./components/FooterInitial.vue";
import GetCoinsComponent from './components/GetCoins.vue';
import ModalMoreComponent from './components/ModalMore.vue';
import i18n from "./translation";
import { Service } from "./services";
import moment from "moment";

export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterHome,
    GetCoinsComponent,
    ModalMoreComponent,
    FooterInitial
  },
  data() {
    return {
      height: '100%',
    }
  },
  computed: {
    computedDirection() {
      return this.$i18n.locale === 'en' ? 'ltr' : 'rtl';
    },
    showLoading() {
      return store.layout.showLoading;
    },
    showLogoutOverlay() {
      return store.layout.showLogoutOverlay;
    },
    showUserWallet() {
      return store.layout.showUserWallet
    },
    showGetCoins() {
      return store.layout.showGetCoins
    },
    showModalMore() {
      return store.layout.showModalMore
    },
    showHeader(){
      return store.layout.showHeader
    },
    hasFooter(){
      return store.layout.footerType
    },
    footerComponent() {
      return store.layout.footerType ? store.layout.footerType : '';
    }
  },
  methods: {
    doLogout() {
      store.layout.showLoading = false;
      localStorage.removeItem("userToken");
      store.userToken = "";
      store.layout.showLogoutOverlay = false;
      this.$router.push("/");
    },
    setAppHeight() {
      let headerHeight = 0;
      if(this.showHeader)
        headerHeight = this.showUserWallet ? 95 : 60;
      let footerHeight = this.hasFooter ? 55 : 0;
      this.height = (window.innerHeight - (headerHeight + footerHeight)) + 'px';
      document.documentElement.style.setProperty("--appHeight", this.height);
    },
    // async getUserIpInfo() {
    //   let response = await Service.getUserIpInfo();
    //   if (response && response.status == 200) {
    //     localStorage.setItem("userCountryCode", response.data['calling_code'])
    //     if (response.data['calling_code'] == "+966") {
    //       window.location.href = "https://torliga.com/ksa";
    //     } else if (response.data['calling_code'] == "+972") {
    //       window.location.href = "https://torliga.com/ps";
    //     }
    //   }
    // }
  },
  created() {
    // const storedLanguage = localStorage.getItem('selectedLanguage') ?? 'en';
    // if (storedLanguage) {
    //   i18n.locale = storedLanguage;
    // }

    if (localStorage.getItem("userCountryCode") == null) {
      // const response = this.getUserIpInfo()
    } else {
      if (localStorage.getItem("userCountryCode") == "+966") {
        window.location.href = "https://torliga.com/ksa";
      } else if (localStorage.getItem("userCountryCode") == "+972" || localStorage.getItem("userCountryCode") == "+970") {
        window.location.href = "https://torliga.com/ps";
      }
    }
  },
  mounted() {
    //window.addEventListener("resize", this.setAppHeight);
    window.visualViewport.addEventListener('resize', this.setAppHeight);
    window.visualViewport.addEventListener("focus", this.setAppHeight);
    window.visualViewport.addEventListener("blur", this.setAppHeight);
    this.setAppHeight();
    let urlParams = new URLSearchParams(window.location.search);
    let lang = urlParams.get("lang");
    if (lang && ["en", "ar", "ku"].indexOf(lang) > -1) {
      i18n.locale = lang;
    } else if (lang && ["kr"].indexOf(lang) > -1) {
      i18n.locale = "ku";
    }
    store.serviceLang = lang && ["en", "ar", "ku", "kr"].indexOf(lang) > -1 ? lang : "en";

    document.documentElement.style.setProperty("--appFontFamily", i18n.locale == "ku" ? "Rudaw Regular" : "Rabar_031");
    document.documentElement.style.setProperty("--appFontWeight", 400);

    let langStorage = localStorage.getItem("lang");
    if (langStorage) {
      i18n.locale = langStorage;
    } else {
      localStorage.setItem("lang", 'ar');
    }
    i18n.locale === 'en' ? moment.locale("en") : i18n.locale === 'ar' ? moment.locale("ar") : moment.locale("ku")




  },
  watch: {
    showHeader() {
      if(!this.showHeader)
        document.documentElement.style.setProperty("--contentTop", '0px');
      else
        document.documentElement.style.setProperty("--contentTop", this.showUserWallet ? '95px' : '60px');
      this.setAppHeight();
    },
    showUserWallet() {
      if(!this.showHeader)
        document.documentElement.style.setProperty("--contentTop", '0px');
      else
        document.documentElement.style.setProperty("--contentTop", this.showUserWallet ? '95px' : '60px');
      this.setAppHeight();
    },
    hasFooter() {
      document.documentElement.style.setProperty("--contentBottom", this.hasFooter ? '55px' : '0px');
      this.setAppHeight();
    },
  }
};
</script>

<style>




@font-face {
  font-family: 'Myriad Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: url('@/assets/fonts/myriadpro.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  src: url('@/assets/fonts/Poppins.ttf');
}

@font-face {
  font-family: 'Rudaw Regular';
  font-style: normal;
  font-weight: normal;
  src: url('@/assets/fonts/rudawregular2.woff') format('woff');
}

@font-face {
  font-family: 'Rabar_031';
  src: url('@/assets/fonts/Rabar_031.ttf');
}

@font-face {
  font-family: 'DIN_Next_LT_Arabic';
  src: url('@/assets/fonts/DIN_Next_LT_Arabic.ttf');
}

:root {
  --appHeight: 100%;
  --contentTop: 60px;
  --contentBottom: 0px;
  --contentBalanceBG: none;
}

html,
body {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100%;
  background-color: #2c3e50;
  font-family: var(--appFontFamily);
  font-weight: 400;
  overflow: hidden;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  -webkit-text-size-adjust: none;
  touch-action: pan-y;
  /*prevent user scaling*/
}

@media (max-width: 600px) {
  #app {
    background: linear-gradient(to left, #182252, #0C1538);
    max-width: 600px;
  }


}

@media (min-width: 600px) {
  #app {
    background: linear-gradient(to left, #51607D, #51607D);
  }

  .content {
    max-width: 650px;
  }
}

.content::-webkit-scrollbar {
  display: none;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 auto;
  margin-top: 0;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  background-size: 100vw 100vw;
}

.content {
  background: #0C1538;
  margin: 0 auto;
  /* top: var(--contentTop);
  bottom: var(--contentBottom); */
  left: 0px;
  right: 0px;
  width: 100%;
  overflow-y: scroll;
  padding: 0px 0px;
  position: absolute;
  -ms-overflow-style: none;
  scrollbar-width: none;
 height: 100%;
 overflow-x: hidden !important;
}

.content-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;
}

.notify-class {
  font-weight: 400;
}

.el-notification.notify-class {
  /* position: absolute !important; */
  z-index: 99995 !important;
}

.el-notification__title {
  font-size: 18px !important;
}

.el-notification__content {
  font-size: 16px !important;
}

.el-loading-mask.is-fullscreen {
  z-index: 99990 !important;
  width: 100%;
}

:focus-visible {
  outline: none;
}



.el-loading-spinner .path {
  stroke: #232C40;
}

.el-notification__content {
  text-align: right !important;
}

.overlay-content {
  position: absolute;
  z-index: 99991;
  top: calc(100vh / 2 + 80px);
  text-align: center;
  width: 100%;
}

.overlay-content .btn-logout {
  border: solid 1px #000;
  padding: 8px 15px;
  border-radius: 5px;
  color: #aaff01;
  background-color: rgb(28, 0, 73);
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.95;
  cursor: pointer;
}

/* Bug v-modal ios  */
.v-modal {
  /* z-index: 0 !important; */
}

.el-scrollbar__bar {
  opacity: 0.8 !important;
}

.rtlDirection {
  direction: rtl;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.el-select .el-input__inner  {
  text-transform: uppercase !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



/* sidebar container */

.el-drawer{
  width: 65% !important;
  background: #182252 !important;
}


@media (min-width: 768px) {
  .el-drawer{
  width:  30% !important;
  background: #182252 !important;
}

}


@media (max-width: 768px) {
  .el-dialog {
  width: 90% !important;
}

}

/* tabbar */
.el-tabs__header{
    background: #182252 !important;
    padding:0px 10px 0px 10px  !important;
  }

  .demo-tabs{
    margin-top: 10px !important;
  }

  .el-tabs__active-bar {
    background: #D74EF8 !important;
  }

  .el-tabs__active-bar {
    height: 3px !important;
  }

  .el-tabs__item {
    color: #ffffff !important;
    font-size: 16px !important;
    font-weight: 400 !important;

  }

  .el-tabs__nav-wrap::after {
    background: transparent !important;
  }



.gr-btn-item {
  align-items: center !important;
}
.gr-btn-item i{
  color:#3D4676 !important
}

.gr-btn-container-active{
  background: #D74EF8 !important;
  color: #ffffff !important;
}

.gr-btn-icon-active{
  color: #ffffff !important;
}

.gr-animated-title-active{
  color: #ffffff !important;
}

.gr-animated-title{
  left: 0px !important;
}

.gr-btn-icon-active i{
  color: #ffffff !important;
}

.el-carousel__indicators--horizontal{
  width: 100% !important;
}


</style>
