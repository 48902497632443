<template>
  <div align="center">
    <div class="arrow-title">
      <div class="arrow">
        <img @click="goBack" :src="require('@/assets/arrow.svg')"/>
      </div>
      <div class="title">{{ $t("forgot_password.title") }}</div>            
    </div>
    <div style="max-width: 250px;">
      <CustomInput 
        :otp="true"
        style="margin-top: 15px;" 
        :type="'number'" 
        :label="$t('inputs.otp')" 
        @setInput="onInputOtp"
        v-maska="'#'">
      </CustomInput>
      <div>
        <el-button
            @click="this.verify"
            :class="{'button': true, 'button-valid': true}"
            type="primary">
            {{ $t("forgot_password.verify") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { maska } from "maska";
import i18n from "@/translation";
import { store, notify } from "../store";
import CustomInput from "../components/CustomInput.vue"

export default {
  name: "ForgotPasswordOtpComponent",
  props: {
    msg: String,
  },
  components: {
    CustomInput
  },
  directives: { maska },
  data() {
    return {
      otp: ["","","",""],
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    goToRoute(route) {
      this.$router.push("/" + route);
    },
    onInputOtp(event){
      this.otp[event[1]-1] = event[0];
      if(event[1]<=3)
        document.getElementsByName(event[1])[0].focus()
    },
    verify(){
      if(this.otp[0]+this.otp[1]+this.otp[2]+this.otp[3] == store.pinCode){
        this.goToRoute("forgot-password-create-password")
      }else{
        notify(this.$t("error.error"),this.$t("error.otp_invalid"),"error");
      }
    }
  },
  computed: {
    language() {
      return i18n.locale;
    },
  },
  mounted() {
    store.layout.showGoBack = false;
    store.layout.footerType = 'FooterInitial'
    store.layout.showUserWallet = false;
    store.layout.textHeader = 'Log In'
    store.layout.showLanguageSwitch = true
  },
};
</script>

<style scoped>
.arrow-title {
    display: flex;
    justify-items: center;
    align-items: center;
    height: 54px;
  }
  .arrow {
    padding-left: 15px;
    width: 55px;
  }

  .arrow-title img {
    vertical-align: middle;
  }

  .arrow-title .title {
    padding-right: 55px;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-size: cover;
    width: 100%;
    color: #fff;
    padding: 0;
    font-family: 'Rabar_031';
    font-size: 26px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
  }
.button-valid {
  background-color: #aaff03 !important;
  color: #26314A !important;
}
.button{
  width: 100%; 
  border-radius: 6px !important;
  font-family: 'Rabar_031';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #fff;
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}
.el-button--primary {
  background-color: #445C87;
  border: 0px;
  border-radius: 20px;
  line-height: 15px !important;
}

@media (max-width: 600px) {
  .login-icons-page {
    font-size: 14px;
    margin-top: 30px;
  }

  .login-icons-page > div{
    width: 100px;
  }
  .login-icons-page > div img{
    height: 20px;
    margin-left: 5px;
  }
}
</style>
