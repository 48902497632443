import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "ar",
  messages: {
    en: {
      inputs: {
        phone_only_numbers: "+964",
        phonenumber_long: "Enter your phone number",
        phonenumber_short: "Phone Number",
        credit_card: "Credit card number",
        expiration_date: "Expiration date",
        cvv: "CVV",
        name_card: "Name on card",
        name: "Name",
        pin: "Enter your Vouche PIN",
        otp: "Enter your OTP",
        username: "Username",
        email_phone: "Phone Number",
        email_phone_placeholder: "Example@GoalPro.com or +9647",
        answer: "Answer",
        first_name: "First Name",
        last_name: "Last Name",
        name_placeholder: "Your Name",
        email: "Email Address",
        email_placeholder: "Example@GoalPro.com",
        country: "Country",
        country_placeholder: "Your country",
        city: "City",
        city_placeholder: "Your city",
        password: "Password",
        password_placeholder: "Minimum 8 characters",
        create_password: "Create Password",
        create_password_placeholder: "Minimum 8 characters",
        confirm_password: "Confirm Password",
        confirm_password_placeholder: "Minimum 8 characters",
        optional: "Optional",
        subject: "Subject",
        message: "Messages",
      },
      general: {
        points: "Points: ",
        coins: "Coins: ",
        coins_min: " coins",
        days: " days",
        versus: "vs",
        second_choice_unlocked: "2nd choice unlocked",
        unlock_second_choice: "Unlock 2nd choice by 2 coins",
        english: "English",
        en: "EN",
        arabic: "Arabic",
        ar: "AR",
        kurdish: "Kurdish",
        ku: "KU",
        logout: "Logout",
        success: "Success",
        unsubscribe: 'Unsubscribe',
        daysOfWeek: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday'],
        currency: "IQD",
        skip: "Skip"
      },
      footer: {
        home: "Home",
        quiz: "Daily Quiz",
        playMatch: "Play Match",
        status: "Status",
        more: "More",
        terms: "Terms & Conditions",
        policy: "Privacy Policy",
        about: "About Us"
      },
      login: {
        enter_details: "Log In",
        verify_code: "Verify Code",
        description: "Please enter your GoalPro account details to Log In",
        send_sms: "Subscribe",
        terms_and_conditions: "Terms and Conditions",
        privacy_policy: "Privacy Policy",
        close_modal: "Close",
        welcome_sub_message_1: "Welcome to GoalPro!",
        welcome_sub_message_2: "1 day is free and 300 IQD / Day. To unsubscribe send 0 to 3838 (Free of charge)",
        invalid_email: "Type a valid E-mail",
        invalid_username: "Type a valid username",
        forgot_password: "Forgot Password?",
        login: "Log In",
        keep_loged: "Keep me loged in",
        dont_have_account: "Dont have an account?",
        signup: "Sign Up",
        ads: "Watch Ads to get X coins to try the application",
        one_upper: "Password must contain at least one upper letter",
        one_digit: "Password must contain at least one digit",
        one_symbol: "Password must contain at least one symbol",
        disclaimer:"For Zain subscribers, the service price is 240 IQD To unsubscribe, send UGP to 3368"
      },
      favourites: {
        favourites: "Favourites",
        reached_maximum: "You reached the maximum favourites for this category",
        continents: {
          ASIA: 'Asia',
          AFRICA: 'Africa',
          EUROPE: 'Europe',
          NORTHAMERICA: 'North America',
          SOUTHAMERICA: 'South America',
          OCEANIA: 'Oceania'
        },
        error_submit: "Error on saving favourites",
        select_your_favourites: "Select Your Favourites",
        your_favourites: "Your Favourites",
        national_teams: "National Teams",
        leagues_up: "Leagues",
        leagues: "leagues",
        popular_clubs: "Popular Clubs",
        your_favourite_clubs: "Your Favourite Clubs",
        popular_national_teams: "Popular National Teams",
        your_favourite_national_teams: "Your Favourite National Teams",
        select_language: "Select a League",
        select_continent: "Select a Continent",
        modify: "Modify",
        continue: "Continue",
        submit: "Submit",
        search: "Search",
      },
      favourites_team_group: {
        view_more: "View More",
        view_less: "View Less"
      },
      loginValidation: {
        enter_pin: "Enter the 4-digit PIN code sent to your mobile number",
        validate: "Validate",
        resend_pin: "Click here to resend the PIN Code",
      },
      home: {
        points: "Points",
        coins: "Coins",
        ranking: "Ranking",
        play: "Play",
        dailyQuiz: "Daily Quiz",
        headToHead: "Head To Head",
        ads: "Watch Ads to get X coins to try the application",
        subscribe_now: "Subscribe Now",
        weekly_prize: "Weekly Prize",
        weekly_prizes_value: "750,000",
        monthly_prize: "Monthly Prize",
        monthly_prize_value: "2,000,000",
        grand_prize: "Grand Prize",
        grand_prize_value: "15,000,000",
        currency: "IQD",
        favourites: "Favourites",
        add: "Add",
        gameEnd: "The game Ended",
        questionAnswer: "Question & Answer",
      },
      quiz: {
        title: "Quiz",
        submit: "Submit",
        next_question: "Next Question",
        points_earned: "Earned ",
        get: "Get",
        needCoins: "No More Coins",
        points: "points",
        coins: "coins",
        weekly_prize: "Weekly Prize",
        weekly_prize_value: "750,000",
        monthly_prize: "Monthly Prize",
        monthly_prize_value: "2,000,000",
        grand_prize: "Grand Prize",
        grand_prize_value: "15,000,000",
        currency: "IQD"
      },
      newsMatchResults: {
        title: "Match Result and Prizes",
        matchResults: "Match Results",
        footballNews: "Football News",
        leagueStandings: "League Standings",
        prizes: "Prizes",
      },
      status: {
        title: 'Status',
        current: 'Current',
        history: 'History',
        points: 'points',
        congratulations: 'Congratulations you collected',
        congratulations_kurdish: 'پیرۆزە',
        collect_kurdish: 'خاڵت بە دەست هێنا'
      },
      playMatch: {
        title: "Play Match",
        submit: "Submit",
        checkout: "Check out",
        draw: "Draw",
        win: "Win",
        notSelected: "Not Selected",
        points_earned: "Points earned: ",
        submit_and_pay: "Submit and Pay ",
        coins: "coins",
        add_coins: "Add more points",
        points_per_coin: "(100 points per coin)",
        edit_prediction: "Edit Prediction",
        success: "Prediction saved",
        totalPoints: "Total Points",
        totalCoin: "Total Coin",
        edit_favourites: 'Edit',
        my_favourites: 'My Favourites',
        select_match: "Please select a match to predict",
        delayed: "Delayed"
      },
      matchResults: {
        title: "Match Results",
        draw: "Draw",
        awaiting_results: "Awaiting results",
        total: "Total: ",
        points: "points",
      },
      getCoins: {
        submit: "Submit",
        select_label: "Select an option",
        enter_pin: "Enter the 4-digit PIN code sent to your mobile number",
        select_amount: "Select an amount of coins and submit your request",
        promo: "Do you want to get more coins 50% off?",
        recharge: "Sorry, no enough balance, recharge and try again",
        prices: {
          label_3: "Get 3 Coins by 300 IQD",
          label_6: "Get 6 Coins by 600 IQD",
          label_9: "Get 9 Coins by 900 IQD",
          label_12: "Get 12 Coins by 1,200 IQD",
          label_48: "Get 48 Coins by 3,600 IQD",
          label_70: "Get 70 Coins by 5,000 IQD",
        },
        promoPrices: {
          label_3: "Get 6 Coins by 300 IQD",
          label_6: "Get 12 Coins by 600 IQD",
          label_9: "Get 18 Coins by 900 IQD",
          label_12: "Get 24 Coins by 1,200 IQD",
        },
        buy: 'Buy',
        close: "Close"
      },
      error: {
        error: "Note",
        connexion_error: "A connection error occurred",
        auth_error: "Please sign-in again",
        invalid_phone_number: "Invalid Phone Number",
        otp_request_exceeded: "Exceeded Login Attempts, please use the Last OTP received",        
        otp_request_not_subscribed: "This mobile number is not a subscriber on this service",
        select_quiz_response: "Select an answer",
        select_play_match: "You have to predict all matches before submit",
        maximum_quiz_reached: "Maximum quiz questions was reached",
        invalid_pin_code: "Invalid PIN code",
        waiting_subscription: "Waiting for subscription...",
        three_answer: "User needs to answer 3 questions at least",
        email_invalid: "E-mail is invalid",
        country_required: "Country is required",
        otp_invalid: "OTP is invalid",
        first_name_required: "First Name is required",
        last_name_required: "Last Name is required",
        password: {
          required: "Password is required",
          confirm_identical: "Password and confirm, must be identical",
          eight_character: "Password must be at least 8 characters long",
          one_lower: "Password must contain at least one lower letter",
          one_upper: "Password must contain at least one upper letter",
          one_digit: "Password must contain at least one digit",
          one_symbol: "Password must contain at least one symbol"
        }
      },
      more: {
        title: "More",
        manage_account: "Manage Account",
        about_us: "About Us",
        languages: {
          text: "Languages",
          english: "English",
          arabic: "عربي",
          kurdish: "کوردی",
        },
        notifications: "Notifications",
        FaQs: "FAQs",
        terms: "Terms & Conditions",
        privacy_policy: "Privacy Policy",
        contact_us: "Contact Us",
        winners: "GoalPro Winners",
        theme: {
          text: "Theme",
          ligth: "Ligth Theme",
          dark: "Dark Theme",
          green: "Green",
          yallow: "Yallow",
          red: "Red"
        },
        league: "League standing",
        sign_out: "Sign Out",
      },
      winners: {
        title: "GoalPro Winners",
        winner: "The winner",
        week: 'Week',
        month: 'Month',
        grand: 'Grand'
      },
      league_list: {
        title: "League Standing"
      },
      manage_account: {
        title: "Manage Account",
        save: "Save Changes",
        error: {
          first_name: "First Name is required",
          last_name: "Last Name is required",
          country: "Country is required",
          email: "E-mail is invalid"
        }
      },
      faqs: {
        title: "FAQs",
        torliga: "Whats GoalPro",
        play: "How I can Play",
        points: "How I can collect points",
        win_prizes: "How I can win the prizes",
        whats_prizes: "Whats Prizes",
        coins: "How I can buy coins"
      },
      forgot_password: {
        title: "Forgot Password?",
        save: "Save",
        verify: "Verify",
        continue: "Continue",
        answer_below: "Answer below security question to create new password"
      },
      signup: {
        title: "Create your account",
        subtitle: "No commitments, cancel anytime",
        continue: "Continue",
        text1: "By proceeding, you confirm that you have read and agreed to the",
        text2: "T&Cs",
        text3: "Have an account?",
        login: "Log In",
        security_questions: "Security Questions"
      },
      choose_subscription: {
        title: "Choose your Subscription",
        weekly_plan: "Weekly Plan",
        weekly_plan_value_1: "2,100",
        weekly_plan_value_2: "9,000",
        six_month_plan: "6 Month Plan",
        six_month_plan_value: "54,000",
        twenty_five_coins: "25 coins",
        seven_days: "7 days",
        get_chance_win: "Get chance to win",
        top_scorer: "Top Scorer",
        for_three_winners: "For 3 winners / Draw",
        weekly_prizes: "Weekly prizes",
        weekly_prizes_top_value: "300,000 IQD",
        weekly_prizes_for_three_value: "150,000 IQD",
        monthly_prize: "Monthly prize",
        monthly_prize_value: "2,000,000 IQD",
        grand_prize: "Grand prize",
        grand_prize_value: "15,000,000 IQD",
        currency: "IQD",
        subsribe: "Subscribe"
      },
      subscription: {
        title: "Enter your payment details",

        text1: "Choose another payment method",
        methods: {
          voucher: "Voucher",
          visa: "VISA",
          master: "Master Card",

        },
        pay: "Pay",
        confirm: "Confirm"
            },
      languages: {
        title: "Languages",
      },
      comingSoon: {
        title: "Coming Soon",
      },
      account: {
        title: "Manage Account",
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        submit: "Submit",
      },
      about: {
        title: "About Us",
      },
      privacy_policy: {
        title: "Privacy Policy",
      },
      terms: {
        title: "Terms & Conditions",
      },
      news: {
        title: "News",
      },
      leagues: {
        title: "Leagues",
        worldCup: "World Cup - 2022",
        Laliga: "Laliga - 2022",
      },
      league: {
        title: "League Standing",
        pos: "POS",
        team: "Team",
        pl: "PL",
        gw: "GW",
        total: "Total"
      },
      prizes: {
        title: "Prizes",
        weekly: "How can you be a weekly Top Scorer? Simply, By answering as many questions and Predicting matches as well as adding more points. The winner will get IQD 300,000 in Cash. There is more! Torliga will have a draw to choose 3 more winners and each will get IQD 150,000",
        monthly: " IQD 2,000,000 for a winner through a draw.",
        grand: "A lucky Winner will win  IQD 15,000,000!"
      },
      logout: {
        msg: "Are you sure you want to sign out?",
        yes: "Yes, Logout",
        no: "No, keep me in"
      },
      needCoins: {
        buy: 'Get Coins',
        back: 'Back',
        textOld: 'You don\'t have enough coins to submit your prediction list, please buy more coins',
        text:'You have used all your daily coins. You will receive 5 more coins tomorrow.'
      },
      needSubscribe: {
        subscribe: 'Subscribe',
        watchAds: 'Watch Ads',
        text: 'To submit your prediction list, please select one of the below options'
      },
      subscription_types: {
        0: {
          plan: 'Weekly Plan',
          value: '2,100 IQD'
        },
        1: {
          plan: 'Monthly Plan',
          value: '9,000 IQD'
        },
        2: {
          plan: '6 Month Plan',
          value: '54,000 IQD'
        }
      },
      ranking: {
        title: "Ranking",
        week: "Week",
        month: "Month",
        overall: "Overall",
        pos: "POS",
        overallPoints: "Overall Points",
        username: "Username",
        weekPoints: "Week Points",
        monthPoints: "Month Points"
      },
      headToHead: {
        playerName: "Player Name",
        buyCoins: "Buy Coins",
        ranking: "Ranking",
        score: "Score",
        overview: "Overview",
        overviewTable: {
          name: "Name: ",
          Points: " Points: ",
          points: "Head to Head Points",
          coins: "Coins: ",
          ranking: "Ranking: ",
        },
        getStarted: "Get Started",
        Start: "Start",
        chooseYourPlan: "Choose your plan",
        plans: {
          playCoins: "Play # Coins",
          getPoints: "Get # Points"
        },
        play: "Play",
        prize: "Prize",
        noOfQuestion: "No.of question",
        noOfPoints: "No.of points",
        matchMacts: "Match Facts",
        totalPointsEarned: "Total Points Earned",
        pointsEarned: "Points Earned",
        rematch: "Rematch",
        newMatch: "New Match",
        backToHome: "Back",
        searchForPlayer: "Search For Player",
        selectRandomly: "Select randomly",
        choosePlayer: "Choose a player",
        nextQuestion: "Next Question in # sec",
        waiting: "Waiting...",
        points: "points",
        matchFacts: "Match Facts",
        congratulations: "Congratulations to # for winning the competition. You earned # points and # extra points!",
        competitionDraw: "The competition was a draw. You got # points and # extra points!",
        userConnected: "User is already connected",
        accept: "Accept",
        decline: "Decline",
        msgPlayWithMsisdn: "The Player #, want's to play with you",
        msgPlayerNotFound: "Player not found",
        close: "Close",
        you: "You",
        error:{
          'cant-play-with-yourself':{
            msg: 'Can not request a match to yourself'
          },
          'not-enough-questions':{
            msg: 'Not enough to head questions found'
          },
          'user-has-not-balance':{
            msg: 'User has not enough balance'
          },
          'match-not-found':{
            msg: 'Match not found'
          },
          'user-already-answered':{
            msg: 'User already answered this question'
          },
          'unsubscribed-user':{
            msg: 'User not subscribed'
          },
          'unauthorized-user':{
            msg: 'Unauthorized user'
          },
          'match-has-active-question':{
            msg: 'This match has an active question ...'
          }
        }
      },
    },


    ar: {
      inputs: {
        phone_only_numbers: "+964",
        phonenumber_long: "أدخل رقم جوالك",
        phonenumber_short: "رقم جوالك",
        credit_card: "رقم البطاقة الائتمانية",
        expiration_date: "تاريخ انتهاء الصلاحية",
        cvv: "CVV",
        name_card: "الإسم على البطاقة",
        name: "الإسم",
        pin: "أدخل رمز PIN الخاص بالقسيمة",
        otp: "أدخل رمز التحقق الخاص بك",
        username: "إسم المستخدم",
        email_phone: "رقم الجوال",
        email_phone_placeholder: "example@XXXX.com or +9647",
        answer: "الإجابة",
        first_name: "الإسم الأول",
        last_name: "إسم العائلة",
        name_placeholder: "إسمك",
        email: "عنوان البريد الإلكتروني",
        email_placeholder: "Example@XXXXXXX.com",
        country: "البلد",
        country_placeholder: "البلد",
        city: "المدينة",
        city_placeholder: "المدينة",
        password: "كلمة المرور",
        password_placeholder: "يجب أن تكون كلمة السر 8 حروف على الأقل",
        create_password: "أنشئ كلمة مرور",
        create_password_placeholder: "يجب أن تكون كلمة السر 8 حروف على الأقل",
        confirm_password: "تأكيد كلمة المرور",
        confirm_password_placeholder: "يجب أن تكون كلمة السر 8 حروف على الأقل",
        optional: "إختياري",
        subject: "الموضوع",
        message: "الرسالة",
      },
      general: {
        points: "النقاط: ",
        coins: "كوينز: ",
        versus: "vs",
        second_choice_unlocked: "توفر الآن الخيار الثاني",
        unlock_second_choice: "إفتح الخيار الثاني بـ ٢ كوين",
        english: "إنكليزي",
        arabic: "عربي",
        kurdish: "كردي",
        'en':'EN',
        'ku':'KU',
        'ar':'AR',
        logout: "تسجيل الخروج",
        success: "بنجاح",
        unsubscribe: 'إلغاء الإشتراك',
        daysOfWeek: ['الأحد', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت']
      },
      login: {
        enter_details: "أدخل رقم جوالك",
        verify_code: "التحقق من الرمز",
        description: "الرجاء إدخال تفاصيل حساب كول برو الخاص بك لتسجيل الدخول",
        send_sms: "إشترك",
        terms_and_conditions: "الأحكام والشروط",
        privacy_policy: "سياسة الخصوصية",
        close_modal: "إغلاق",
        welcome_sub_message_1: "كول برو ترحب بك",
        welcome_sub_message_2: "اليوم الأول مجاني ثم 300 د.ع يومياً. لإلغاء الإشتراك أرسل 0 الى XXXX (مجاناً)",
        invalid_email: "يرجى إدخال بريد إلكتروني صحيح",
        invalid_username: "يرجى إدخال إسم مستخدم صحي",
        forgot_password: "هل نسيت كلمة السر؟",
        login: "تسجيل الدخول",
        keep_loged: "إحفظ تسجيل دخولي",
        dont_have_account: "ليس لديك حساب؟",
        signup: "إشتراك",
        ads: "  شاهد الإعلانات للحصول على X كوينات لتجربة التطبيق  ",
        one_upper: "Password must contain at least one upper letter",
        one_digit: "Password must contain at least one digit",
        one_symbol: "Password must contain at least one symbol",
        disclaimer:" لمشتركي زين سعر الخدمة 240 دينار عراقي. لإلغاء الإشتراك من الخدمة أرسل UGP إلى 3368. "

      },
      footer: {
        home: "الرئيسية",
        quiz: "السؤال",
        playMatch: "إلعب",
        status: "الحالة",
        more: "المزيد",
        terms: "البنود",
        policy: "سياسة الخصوصية",
        about: "معلومات عنا"
      },
      loginValidation: {
        enter_pin: "أدخل رمز التحقق المرسل إلى رقمك",
        validate: "تحقق",
        resend_pin: "إضغط هنا لإعادة إرسال رمز التحقق",
      },
      favourites: {
        favourites: "المفضلة",
        reached_maximum: "لقد وصلت إلى الحد الأعلى من الإضافة إلى المفضلة لهذه الفئة",
        continents: {
          ASIA: 'آسيا',
          AFRICA: 'أفريقيا',
          EUROPE: 'أوروبا',
          NORTHAMERICA: 'أمريكا الشمالية',
          SOUTHAMERICA: 'أمريكا الجنوبية',
          OCEANIA: 'أوقيانوسيا'
        },
        error_submit: "خطأ في حفظ المفضلة",
        select_your_favourites: "حدد المفضلة الخاصة بك",
        your_favourites: "مفضلاتك",
        national_teams: "المنتخبات الوطنية",
        leagues_up: "الدوريات",
        leagues: "بطولات الدوري",
        popular_clubs: "النوادي الشعبية",
        your_favourite_clubs: "النوادي المفضلة لديك",
        popular_national_teams: "المنتخبات الوطنية الشعبية",
        your_favourite_national_teams: "فرقك الوطنية المفضلة",
        select_language: "إختر الدوري",
        select_continent: "حدد القارة",
        modify: "تعديل",
        continue: "إستمر",
        submit: "تأكيد ",
        search: "بحث",
      },
      home: {
        points: "النقاط",
        coins: "كوينز",
        ranking: "تصنيف",
        play: "إلعب",
        dailyQuiz: "السؤال اليومي",
        headToHead: "المواجهة",
        ads: "شاهد الإعلانات لتحصل على X کوینز لتجربة التطبيق",
        subscribe_now: "إشترك الآن",
        weekly_prize: "الجائزة الأسبوعية",
        weekly_prizes_value: "750,000",
        monthly_prize: "الجائزة الشهرية",
        monthly_prize_value: "2,000,000",
        grand_prize: "الجائزة الكبرى",
        grand_prize_value: "15,000,000",
        currency: "IQD",
        favourites: "المفضلة",
        add: "إضافة",
        gameEnd: "إنتهت المباراة",
        questionAnswer: "سؤال و جواب",
      },
      quiz: {
        title: "أجب على الأسئلة",
        submit: "تأكيد",
        next_question: "السؤال التالي",
        points_earned: "النقاط المكتسبة",
        get: "تحتاج",
        needCoins: "لم يتبق لديك كوينز",
        points: "النقاط",
        coins: "كوين",
        weekly_prize: "الجائزة الأسبوعية",
        weekly_prize_value: "750,000",
        monthly_prize: "الجائزة الشهرية",
        monthly_prize_value: "2,000,000",
        grand_prize: "الجائزة الكبرى",
        grand_prize_value: "15,000,000",
        currency: "IQD"
      },
      newsMatchResults: {
        title: "نتيجة المباراة والجوائز",
        matchResults: "نتائج المباريات",
        footballNews: "أخبار كرة القدم",
        leagueStandings: "ترتيب الدوريات",
        prizes: "الجوائز",
      },
      status: {
        title: 'الحالة',
        current: 'الحالية',
        history: 'السابقة',
        points: 'النقاط',
        congratulations: 'مبروك جمعت ',
        congratulations_kurdish: 'تهانينا',
        collect_kurdish: 'لقد حصلت على نقطة'
      },
      playMatch: {
        title: "توقع مَن الرابح",
        submit: "تأكيد",
        draw: "تعادل",
        points_earned: "النقاط المكتسبة",
        submit_and_pay: "تأكيد ودفع",
        edit_prediction: "تعديل التوقع",
        coins: "كوين",
        add_coins: "أضف نقاط",
        points_per_coin: "١٠٠ نقطة لكل كوين",
        checkout: "تابع",
        win: "إربح",
        notSelected: "لم يتم الاختيار",
        success: "تم حفظ التوقع",
        totalPoints: "مجموع النقاط:",
        totalCoin: "مجموع الكوين:",
        edit_favourites: 'تعديل',
        my_favourites: 'مفضلاتي',
        select_match: "الرجاء تحديد التوقع",
        delayed: "Delayed"
      },
      matchResults: {
        title: "نتائج المباريات",
        draw: "تعادل",
        awaiting_results: "في إنتظار النتائج",
        total: "المجموع",
        points: "نقطة",
      },
      getCoins: {
        submit: "تأكيد",
        select_label: "حدد إختيارك",
        enter_pin: "أدخل رمز التحقق المرسل إلى رقمك",
        select_amount: "حدد عدد الكوينز وأرسل طلبك",
        promo: "هل ترغب في الحصول على المزيد من الكوينز بخصم 50٪؟",
        recharge: "عذراً، ليس لديك رصيد كافٍ",
        prices: {
          label_3: " ٣ كوين بـ ٣٠٠ د.ع",
          label_6: " ٦ كوين بـ ٦٠٠ د.ع",
          label_9: "٩ كوين بـ ٩٠٠ د.ع",
          label_12: "١٢ كوين بـ ١،٢٠٠ د.ع",
          label_48: "٤٨ كوين بـ ٣،٦٠٠ د.ع",
          label_70: "٧٠ كوين بـ ٥،٠٠٠ د.ع",
        },
        promoPrices: {
          label_3: " ٦ كوين بـ ٣٠٠ د.ع",
          label_6: " كوين بـ ٦٠٠ د.ع ١٢",
          label_9: " كوين بـ ٩٠٠ د.ع ١٨",
          label_12: "كوين بـ ١,٢٠٠ د.ع ٢٤",
        },
        buy: 'شراء',
        close: "إغلاق"
      },
      error: {
        error: "ملاحظة",
        connexion_error: "حدث خطأ في الشبكة",
        auth_error: "الرجاء تسجيل الدخول مرة أخرى",
        invalid_phone_number: "رقم الجوال غير صحيح",
        otp_request_exceeded: "تم تجاوز محاولات تسجيل الدخول ، يرجى استخدام آخر كلمة مرور تم استلامها",   
        otp_request_not_subscribed: "رقم الجوال المدخل غير مشترك في الخدمة",
        select_quiz_response: "إختر إجابة",
        select_play_match: "عليك أن تتوقع نتائج جميع المباريات قبل الإرسال",
        maximum_quiz_reached: "لقد وصلت إلى الحد الأعلى من أسئلة الاختبار",
        invalid_pin_code: "رمز التحقق غير صالح",
        waiting_subscription: "قيد الإشتراك",
        three_answer: "يجب الإجابة على 3 أسئلة على الأقل",
        email_invalid: "البريد الإلكتروني غير صالح",
        country_required: "يرجى إختيار البلد",
        otp_invalid: "رمز التحقق خاطئ",
        first_name_required: "يرجى إدخال الإسم الأول",
        last_name_required: "يرجى إدخال إسم العائلة",
        password: {
          required: "يرجى إدخال كلمة المرور",
          confirm_identical: "يجب أن تكون كلمة المرور والتأكيد متطابقتين",
          eight_character: "يجب أن تكون كلمة المرور 8 أحرف على الأقل",
          one_lower: "يجب أن تكون كلمة المرور 8 أحرف على الأقل",
          one_upper: "يجب أن تحتوي كلمة المرور على حرف علوي واحد على الأقل",
          one_digit: "يجب أن تحتوي كلمة المرور على رقم واحد على الأقل",
          one_symbol: "يجب أن تحتوي كلمة المرور على رمز واحد على الأقل"
        }
      },
      more: {
        title: "أكثر",
        manage_account: "إدارة الحساب",
        about_us: "مزود الخدمة",
        languages: {
          text: "اللغات",
          english: "إنكليزي",
          arabic: "عربي",
          kurdish: "كردي",
        },
        notifications: "الإشعارات",
        FaQs: "الأسئلة المتداولة",
        terms: "الشروط والأحكام",
        privacy_policy: "سياسة الخصوصية",
        contact_us: "إتصل بنا",
        winners: " الرابحين",
        theme: {
          text: "الموضوع",
          ligth: "وضع الخافت",
          dark: "وضع الداكن",
        },
        league: "مكانة الدوري",
        sign_out: "تسجيل الخروج",
      },
      winners: {
        title: "الرابحين",
        winner: "الفائز",
        week: 'أسبوع',
        month: 'شهر',
        grand: 'الكبرى'
      },
      league_list: {
        title: "مكانة الدوري"
      },
      manage_account: {
        title: "إدارة الحساب",
        save: "حفظ",
        error: {
          first_name: "يرجى إدخال الإسم الأول",
          last_name: "يرجى إدخال إسم العائلة",
          country: "يرجى إختيار البلد",
          email: "يرجى إدخال عنوان بريد إلكتروني صحيح",
        }
      },
      faqs: {
        title: "الأسئلة المتداولة",
        Goal_Pro: "ماهي كول برو",
        play: "كيف يمكنني اللعب",
        points: "كيف يمكنني جمع النقاط",
        win_prizes: "كيف يمكنني الفوز بالجوائز",
        whats_prizes: "ما هي الجوائز",
        coins: "كيف يمكنني شراء كوينز"
      },
      forgot_password: {
        title: "هل نسيت كلمة السر؟",
        save: "حفظ",
        verify: "تحقق",
        continue: "تابع",
      },
      signup: {
        title: "إشترك الآن",
        subtitle: "لا توجد إلتزامات، يمكنك إلغاء الإشتراك في أي وقت",
        continue: "تابع",
        text1: "من خلال المتابعة، فإنك تؤكد أنك قد قرأت ووافقت على",
        text2: "الشروط والأحكام",
        text3: "هل لديك حساب؟",
        login: "تسجيل الدخول",
        security_questions: "أسئلة الأمان"
      },
      choose_subscription: {
        title: "إختر إشتراكك",
        weekly_plan: "خطة أسبوعية",
        weekly_plan_value_1: "2,100",
        weekly_plan_value_2: "9,000",
        six_month_plan: "خطة 6 أشهر",
        six_month_plan_value: "54,000",
        twenty_five_coins: "25 كوينز",
        seven_days: "7 أيام",
        get_chance_win: "أحصل على فرصة للفوز",
        top_scorer: "الأعلى نقاطا",
        for_three_winners: "لـ 3 فائزين / سحب",
        weekly_prizes: "الجوائز الأسبوعية",
        weekly_prizes_top_value: "300,000 IQD",
        weekly_prizes_for_three_value: "150,000 IQD",
        monthly_prize: "الجائزة الشهرية",
        monthly_prize_value: "2,000,000 IQD",
        grand_prize: "الجائزة الكبرى",
        grand_prize_value: "15,000,000 IQD",
        currency: "د.ع",
        subsribe: "إشترك"
      },
      subscription: {
        title: "أدخل تفاصيل الدفع الخاصة بك",

        text1: "اختر طريقة دفع أخرى",
        methods: {
          voucher: "فاتورة",
          visa: "فيزا كارد",
          master: "ماستر كارد",

        },
        pay: "دفع",
        confirm: "تأكيد"
      },
      contact: {
        title: "من نحن",
        email: "info@xxxx.com",
        fb: "xxxxx",
        instagram: "xxxxxxx",


      },
      privacy_policy: {
        title: "سياسة الخصوصية",
      },
      terms: {
        title: "الشروط والأحكام",
      },
      news: {
        title: "خبر",
      },
      leagues: {
        title: "البطولات",
        worldCup: "كأس العالم - 2022",
        Laliga: "لاليجا - 2022",
      },
      league: {
        title: "مكانة الدوري",
        pos: "POS",
        team: "فريق",
        gw: "المباريات التي لعبتها",
        pl: "اخر 5 مباريات",
        total: "مجموع النقاط"
      },
      prizes: {
        title: "جوائز",
        weekly: "هل ترغب في أن تكون أحد الأبطال الاسبوعيين صاحبي اعلى النقاط؟ طريقة الاشتراك سهلة جدا! قم بارسال توقعاتك والإجابة على أكبر عدد من الأسئلة واحصل على المزيد من النقاط، كلما زادت نقاطك كلما تزيد فرصك للحصول على 300,000 دينار كاش. لدينا جوائز اكثر! حيث سيربح 3 مشتركين محظوظين بالسحب وسيحصل كل منهم على 150,000 دينار.",
        monthly: "2,000,000 دينار لرابح السحب.",
        grand: "سيربح فائز محظوظ بـ 15,000,000 دينار"
      },
      logout: {
        msg: "هل أنت متأكد أنك تريد الخروج؟",
        yes: "نعم",
        no: "لا"
      },
      needCoins: {
        buy: 'أحصل على الكوينز',
        back: 'رجوع',
        textOld: 'ليس لديك ما يكفي من الكوينز. لإرسال قائمة التوقعات الخاصة بك ، يرجى شراء المزيد من الكوينز',
        text:'لقد استخدمت الكوينز المتاحة لليوم ، ستحصل على 5 كوين أخرى غداً.'
      },
      needSubscribe: {
        subscribe: 'إشترك',
        watchAds: 'مشاهدة الإعلانات',
        text: 'لتقديم قائمة التوقعات الخاصة بك ، يرجى تحديد أحد الخيارات أدناه'
      },
      subscription_types: {
        0: {
          plan: 'خطة أسبوعية',
          value: '2,100 IQD'
        },
        1: {
          plan: 'خطة شهرية',
          value: '9,000 IQD'
        },
        2: {
          plan: 'خطة 6 اشهر',
          value: '54,000 IQD'
        }
      },
      ranking: {
        title: "التصنيف",
        week: "أسبوع",
        month: "شهر",
        overall: "كلي",
        pos: "POS",
        overallPoints: "النقاط الإجمالية",
        username: "إسم المستخدم",
        weekPoints: "نقاط الأسبوع",
        monthPoints: "نقاط الشهر"

      },
      headToHead: {
        playerName: "إسم اللاعب",
        buyCoins: "شراء كوينز",
        ranking: "التصنيف",
        score: "النتيجة",
        overview: "ملخص",
        overviewTable: {
          name: "الإسم: ",
          Points: "نقاط: ",
          points: "نقاط المواجهة",
          coins: "كوينز: ",
          ranking: "التصنيف: ",
        },
        getStarted: "إبدأ",
        Start: "إبدأ",
        chooseYourPlan: "إختر خطتك",
        plans: {
          playCoins: "إلعب 2 كوين",
          getPoints: "أحصل على 100 نقطة"
        },
        play: "إلعب",
        prize: "الجائزة",
        noOfQuestion: "عدد الأسئلة",
        noOfPoints: "عدد النقاط",
        matchMacts: "عن المباراة",
        totalPointsEarned: "مجموع النقاط المكتسبة",
        pointsEarned: "النقاط التي أحرزتها",
        rematch: "إعادة المباريات",
        newMatch: "مباراة جديدة",
        backToHome: "العودة",
        searchForPlayer: "إبحث عن لاعب",
        selectRandomly: "إختر عشوائيا",
        choosePlayer: "إختر لاعبًا",
        nextQuestion: "السؤال التالي بعد # ثانية",
        waiting: "إنتظر...",
        points: "نقاط",
        matchFacts: "عن المباراة",
        congratulations: "مبروك ل #الفوز بالمسابقة. لقد حصلت على # نقطة و# نقطة إضافية!",
        competitionDraw: "وكانت نتيجة المنافسة التعادل. لقد حصلت على # نقطة و # نقطة إضافية!",
        userConnected: "المستخدم متصل بالفعل",
        accept: "قبول",
        decline: "رفض",
        msgPlayWithMsisdn: "اللاعب #، يريد أن يلعب معك",
        msgPlayerNotFound: "اللاعب غير موجود",
        close: "إغلاق",
        you: "أنت",
        error:{
          'cant-play-with-yourself':{
            msg: 'لا يمكنك طلب مباراة لنفسك'
          },
          'not-enough-questions':{
            msg: 'لم يتم العثور على ما يكفي من الأسئلة المباشرة'
          },
          'user-has-not-balance':{
            msg: 'المستخدم ليس لديه رصيد كافي'
          },
          'match-not-found':{
            msg: 'لم يتم العثور على المباراة'
          },
          'user-already-answered':{
            msg: 'أجاب المستخدم بالفعل على هذا السؤال'
          },
          'unsubscribed-user':{
            msg: 'المستخدم غير مشترك'
          },
          'unauthorized-user':{
            msg: 'المستخدم غير مصرح به'
          },
          'match-has-active-question':{
            msg: 'يوجد سؤال عن هذه المباراة'
          }
        }
      }
    },


    ku: {
      inputs: {
        phone_only_numbers: "+964",
        phonenumber_long: "ژمارەی مۆبایلت داخل بکە",
        phonenumber_short: "ژمارەی تەلەفۆن",
        credit_card: "ژمارەی کارتی متمانە",
        expiration_date: "بەرواری بەسەرچوون",
        cvv: "CVV",
        name_card: "ناو لەسەر کارت",
        name: "ناو",
        pin: "PIN  کۆدەکەت داخڵ بکە",
        otp: "OTP داخڵ بکە",
        username: "ناوی بەکارهێنەر",
        email_phone: "ژمارەی تەلەفۆن",
        email_phone_placeholder: "XXXXXXXXX.com or +9647",
        answer: "وەڵام",
        first_name: "ناوی یەکەم",
        last_name: "ناوی کۆتایی",
        name_placeholder: "ناوت",
        email: "ناونیشانی پۆستی ئەلکترۆنی",
        email_placeholder: "XXXXXXXXXX.com",
        country: "وڵات",
        country_placeholder: "ووڵاتەکەت",
        city: "شار",
        city_placeholder: "شارەکەت",
        password: "نهێنوشە",
        password_placeholder: "لانی کەم ٨ پیت",
        create_password: "نهێنوشە درووستبکە",
        create_password_placeholder: "لانی کەم ٨ پیت",
        confirm_password: "ووشەی نھێنی دووپات بكەوە",
        confirm_password_placeholder: "لانی کەم ٨ پیت",
        optional: "ئارەزوومەندانە",
        subject: "بابەت",
        message: "پەیام",
      },
      general: {
        points: "خاڵ: ",
        coins: "كۆين: ",
        versus: "vs",
        second_choice_unlocked: "بژاردەی دووەم کرایەوە",
        unlock_second_choice: "بژاردەی دووەم بکەوە بە ٢ کۆین",
        english: "ئینگلیزی",
        arabic: "عەرەبی",
        kurdish: "کوردی",
        'en':'EN',
        'ku':'KU',
        'ar':'AR',
        logout: "هاتنە دەرەوە",
        success: "سەرکەوتن",
        unsubscribe: 'ڕاگرتنی بەشداریکردن',
        daysOfWeek: ['یەکشەممە', 'دووشەممە', 'سێشەممە', 'چوارشەممە', 'پێنجشەممە', 'هەینی', 'شەممە']
      },
      footer: {
        home: "سەرەکی",
        quiz: "پرسیار",
        playMatch: "يارى بكه",
        status: "بار",
        more: "زیاتر",
        terms: "مەرج و ڕێساکان",
        policy: "سیاسەتی پاراستنی نهێنی",
        about: "دەربارەی ئێمە"
      },
      login: {
        enter_details: "ژمارەکەت بنووسە",
        verify_code: "کۆدی ژمارەکەت بنووسە",
        description: "تکایە وردەکارییەکانی ئەکاونتی GOAL PRO بنووسە بۆ چوونە ژوورەوە",
        send_sms: "بەشداربە",
        terms_and_conditions: "بەند و مەرجەکان",
        privacy_policy: "سیاسەتی تایبەتمەندی",
        close_modal: "دایخە",
        welcome_sub_message_1: "بەخێرهاتنتان دەکات Goal pro",
        welcome_sub_message_2: "یەکەم ڕۆژ بەخۆڕاییە و دواتر ڕۆژانە 300 د.ع. بۆ ڕاگرتنی بەشداریکردن 0 بنێرە بۆ XXXX(بەخۆڕایی)",
        invalid_email: "ئیمەیڵێکی دروست بنووسە",
        invalid_username: "ناوی بەکارهێنەرێکی دروست بنووسە",
        forgot_password: "پاسۆردت لەبیر کردووە؟",
        login: "چونه‌ ژووره‌وه‌",
        keep_loged: "من لە چوونەژوورەوە بهێڵەوە",
        dont_have_account: "ئەکاونتت نییە؟",
        signup: "خۆت تۆمار بکە",
        ads: "سەیری ڕیکلام بکە بۆ وەرگرتنی X کۆین بۆ تاقیکردنەوەی ئەپەکە.",
        one_upper: "Password must contain at least one upper letter",
        one_digit: "Password must contain at least one digit",
        one_symbol: "Password must contain at least one symbol",
        disclaimer :"بۆ بەشداربووانی زەین نرخی خزمەتگوزاری 240 د.ع بۆ ڕاگرتن، UGP بنێرە بۆ 3368"
      },
      loginValidation: {
        enter_pin: "کۆدی ژمارەکەت بنووسە کە نێردرا بۆت",
        validate: "سەلماندن",
        resend_pin: "ئێرە دابگرە بۆ دووبارە ناردنەوەی کۆد",
      },
      favourites: {
        favourites: "دڵخوازەکان",
        reached_maximum: "گەیشتووی بە زۆرترین پەسەندکراو بۆ ئەم پۆلە",
        continents: {
          ASIA: 'ئاسیا',
          AFRICA: 'ئەفریقا',
          EUROPE: 'ئەوروپا',
          NORTHAMERICA: 'ئەمریکای باکور',
          SOUTHAMERICA: 'ئەمریکای باشوور',
          OCEANIA: 'ئۆقیانووس'
        },
        error_submit: "هەڵەیەک ڕوویدا لە ناردنی پەسەندکراوەکانت",
        select_your_favourites: "دڵخوازەکانت هەڵبژێرە",
        your_favourites: "دڵخوازەکانت",
        national_teams: "هەڵبژاردە نیشتمانییەکان",
        leagues_up: "خولەکان",
        leagues: "خولەکان",
        popular_clubs: "یانە بەناوبانگەکان",
        your_favourite_clubs: "یانە دڵخوازەکانت",
        popular_national_teams: "هەڵبژاردە نیشتمانییە بەناوبانگەکان",
        your_favourite_national_teams: "هەڵبژاردە نیشتمانییە دڵخوازەکانت",
        select_language: "خولێک هەڵبژێرە",
        select_continent: "کیشوەرێک هەڵبژێرە",
        modify: "دەستکاری بکە",
        continue: "بەردەوام بوون",
        submit: "پەسەند بکە",
        search: "گەڕان",
      },
      home: {
        points: "خاڵ",
        coins: "کۆین",
        ranking: "ڕیزبەندی",
        play: "يارى بكه",
        dailyQuiz: "پرسیاری ڕۆژانە",
        headToHead: "ڕکابەری",
        ads: "سەیری ڕیکلام بکە بۆ بەدەستهێنانی x کۆین بۆ تاقیکردنەوەی بەرنامەکە",
        subscribe_now: "ئێستا بەشداری بکە",
        weekly_prize: "خەڵاتی هەفتانە",
        weekly_prizes_value: "750,000",
        monthly_prize: "خەڵاتی مانگانە",
        monthly_prize_value: "2,000,000",
        grand_prize: "خەڵاتی گەورە",
        grand_prize_value: "15,000,000",
        currency: "د.ع",
        favourites: "دڵخوازەکان",
        add: "زیاد بکە",
        gameEnd: "یاریەکە تەواوبوو",
        questionAnswer: "پرسیار & وەڵام",
      },
      quiz: {
        title: "وەلامی پرسیارەکان بدە",
        submit: "پەسەند بکە",
        next_question: "پرسیاری داهاتوو",
        points_earned: "خاڵی بەدەستهێنراو",
        get: "پێوەستت بە",
        needCoins: "کۆینت نییە",
        points: "خاڵ",
        coins: "کۆین",
        weekly_prize: "خەڵاتی هەفتانە",
        weekly_prize_value: "750,000",
        monthly_prize: "خەڵاتی مانگانە",
        monthly_prize_value: "2,000,000",
        grand_prize: "خەڵاتی گەورە",
        grand_prize_value: "15,000,000",
        currency: "د.ع"
      },
      newsMatchResults: {
        title: "ئەنجامی یارییەکان و خەڵاتەکان",
        matchResults: "ئەنجامی یارییەکان",
        footballNews: "هەواڵی تۆپی پێ",
        leagueStandings: "ڕیزبەندی خولەکان",
        prizes: "خەڵاتەکان",
      },
      status: {
        title: 'بار',
        current: 'لە ئێستادا',
        history: 'ئەنجامی پێشوو',
        points: 'خاڵ',
        congratulations: 'پیرۆزە خاڵت بە دەست هێنا: ',
        congratulations_kurdish: 'پیرۆزە',
        collect_kurdish: 'خاڵت بە دەست هێنا'
      },
      playMatch: {
        title: "پێشبینی براوە بکە",
        submit: "پەسەند بکە",
        draw: "یەکسان",
        points_earned: "خاڵی بەدەستهێنراو",
        submit_and_pay: "پەسەند بکە و",
        edit_prediction: "پێشبینییەکە بگۆڕە",
        coins: " کۆین بدە",
        add_coins: "خاڵ زیاد بکە",
        points_per_coin: "١٠٠ خاڵ بۆ هەر کۆین",
        success: "پێشبینیکردنەکە تۆماربوو",
        checkout: "پێداچوونەوە",
        win: "بردنەوە",
        notSelected: "هەڵنەبژێردراوە",
        totalPoints: "کۆی خاڵەکان",
        totalCoin: "کۆی کۆینەکان",
        edit_favourites: 'دەستکاریکردن',
        my_favourites: 'خوازراوەکانم',
        select_match: "تکایە یارییەک هەڵبژێرە بۆ پێشبینیکردن",
        delayed: "Delayed"
      },
      matchResults: {
        title: "ئەنجامی یارییەکان",
        draw: "یەکسان",
        awaiting_results: "لە چاوەڕوانی ئەنجامەکان",
        total: "کۆی گشتی",
        points: "خاڵ",
      },
      getCoins: {
        submit: "پەسەند بکە",
        select_label: "هەڵبژاردەیێک دیاریبکە",
        enter_pin: " کۆدەکە بنووسە کە نێردرا بۆ ژمارەکەت",
        select_amount: "ژمارەیەک لە کۆینەکان دیاریبکە و داواکاریەکەت پێشکەش بکە",
        promo: "دەتەوێت کۆینی زیاتر بەدەست بهێنیت بە داشکاندنی %50؟",
        recharge: "ببورە، باڵانسی پێویستت نییە.",
        prices: {
          label_3: "٣ کۆین بە ٣٠٠ د.ع",
          label_6: "٦ کۆین بە ٦٠٠ د.ع",
          label_9: "٩ کۆین بە ٩٠٠ د.ع",
          label_12: "١٢ کۆین بە ١،٢٠٠ د.ع",
          label_48: "٤٨ کۆین بە ٣،٦٠٠ د.ع",
          label_70: "٧٠ کۆین بە ٥،٠٠٠ د.ع",
        },
        promoPrices: {
          label_3: "٦ کۆین بە ٣٠٠ د.ع",
          label_6: "١٢ کۆین بە ٦٠٠ د.ع",
          label_9: "١٨ کۆین بە ٩٠٠ د.ع",
          label_12: "٢٤ کۆین بە ١،٢٠٠ د.ع",
        },
        buy: 'کڕین',
        close: "داخستن"
      },
      error: {
        error: "تێبینی",
        connexion_error: "هەڵەیەکی پەیوەندی ڕوویدا",
        auth_error: "تکایە دووبارە بڕۆ ژوورەوە",
        invalid_phone_number: "ژمارەکە دروست نیە",
        otp_request_exceeded: "هەوڵەکانی چوونەژوورەوەت تێپەڕاند، تکایە دوایین وشەی نهێنی وەرگیراو بەکاربهێنە",
        otp_request_not_subscribed: "ژمارەکە بەشداری لە خزمەتگوزارییەکە نەکردوه.",
        select_quiz_response: "وەڵامێک دیاریبکە",
        select_play_match: "پێویستە پێشبینی هەموو یاریەکان بکەیت پێش ناردن",
        maximum_quiz_reached: "ژمارەی دیاریکراوی پرسیاری کویزت تێپەڕاند",
        invalid_pin_code: "کۆدەکە دروست نیە",
        waiting_subscription: "داواکاریەکەت لە جێبەجێکردندایە",
        three_answer: "بەکارهێنەر پێویستە بەلایەنی کەمەوە وەڵامی ٣ پرسیار بداتەوە",
        email_invalid: "ئیمێڵەکەت دروست نیە",
        country_required: "وڵات پێویستە",
        otp_invalid: "OTP نادروستە",
        first_name_required: "ناوی یەکەم پێویستە",
        last_name_required: "ناوی کۆتایی پێویستە",
        password: {
          required: "وشەی نهێنی پێویستە",
          confirm_identical: "پاسۆرد و پشتڕاستکردنەوە، دەبێت یەکسان بن",
          eight_character: "وشەی نهێنی دەبێت بەلایەنی کەمەوە ٨ پیت بێت",
          one_lower: "وشەی نهێنی دەبێت بەلایەنی کەمەوە ٨ پیت بێت",
          one_upper: "وشەی نهێنی دەبێت لانیکەم یەک پیتی سەرەوەی تێدابێت",
          one_digit: "وشەی نهێنی دەبێت لانیکەم یەک ژمارەی تێدابێت",
          one_symbol: "وشەی نهێنی دەبێت لانیکەم یەک هێمای تێدابێت"
        }
      },
      more: {
        title: "زیاتر",
        manage_account: "بەڕێوەبردنی هەژمار",
        about_us: "دەربارەی ئێمه",
        languages: {
          text: "زمانەکان",
          english: "ئینگلیزی",
          arabic: "عربي",
          kurdish: "کوردی",
        },
        notifications: "ئاگانامەکان",
        FaQs: "پرسیاره باوەکان",
        terms: "مەرجەکان",
        privacy_policy: "سیاسەتی تایبەتێتی",
        contact_us: "پەیوەندیمان پێوە بکە",
        winners: "براوەکان",
        theme: {
          text: "باگڕاوند",
          ligth: "باگڕاوندی ڕوناک",
          dark: "باگڕاوندی تاریک"
        },
        league: "ڕیزبەندی خولەکە",
        sign_out: "چوونە دەرەوە",
      },
      winners: {
        title: "براوەکان",
        winner: "براوه‌كه‌",
        week: 'هەفتە',
        month: 'مانگ',
        grand: 'گراند'
      },
      league_list: {
        title: "ڕیزبەندی خولەکان"
      },
      manage_account: {
        title: "بەڕێوەبردنی هەژمار",
        save: "گۆڕانکاریەکان بپارێزە",
        error: {
          first_name: "ناوی یەکەم پێویستە",
          last_name: "ناوی کۆتایی پێویستە",
          country: "وڵات پێویستە",
          email: "ئیمێڵەکە دروست نیە"
        }
      },
      faqs: {
        title: "FAQs",

        play: "چۆن دەتوانم یاری بکەم",
        points: "چۆن خاڵ بەدەستبهێنم",
        win_prizes: "چۆن خەڵات ببەرمەوە",
        whats_prizes: "خەڵاتەکان چین",
        coins: "چۆن کۆینزەکان بکڕم"
      },
      forgot_password: {
        title: "وشەی نهێنیت بیر نەماوە?",
        save: "هەڵگرتن",
        verify: "پشتراسکردنەوە",
        continue: "بەردەوامبوون"
      },
      signup: {
        title: "هەژمارەکەت دروست بکە",
        subtitle: "هیچ پابەندبوونێک نییە، هەر کاتێک هەڵبوەشێنەوە",
        continue: "بەردەوامبوون",
        text1: "بە بەردەوامبوون: تۆ پشتڕاستی دەکەیتەوە کە تۆ خوێندتەوە و ڕازی بوویت بە",
        text2: "ڕێنماییەکان",
        text3: "هەژمارت هەیە?",
        login: "چوونەژوورەوە",
        security_questions: "پرسیار"
      },
      choose_subscription: {
        title: "بەشداریکردنت هەڵبژێرە",
        weekly_plan: "پلانی هەفتانە",
        weekly_plan_value_1: "2,100",
        weekly_plan_value_2: "9,000",
        six_month_plan: "پلانی ٦ مانگ",
        six_month_plan_value: "54,000",
        twenty_five_coins: "25 کۆین",
        seven_days: "7 ڕۆژ",
        get_chance_win: "هەلی بردنەوە",
        top_scorer: "بەرزترین خاڵ",
        for_three_winners: "بۆ 3 براوە / تیرووپشک",
        weekly_prizes: "خەڵاتی هەفتانە",
        weekly_prizes_top_value: "300,000 IQD",
        weekly_prizes_for_three_value: "150,000 IQD",
        monthly_prize: "خەڵاتی مانگانە",
        monthly_prize_value: "2,000,000 د.ع",
        grand_prize: "خەڵاتی گەورە",
        grand_prize_value: "15,000,000 د.ع",
        currency: "د.ع",
        subsribe: "بەشداربە"
      },
      subscription: {
        title: "وردەکارییەکانی پارەدانەکەت بنووسە",
        subtitle: "بۆ بەشداربووانی کۆڕەک تیلیکۆم",
        text1: "شێوازێکی تری پارەدان هەڵبژێرە",
        methods: {
          voucher: "باڵانس",
          visa: "ڤیزا",
          master: "ماستەر کارت",

        },
        pay: "پارەدان",
        confirm: "دڵنیاکردنەوە"

      },
      languages: {
        title: "زمانەکان",
      },
      comingSoon: {
        title: "بەم زووانە",
      },
      account: {
        title: "بەرێوەبردنی هەژمار",
        firstName: "ناوی یەکەم",
        lastName: "ناوی خانەوادە",
        email: "ئیمەیڵ",
        submit: "ناردن",
      },
      about: {
        title: "دەربارەی ئێمه",
      },
      privacy_policy: {
        title: "سیاسەتی پاراستنی نهێنی",
      },
      terms: {
        title: "مەرجەکان",
      },
      leagues: {
        title: "خولەکان",
        worldCup: "جامی جیهانی - 2022",
        Laliga: "لالیگا - 2022",
      },
      league: {
        title: "ریزبەندی خولەکە",
        pos: "POS",
        team: "تیم",
        gw: "ژمارەی یاری",
        pl: "کۆتا 5 یاری",
        total: "کۆی خاڵ"
      },
      news: {
        title: "هەواڵ",
      },
      prizes: {
        title: "خەڵاتەکان",
        weekly: "چۆن دەبیتە خاوەنی بەرزترین خاڵ هەموو هەفتەیەک؟ ئاسانە! پێشبینیەکانت بنێرە و وەڵامی زۆرترین پرسیار بدەرەوە تا خاڵی زیاتر بەدەست بهێنیت. تا خاڵەکانت زیاتر بن، چانسی وەرگرتنی 300,000 ديناری کاش زیاتر دەبێت.وە خەڵاتی تریشی هەیە! کە 3 بەشداربووی بەختەوەر دەبنە براوەی تیروپشکەکە، و هەریەکەیان 150,000 دینار وەردەگرن.",
        monthly: "2,000,000 دینار بۆ براوەی تیروپشک.",
        grand: "یەک براوەی بەختەوەر 15,000,000 د.ع دەباتەوە"
      },
      logout: {
        msg: "ئایا دڵنیای کە دەتەوێت بچیتە دەرەوە؟",
        yes: "بەڵێ",
        no: "نەخێر"
      },
      needCoins: {
        buy: 'دراو به‌ده‌ستهێنان',
        back: 'گەڕانەوە',
        textOld: 'تۆ دراوی پێویستت نییە بۆ پێشکەشکردنی لیستی پێشبینییەکانت، تکایە دراوی زیاتر بکڕە',
        text:'هەموو کۆینەکانی ڕۆژانەت بەکارهێنا ، بەیانی ٥ کۆینی زیادە وەردەگریت'

      },
      needSubscribe: {
        subscribe: 'بەشداریکردن',
        watchAds: 'سەیرکردنی ڕیکلامەکان',
        text: 'بۆ پێشکەشکردنی لیستی پێشبینییەکانتان، تکایە یەکێک لەم بژاردانەی خوارەوە هەڵبژێرە'
      },
      subscription_types: {
        0: {
          plan: 'پلانی هەفتانە',
          value: '2,100 IQD'
        },
        1: {
          plan: 'پلانی مانگانە',
          value: '9,000 IQD'
        },
        2: {
          plan: 'پلانی ٦ مانگ',
          value: '54,000 IQD'
        }
      },
      ranking: {
        title: "ڕیزبەندی",
        week: "هەفتە",
        month: "مانگانە",
        overall: "گشتی",
        pos: "POS",
        overallPoints: "کۆی گشتی خاڵەکان",
        username: "ناوی بەکارهێنەر",
        weekPoints: "خاڵەکانی ئەم هەفتەیە",
        monthPoints: "خاڵەکانی ئەم مانگە"

      },
      headToHead: {
        playerName: "ناوی یاریزان",
        buyCoins: "کۆین بکڕە",
        ranking: "ڕیزبەندی",
        score: "خاڵ",
        overview: "تێڕوانینێکی گشتی",
        overviewTable: {
          name: "ناو: ",
          torligaPoints: "خاڵەکان : ",
          points: "خاڵەکانی ڕوبەڕوبونەوە",
          coins: "کۆین: ",
          ranking: "ڕیزبەندی: ",
        },
        getStarted: "دەست پێبکە",
        Start: "دەست پێکردن",
        chooseYourPlan: "پلانەکەت هەڵبژێرە",
        plans: {
          playCoins: "# کۆین وەرگرە",
          getPoints: "# خاڵ وەرگرە"
        },
        play: "یاری بکە",
        prize: "خەڵات",
        noOfQuestion: "ژمارەی پرسیارەکان",
        noOfPoints: "ژمارەی خاڵەکان",
        matchMacts: "ڕاستییەکانی یارییەکان",
        totalPointsEarned: "کۆی خاڵە بەدەست هێنراوەکان",
        pointsEarned: "خاڵە بەدەست هێنراوەکان",
        rematch: "دووبارە یاریکردنەوە",
        newMatch: "یاری نوێ",
        backToHome: "بگه‌ڕێوه‌ دواوە",
        searchForPlayer: "گەڕان بۆ یاریزان",
        selectRandomly: "بە شێوەیەکی هەڕەمەکی هەڵبژێرە",
        choosePlayer: "یاریزانێک هەڵبژێرە",
        nextQuestion: "پرسیاری داهاتوو لە # چرکە",
        waiting: "چاوەڕوان کردن...",
        points: "خاڵەکان",
        matchFacts: "ڕاستییەکانی یارییەکان",
        congragulations: "پیرۆزە لە # بۆ بردنەوەی پێشبڕکێ و بەدەستهێنانی # خاڵ",
        congratulations: "پیرۆزە لە # بۆ بردنەوەی پێشبڕکێکە. # خاڵ و # خاڵی زیادەت بەدەست هێنا!",
        competitionDraw: "پێشبڕکێکە یەکسان بوو. # خاڵ و # خاڵی زیادەت دەستکەوت!",
        userConnected: "بەکارهێنەر پەیوەندی کراوە",
        accept: "پەسەندکردن",
        decline: "ڕەتکردنەوە",
        msgPlayWithMsisdn: "یاریزان # ، دەیەوێت یاریت لەگەڵ بکات",
        msgPlayerNotFound: "یاریزان نەدۆزرایەوە",
        close: "داخستن",
        you: "تۆ",
        error:{
          'cant-play-with-yourself':{
            msg: 'ناتوانی داوای یارییەک لە خۆت بکەیت'
          },
          'not-enough-questions':{
            msg: 'هیچ پرسیارێکی نوێ نییە بۆ یاریکردن'
          },
          'user-has-not-balance':{
            msg: 'بەکارهێنەر پارەی تەواوی نییە بۆ یاریکردن'
          },
          'match-not-found':{
            msg: 'یاری نەدۆزرایەوە'
          },
          'user-already-answered':{
            msg: 'بەکارهێنەر پێشتر پرسیارەکەی وەڵام داوەتەوە'
          },
          'unsubscribed-user':{
            msg: 'بەکارهێنەر بەشداری نەکردووە'
          },
          'unauthorized-user':{
            msg: 'بەکارهێنەری بێ مۆڵەت'
          },
          'match-has-active-question':{
            msg: 'ئەم یارییە پرسیارێکی چالاکانەی هەیە ...'
          }
        }
      }
    }
  },
});

export default i18n;