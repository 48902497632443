<template>
  <div class="content-page">
  <div align="center">
    <div class="titles" >
      <h1> {{ $t("signup.security_questions") }}</h1>
    </div>
    <div style="max-width: 250px;" v-for="(item, i) in this.securityQuestionsArray" :key="i">
      <CustomInput 
        style="margin-top: 15px;" 
        :type="'text'" 
        :label="item['description_'+language]" 
        :placeholder=" $t('inputs.answer')" 
        @setInput="onInputSecurityQuestion($event,i)">
      </CustomInput>
    </div>
    <div style="max-width: 250px;">
      <el-button
          @click="saveSecurityQuestionUserAnswers"
          :class="{'button': true, 'button-valid': true}"
          type="primary">
          {{ $t("signup.continue") }}
      </el-button>
    </div>
  </div>
</div>
</template>

<script>
import { maska } from "maska";
import i18n from "@/translation";
import { store, notify } from "../store";
import { Service } from "../services";
import CustomInput from "../components/CustomInput.vue"

export default {
  name: "SingUpComponent",
  props: {
    msg: String,
  },
  components: {
    CustomInput
  },
  directives: { maska },
  data() {
    return {
      securityQuestionsArray: [],
      securityAnswersArray: [],
    };
  },
  methods: {
    async securityQuestionFindRandom(){
      await Service.securityQuestionFindRandom(6).then(response =>{
          if(response.status == 200){
            this.securityQuestionsArray = response.data;
            for (let i = 0; i < this.securityQuestionsArray.length; i++) {
              this.securityAnswersArray.push({
                "answer": "",
                "secQuestionId": ""});  
            }
          }
      });
    },
    goToRoute(route) {
      this.$router.push("/" + route);
    },
    onInputSecurityQuestion(event,i){
      this.securityAnswersArray[i].answer = event;
      this.securityAnswersArray[i].secQuestionId = this.securityQuestionsArray[i].id;
    },
    minimalAmountSecurityUserAnswers(){
      let qtd = 0
      this.securityAnswersArray.forEach(answer => {
        if(answer.answer != "")
          qtd += 1
      });
      if(qtd>=3)
        return true
      else{
        notify(this.$t("error.error"),this.$t("error.three_answer"),"error");
        return false
      }
    },
    async saveSecurityQuestionUserAnswers(){
      if(this.minimalAmountSecurityUserAnswers()){
        let securityAnswersArrayFiltered = this.securityAnswersArray.filter(elements => {
          return (elements.answer != null && elements.answer !== undefined && elements.answer !== "");
          });
        await Service.saveSecurityQuestionUserAnswers(securityAnswersArrayFiltered).then(response =>{
          if(response.status == 201){
            store.userToken = response.data.token;
            localStorage.setItem("userToken", response.data.token);
            this.goToRoute("favourites")
          }
        });
      }
    },
  },
  computed: {
    language() {
      return i18n.locale;
    },
  },
  mounted() {
    store.layout.showGoBack = false;
    store.layout.footerType = 'FooterInitial'
    store.layout.showUserWallet = false;
    store.layout.textHeader = 'Log In'
    store.layout.showLanguageSwitch = true
    this.securityQuestionFindRandom(6)
  },
};
</script>

<style scoped>
.content-page {
  justify-content: space-around;
}
.titles{
  display: flex;
  flex-direction: column;
  align-content: stretch;
}

h1{
  font-family: 'Rabar_031';
  font-size: 28px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  color: #FFFFFF;
}
.button-valid {
  background-color: #aaff03 !important;
  color: #26314A !important;
  margin-top: 45px !important;
}
.button{
  width: 100%; 
  border-radius: 6px !important;
  font-family: 'Rabar_031';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #fff;
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}
.el-button--primary {
  background-color: #445C87;
  border: 0px;
  border-radius: 20px;
  line-height: 15px !important;
}

@media (max-width: 600px) {
  .login-icons-page {
    font-size: 14px;
    margin-top: 30px;
  }

  .login-icons-page > div{
    width: 100px;
  }
  .login-icons-page > div img{
    height: 20px;
    margin-left: 5px;
  }
}
</style>
