<template>

<nav class="navbar navbar-expand-lg p-4 d-flex justify-content-between">

  <span v-if="showBackButton " @click="goBack">
      <img src="@/assets/left-arrow.svg" alt="back_button" :class="this.$i18n.locale == 'en' ? '' : 'rotate-arrow'">
    </span>

  <a class="navbar-brand" href="#" v-else>
    <img :src="require('@/assets/goalpro-logo.svg')" alt="goalpro" />
  </a>


  <h4 v-if="showBackButton" style="color:#D74EF8">
    {{ $t(pageTitle) }}

    </h4>


<div>
  <el-select style="direction:ltr !important" v-if="showLanguageSwitch" @change="changeLanguage" popper-class="popper-lang" v-model="selectedLang" placeholder="Language">
          <template slot="prefix"></template>
          <el-option  value="en">{{ $t("general.en") }}</el-option>
          <el-option  value="ar">{{ $t("general.ar") }}</el-option>
          <el-option  value="ku">{{ $t("general.ku") }}</el-option>
      </el-select>



  <el-button @click="drawer = true"  type="text" :class="this.$i18n.locale == 'en' ? '' : 'mr'" >
    <img src="../assets/new_menu.png" alt="menu" />
</el-button>


</div>

<el-drawer
class="drawer-sidebar"
  :visible.sync="drawer"
  :with-header="false">


  <div class="col-auto px-0">

            <div class="d-flex justify-content-between px-4 py-3">
              <img src="../assets/goalpro-logo.svg" width="100" alt="logo" />
              <div>
                <el-button @click="drawer = false"  type="text" >
                  <img src="../assets/status/cancel.png" alt="close" />
                </el-button>
              </div>
            </div>


            <div id="sidebar" class="collapse collapse-horizontal show border-end">
                <div id="sidebar-nav" class="list-group border-0 rounded-0 text-sm-start min-vh-100">
                    <a @click="goToRoute('manage-account')" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar"><img src="../assets/sidebar/business-time.svg" alt="manage account" /> <span>{{ $t("more.manage_account") }}</span> </a>
                    <a @click="goToRoute('about-us')" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar"><img src="../assets/sidebar/football.svg" alt="aboutus" /> <span>{{ $t("more.about_us") }}</span> </a>
                    <a @click="goToRoute('terms-and-conditions')" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar"><img src="../assets/sidebar/file-invoice.svg" alt="terms" /> <span>{{ $t("more.terms") }}</span> </a>
                    <!-- <a @click="goToRoute('privacy-policy')" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar"><img src="../assets/sidebar/memo-circle-check.svg" alt="privacypolicy" /> <span>{{ $t("more.privacy_policy") }}</span> </a> -->
                    <!-- <a @click="goToRoute('contact-us')" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar"><img src="../assets/sidebar/envelope.svg" alt="contactus" /> <span>{{ $t("more.contact_us") }} </span> </a> -->
                    <a @click="goToRoute('league-standing')" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar"><img src="../assets/sidebar/folder-tree.svg" alt="league_standing" /> <span>{{ $t("more.league") }}</span> </a>
                    <a @click="showLogout()" class="list-group-item border-end-0 d-inline-block text-truncate" data-bs-parent="#sidebar"><img src="../assets/sidebar/power (1).svg" alt="signout" /> <span>{{ $t("more.sign_out") }}</span> </a>

                </div>
            </div>
        </div>

</el-drawer>


<ModalLogout v-if="showLogoutModalMore" @closeDialog="onCloseDialog" @logout="doLogout"></ModalLogout>


</nav>





</template>

<script>
import { store } from "../store"
import { Service } from "../services"
import i18n from "@/translation"
import moment from "moment";
import ModalLogout from './ModalLogout.vue'


export default {
  name: "HeaderComponent",

  components: {ModalLogout},
  data() {
    return {
      selectedLang: "EN",
      flags: ["AR", "EN", "KU"],
      drawer: false,
    };
  },
  methods: {
    goBack() {
      this.$router.push("/home");
      this.$root.$emit('bv', 1);
    },


    onCloseDialog(){
      store.layout.showLogoutModalMore = false;
    },
    doLogout(){
      store.layout.showModalMore = false;
      store.layout.showLoading = false;
      localStorage.removeItem("userToken");
      store.userToken = "";
      store.layout.showLogoutOverlay = false;
      this.$router.push("/");
    },
    showLogout(){
      store.layout.showLogoutModalMore = true;
    },
    toShowChangeLanguage(){
      this.showChangeLanguage = true;
    },

    changeTheme(){
      this.showChangeTheme = true;
    },
    changeThemeTo(value){
      store.layout.theme ="var(--torliga" + value + ")"
      console.log(store.layout.theme);
    },

    checkActive(item) {
      return [
        "item-footer",
        this.activeItem === item ? "item-footer-active" : "",
      ];
    },


    openShowBuyCoinsDialog() {
      store.layout.showBuyCoinsDialog = true;
    },
    getCoins() {
      store.layout.showGetCoins = true;
    },
    goToRoute(value){
      this.drawer=false
      if(this.$router.history.current.path.replace("/","")!=value)
      this.$router.push(value);
    },
    changeLanguage(){
      i18n.locale = this.selectedLang
      localStorage.removeItem("lang");
      localStorage.setItem("lang", this.selectedLang);
      this.$i18n.locale === 'en' ? moment.locale("en") : this.$i18n.locale === 'ar' ? moment.locale("ar") : moment.locale("ku")
      this.$router.go();
    }
  },
  mounted() {
    this.selectedLang = localStorage.getItem("lang") ? localStorage.getItem("lang").toUpperCase() : "EN";
  },
  computed: {
    pageTitle() {
      return this.$route.meta.pageTitle ; // Use a default title if not provided
    },
    isBackPage() {
      return this.$route.meta.backPage ; // Use a default title if not provided
    },



    activeItem() {
      return store.layout.footerItem;
    },
    language() {
      return i18n.locale;
    },

    showLogoutModalMore() {
      return store.layout.showLogoutModalMore
    },



    showBackButton() {
      return this.$route.path !== '/home';
    },

    showGoBack() {
      return store.layout.showGoBack;
    },
    showUserWallet() {
      return store.layout.showUserWallet;
    },
    userPoints() {
      return store.userPoints;
    },
    userBalance() {
      return store.userBalance;
    },
    textHeader() {
      return store.layout.textHeader ? store.layout.textHeader : '';
    },
    showSkip() {
      return store.layout.showSkip;
    },
    showLanguageSwitch() {
      return store.layout.showLanguageSwitch;
    },
    Service() {
      return Service;
    },
    themeLayout(){
      return store.layout.theme;
    }
  },
};
</script>


<style scoped>

@import '../assets/css/colorcode.css';


.rotate-arrow{
  transform: rotate(180deg) !important;
}

.mr{
  margin-right: 20px !important;
}

.list-group-item {
  background: #182252 !important;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px;
    border-radius: 0;
    border-bottom: 1px solid #3F4C89;
    transition: all 0.3s ease-in-out;
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    cursor: pointer;
    text-align: left !important;
}

.list-group-item img {
  margin-right: 10px;
}

#sidebar-nav{
  padding:20px !important;
}


.popper-lang {
  margin-top: 0px !important;
  background-color: #e4e7ed !important;
}



.popper-lang .popper__arrow::after {
  border-bottom-color: #e4e7ed !important;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-content {

  padding: 0;
  height: 60px;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.back-logo {
  /* display: flex; */
}

.logo {
  display: flex;
  padding: 0px 0px 0px 15px;
  height: 28px;
  max-width: 200px;
}
.notification {
  margin-left: auto;
  align-self: center;
  display: flex;
  padding: 0px 10px 0px 0px;
  height: 30px;
}

.text-header {
  font-size: 18px;
  margin-right: 10px
}

.el-select {
  background-color: #232C40;
  margin-right: 20px;
  border-radius: 3px;
}
.el-select >>> .el-input__inner {
  background-color: #b0aeb100!important;
  width: 60px!important;
  color: #D74EF8;
  border: none;
  height: 25px;
  padding-right: 0!important;
  display: flex;
  height: 28px;
  line-height: 28px;
}

.el-select >>> .el-input__prefix {
    top: 5px!important;
    left: 5px;
    cursor: pointer;
}

.el-select >>> .el-input__prefix {
    top: 5px!important;
    left: 5px;
    cursor: pointer;
}

li.el-select-dropdown__item {
    padding: 2px 0px!important;
    text-align: center;
    border-bottom: #212735;
    font-size: 16px;
}
ul.el-select-dropdown__list {
    padding: 10px 0 0 0;
}
.el-select >>> .el-input__inner, .el-select >>> .el-input__inner:focus {
    border-color: #C0C4CC!important;
}
.el-select >>> .el-select__caret {
  top: 4px;
  width: 0;
  right: 5px;
  height: 0;
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  border-right: 6px solid #D74EF8;
  position: relative;
  transform: none;
}

.el-select >>> .el-select__caret.is-reverse {
    transform: rotateZ(-90deg)!important;
}

.el-select >>> .el-icon-arrow-up:before, .el-select >>> .el-icon-arrow-up:after {
  display: none;
}

.el-select-dropdown__item.selected {
  color: #232c40!important;
  font-size: 20x;
}

.el-select >>> .el-select-dropdown__wrap.el-scrollbar__wrap {
  margin-right: 0!important;
  margin-bottom: 0!important;
}

.go-back {
  display: flex;
  justify-self: left;
  align-self: center;
  margin-left: 10px;
}
.el-icon-back {
  font-size: 30px;
  vertical-align: middle;
  color: #212735;
}

.content-total-balance {
  width: 100vw;
  position: absolute;
  z-index: 10;
  top: 60px;
  background: var(--contentBalanceBG);
}

.content-skip {
  position: absolute;
  z-index: 10;
  top: 75px;
  left: calc(100% - 50px);
}

.skip {
  width: 50px;
  color: #D74EF8;
  display: flex;
  justify-content:left;
  line-height: 34px;
  font-size: 16px;

  background: none;
}

.total-points {
  margin-left: 15px;
}

.total-coins {
  margin-right: 15px;
}

.total-balance {
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: space-between;
  height: 35px;
  font-size: 16px;

  background: #232c40;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  align-items: center;
}

.total-balance img {
  padding: 15px;
  width: 18px;
}





</style>
