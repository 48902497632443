<template>
  <div align="center">
    <div class="arrow-title">
      <div class="arrow">
        <img @click="goBack" :src="require('@/assets/arrow.svg')"/>
      </div>
      <div class="title">{{ $t("forgot_password.title") }}</div>            
    </div>
    <h2> {{ $t("forgot_password.answer_below") }}</h2>
    <div style="max-width: 250px;" v-for="(item, i) in this.securityQuestionsArray" :key="i">
      <CustomInput 
        style="margin-top: 15px;" 
        :type="'text'" 
        :label="item['description_'+language]" 
        :placeholder="$t('inputs.answer')"
        @setInput="onInputAnswer($event,i)">
      </CustomInput>
    </div>
    <div style="max-width: 250px;">
      <el-button
          @click="forgotPassword()"
          :class="{'button': true, 'button-valid': true}"
          type="primary">
          {{ $t("forgot_password.continue") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import i18n from "@/translation";
import { v4 as uuidv4 } from "uuid";
import { store, notify } from "../store";
import { Service } from "../services";
import CustomInput from "../components/CustomInput.vue"

export default {
  name: "ForgotPasswordSecurityQuestionsComponent",
  props: {
    msg: String,
  },
  components: {
    CustomInput
  },
  data() {
    return {
      securityQuestionsArray: [],
      securityAnswersArray: [],
      questionsValid: false,
      windowWidth: ""
    };
  },
  methods: {
    goBack(){
      this.$router.back();
    },
    goToRoute(route) {
      this.$router.push("/" + route);
    },
    onInputPassword(event){
      this.password = event;
    },
    onInputAnswer(event,i){
      this.securityAnswersArray[i].answer = event;
      this.securityAnswersArray[i].secQuestionId = this.securityQuestionsArray[i].id;
    },
    async securityQuestionFindByUser(){
      let response = await Service.securityQuestionFindByUser(store.userName, 6);
      if(response.status == 200){
        this.securityQuestionsArray = response.data;
        for (let i = 0; i < this.securityQuestionsArray.length; i++) {
          this.securityAnswersArray.push({"answer": "",  "secQuestionId": ""});  
        }
      }
      if(response.status == 400){
        this.goToRoute("login")
      }
    },
    minimalAmountSecurityUserAnswers(){
      let qtd = 0
      this.securityAnswersArray.forEach(answer => {
        if(answer.answer != "")
          qtd += 1
      });
      if(qtd>=3)
        return true
      else{
        notify(this.$t("error.error"), this.$t("error.three_answer"),"error");
        return false
      }
    },
    async forgotPassword(){
      if(this.minimalAmountSecurityUserAnswers()){
        await Service.forgotPasswordSecurityQuestions(store.userName,this.securityAnswersArray).then(response =>{
          if(response.status == 200){
            store.userToken = response.data.token;
            this.goToRoute("forgot-password-create-password")
          }
        });
      }
    },
  },
  computed: {
    language() {
      return i18n.locale;
    }
  },
  async mounted() {
    await this.securityQuestionFindByUser();
    store.layout.showGoBack = false;
    store.layout.footerType = 'FooterInitial'
    store.layout.showUserWallet = false;
    store.layout.textHeader = 'Log In'
    store.layout.showLanguageSwitch = true
  },
};
</script>

<style scoped>
.arrow-title {
    display: flex;
    justify-items: center;
    align-items: center;
    padding: 20px 0;
  }
  .arrow {
    padding-left: 15px;
    width: 55px;
  }

  .arrow-title img {
    vertical-align: middle;
  }

  .arrow-title .title {
    padding-right: 55px;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-size: cover;
    width: 100%;
    color: #fff;
    padding: 0;
    font-family: 'Rabar_031';
    font-size: 26px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
  }
  
h2{
  font-family: 'Rabar_031';
  font-size: 16px;
  padding: 5px 80px;
  color: #FFFFFF;
}
.button-valid {
  background-color: #aaff03 !important;
  color: #26314A !important;
}
.button{
  width: 100%; 
  border-radius: 6px !important;
  font-family: 'Rabar_031';
  font-size: 14px;
  letter-spacing: 0em;
  color: #fff;
  margin-top: 30px;
  height: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}
.el-button--primary {
  background-color: #445C87;
  border: 0px;
  border-radius: 20px;
  line-height: 15px !important;
}

@media (max-width: 600px) {
  .login-icons-page {
    font-size: 14px;
    margin-top: 30px;
  }

  .login-icons-page > div{
    width: 100px;
  }
  .login-icons-page > div img{
    height: 20px;
    margin-left: 5px;
  }
}
</style>
