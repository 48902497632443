<template>
  <div class="manage_account_content px-5 pt-4">


    <div class="mx-auto top-title mb-2">
      <h1 class="text-center">{{$t('more.manage_account')}}</h1>
    </div>


  <div class="form-group  mb-3">
    <label class="text-start " :style="this.$i18n.locale == 'en' ? 'float:left' : 'float:right'" style="color:#FFCBAA">{{$t('inputs.first_name')}}</label>
    <input type="text" class="form-control input_design"  :placeholder="$t('inputs.name_placeholder')" v-model="firstName">
  </div>


  <div class="form-group mb-3">
    <label class="text-start " :style="this.$i18n.locale == 'en' ? 'float:left' : 'float:right'" style="color:#FFCBAA">{{$t('inputs.last_name')}}</label>
    <input type="text" class="form-control input_design"  :placeholder="$t('inputs.name_placeholder')" v-model="lastName" >
  </div>

  <div class="form-group mb-3">
    <label class="text-start " :style="this.$i18n.locale == 'en' ? 'float:left' : 'float:right'" style="color:#FFCBAA">{{$t('inputs.phonenumber_short')}}</label>
    <input type="phonenumber"  class="form-control input_design" :placeholder="'7XX XXX XXXX'"
        v-model="cellPhone">
  </div>

  <div class="form-group mb-3">
    <label class="text-start " :style="this.$i18n.locale == 'en' ? 'float:left' : 'float:right'" style="color:#FFCBAA">{{$t('inputs.email')}}</label>
    <input type="email" class="form-control input_design" v-model="email"
        :label="$t('inputs.email')" :placeholder="$t('inputs.email_placeholder')">
  </div>


  <span class="text-start " :style="this.$i18n.locale == 'en' ? 'float:left' : 'float:right'" style="color:#FFCBAA">{{ $t('inputs.country') }}</span><br>

      <div>
        <el-select v-model="countryId"  :placeholder="$t('inputs.country_placeholder')">
          <el-option v-for="item in countries" :key="item.id" :label="item['name_'+language]" :value="item.id" />
        </el-select>
      </div>



        <button class="btn d-block save-btn" @click="saveInformation" >
          {{ $t("manage_account.save") }}
        </button>



    </div>

</template>

<script>
import { maska } from "maska";
import i18n from "@/translation";
import { store, notify } from "../store";
import { Service } from "../services";
// import CustomInput from "../components/CustomInput.vue"

export default {
  name: "ManageAccountComponent",
  props: {
    msg: String,
  },
  components: {
    // CustomInput
  },
  directives: { maska },
  data() {
    return {
      id: "",
      firstName: "",
      lastName: "",
      cellPhone: "",
      email: "",
      countryId: "",
      countries: "",
      formValid: false,
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    goToRoute(route) {
      this.$router.push("/" + route);
    },
    onInputFirstName(event) {
      this.firstName = event;
    },
    onInputLastName(event) {
      this.lastName = event;
    },
    onInputCellphone(event) {
      this.cellPhone = event;
    },
    onInputEmail(event) {
      this.email = event;
    },
    isEmailValid() {
      const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      this.emailValid = expression.test(this.email);
      return this.emailValid;
    },
    validateRequiredInputs() {
      if (!this.firstName) {
        notify(this.$t("error.error"), this.$t("manage_account.error.first_name"), "error");
        return false;
      }
      if (!this.lastName) {
        notify(this.$t("error.error"), this.$t("manage_account.error.last_name"), "error");
        return false;
      }
      if (!this.countryId) {
        notify(this.$t("error.error"), this.$t("manage_account.error.country"), "error");
        return false;
      }
      if (this.email) {
        if (!this.isEmailValid()) {
          notify(this.$t("error.error"), this.$t("manage_account.error.email"), "error");
          return false;
        }
      }
      return true
    },
    loadUserInformation() {
      Service.getUserInfo().then(response => {
        if (response.status == 200) {
          this.firstName = response.data.firstName;
          this.lastName = response.data.lastName;
          this.email = response.data.email;
          this.cellPhone = response.data.phoneAccount != null ? response.data.phoneAccount.replaceAll(" ", "").substring(3) : null;
          this.countryId = response.data.country;
        }
      });
    },
    async loadCountries() {
      Service.loadCountries(1, 50000).then(response => {
        if (response.status == 200) {
          this.countries = response.data.result;
        }
      });
    },
    async saveInformation() {
      if (this.validateRequiredInputs()) {
        Service.changeAccoutInfo(this.firstName,
                                 this.lastName,
                                (this.cellPhone) ? Number("964" + this.cellPhone.replaceAll(" ", "")) : this.cellPhone,
                                 this.email,
                                 this.countryId).then(response => {
          if (response.status == 200) {
            this.goToRoute("home");
          }
        });
      }
    }
  },
  computed: {
    language() {
      return i18n.locale;
    },
    userName() {
      return store.userName;
    }
  },
  mounted() {
    store.layout.showUserWallet = true;
    store.layout.footerItem = "more";
    store.layout.footerType = "FooterHome";
    store.layout.showLanguageSwitch = true

    this.loadUserInformation();
    this.loadCountries();
  },
};
</script>

<style scoped>




.manage_account_content{
  background-image: url("../assets/background.png") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100% !important;
}

.input_design{
  background: #0C1538 !important;
  outline: none !important;
  box-shadow: none !important;
  border:1px solid #2D3B4B !important;
  color: #ffffff !important;
  border-radius: 10px !important;
  padding: 10px !important;
}


.input-manage-account {
  margin-top: 15px;
  max-width: 250px;
}

.custom-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}



.el-select {
  width: 100%;
  height: 42px;
}

.el-select>>>.el-input__inner {
  border-radius: 10px !important;
  /* padding-left: 9px !important; */
  background: #0C1538 !important;
  font-family: 'Rabar_031' !important;
  font-size: 13px !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #ffffff !important;
  border: 1px solid #2D3B4B !important;
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: #2D3B4B !important;
}

.el-select>>>.el-input__inner:focus {
  border-color: #2D3B4B !important;
}

.el-select-dropdown__item.selected {
  color: #D74EF8 !important;
  font-weight: 1000;
}

.arrow-title {
  display: flex;
  justify-items: center;
  align-items: center;
  height: 54px;
}

.arrow {
  padding-left: 15px;
  width: 55px;
}

.arrow-title img {
  vertical-align: middle;
}

.arrow-title .title {
  padding-right: 55px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: cover;
  width: 100%;
  color: #fff;
  padding: 0;
  font-family: 'Rabar_031';
  font-size: 26px;
  font-weight: 400;
  height: 55px;
  letter-spacing: 0em;
  text-align: left;
}

h1 {
  font-family: 'Rabar_031';
  font-size: 28px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  color: #FFFFFF;
}

.button-valid {
  background-color: #D74EF8 !important;
  color: #ffffff !important;

}

.save-btn {
  width: 100% !important;
  background-color: #D74EF8 !important;
  color: #ffffff !important;
  margin: 0 auto !important;
  margin-top: 30px !important;
  padding:10px  20px !important;
  border-radius: 20px !important;
}

.button {
  width: 100% !important;
  border-radius: 6px !important;
  font-size: 14px;
  letter-spacing: 0em;
  color: #fff;
  font-family: 'Rabar_031';
  margin-top: 20px;
  margin-bottom: 50px;
  height: 40px;

}

.el-button--primary {
  background-color: #445C87;
  border: 0px;
  border-radius: 20px;
  line-height: 15px !important;
  max-width: 250px;
}

@media (max-width: 600px) {
  .login-icons-page {
    font-size: 14px;
    margin-top: 30px;
  }

  .login-icons-page > div {
    width: 100px;
  }

  .login-icons-page > div img {
    height: 20px;
    margin-left: 5px;
  }
}
</style>
