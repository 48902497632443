<template>
  <div class="content-page">
    <div class="ranking-content">
      <div class="fixed-header">
        <div class="arrow-title">
          <div class="arrow">
            <img @click="goBack" :src="require('@/assets/arrow.svg')"/>
          </div>
          <div class="title">{{ $t("ranking.title") }}</div>
        </div>
        <div class="ranking-header">
        </div>
      </div>
      <div class="ranking-items">
        <table>
          <thead>
            <tr class="user-ranking">
              <th>{{ userInfo.h2hRanking }}</th>
              <th>{{ userInfo.firstName + " " + userInfo.lastName }}</th>
              <th>{{ userInfo.h2hPoints }}</th>
            </tr>
          </thead>
          <thead>
            <tr class="rankingAll">
              <th>{{ $t('ranking.pos') }}</th>
              <th>{{ $t('ranking.username') }}</th>
              <th>{{ $t('ranking.overallPoints') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="rankingItem in rankingList" :key="rankingItem.userId">
              <td>{{ rankingItem.h2hPosition }} </td>
              <td> {{ rankingItem.user.firstName + " " + rankingItem.user.lastName }} </td>
              <td>{{ rankingItem.h2hPoints }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { Service } from "../services";

import { store } from "../store";
export default {
  name: "RankingComponent",
  data() {
    return {
      rankingList: [],
      page: 1,
      pageSize: 20,
      type: "week",
      totalRows: 20,
      totalPage: 0,
      userInfo: {}
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },

    async getRanking() {
      let response = await Service.rankingHeadToHead("top20");
      if (response && response.status === 200) {
        this.rankingList = response.data.h2hRanking
        this.userInfo = response.data.currentUserH2HRanking
      }
    },
    setPage(val) {
      this.page = val;
      this.getRanking();
    },
    changeType(type) {
      this.page = 1;
      this.type = type;
      this.getRanking();
    },
  },
  computed: {},
  mounted() {
    this.getRanking();
    store.layout.showHeader = false
    store.layout.footerType = ""
    store.layout.footerItem = ""
    store.layout.showUserWallet = false
    store.layout.showLanguageSwitch = true

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to tdis component only -->
<style scoped>

  .content-page {
    font-family: 'DIN_Next_LT_Arabic';
    font-style: normal;
    font-weight: 400;
    background-image: url("../assets/background_h2h.png");
    background-size: cover;
  }
  .ranking-content {
    padding: 0px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .fixed-header {
    position: absolute;
    width: 100%;
    z-index: 100;
  }

  .arrow-title {
    display: flex;
    justify-items: center;
    align-items: center;
    background: none
  }

  .arrow {
    padding-left: 15px;
    width: 45px;
  }

  .arrow-title img {
    vertical-align: middle;
  }

  .arrow-title .title {
    padding-right: 45px;
  }
  .title {
    width: 100%;
    color: #ffffff;
    font-size: 26px;
    padding: 0;
    margin: 8px 0;
    padding: 0px 0;
  }

  .ranking-items {
    background-color: #232c41;
    color: #ffffff;
    padding-bottom: 0px;
    height: calc(100% - 90px);
    margin-top: 90px;
    width: 100%;
    overflow-y: scroll;
    padding: 20px 0 ;
  }


.items {
  background-color: #232c41;
  color: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  flex-grow: 1;
  padding-bottom: 20px;
}

.user-ranking {
  background-color: #a9fb03;
  color: #232c41;
  font-size: 14px;
}

.user-ranking th:nth-of-type(1) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.user-ranking th:nth-of-type(3) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

table {
  text-align: left;
  margin: 0rem;
  margin-bottom: 1rem;
  width: 96%;
  margin: 0 2%;
  border-collapse: collapse;
  font-size: 14px;
}

table tr {
  border-bottom: 0.25px solid #000000;
}

thead tr {
  color: #a9fb03;
}

table th {
  text-align: center;
}

table td,
table th {
  padding: 0.3rem;
  text-align: center;
}

td i {
  color: #a9fb03;
}

.rankingAll th {
  padding-top: 10px;
}

.week {
  width: 50%;
  border-top-left-radius: 10px;
  background-color: #232c41;
  padding: 1px 0;
}

.overall {
  width: 50%;
  border-top-right-radius: 10px;
  background-color: #232c41;
  padding: 1px 0;
}

.week.active {
  background-color: #a9fe06;
  color: #2a3757;
  border-bottom: none;
}

.overall.active {
  background-color: #a9fe06;
  color: #2a3757;
  border-bottom: none;
}

:deep(.number){
  min-width: 70px;
}

:deep(.el-pagination button){
  min-width: 70px;
}

:deep(.el-pagination){
  margin-top: 10px!important;
}
</style>
