import Vue from "vue"
import {
    Notification
} from 'element-ui';

export const store = Vue.observable({
    layout: {
        showModalMore: false,
        showGoBack: false,
        footerType: 'FooterInitial',
        showBuyCoinsDialog: false,
        showLoading: false,
        textHeader: false,
        showUserWallet: false,
        showSkip: false,
        showLogoutOverlay: false,
        showGetCoins: false,
        showLanguageSwitch: false,
        showLogoutModalMore: false,
        footerItem: 'quiz',
        showHeader: true,
        theme: 'var(--torligagreen)'
    },
    keepLogged: true,
    ignoreDefaultError: false,
    phoneNumber: '',
    pinCode: '',
    userPoints: 0,
    userBalance: 0,
    subscribed: false,
    userName: '',
    ti: '',
    serviceLang: "en",
    buyCoinsAmount: 3,
    currentMatchDayCheckout: null,
    userToken: "",
    nextStep: "",
    currentMatchDayCoins: 0,
    socketConnected: false,
    API_URL: process.env.VUE_APP_API_URL,
    WSS_URL: process.env.VUE_APP_WSS_URL,
    
})

export const notify = (title, message, type, fixed = false) => {
    Notification({
        title: title,
        message: ['!', '.'].includes(message.substr(message.length - 1)) ? message : message + '!',
        duration: fixed ? 0 : 4500,
        type: type,
        customClass: 'notify-class'
    });
}

export const resetLayout = () => {
    document.documentElement.style.setProperty("--contentBalanceBG", "none");
    store.layout = {
        showGoBack: false,
        footerType: false,
        showBuyCoinsDialog: false,
        showLoading: false,
        textHeader: false,
        showLanguageSwitch: false,
        showUserWallet: false,
        showSkip: false,
        showLogoutOverlay: false,
        showGetCoins: false,
        showModalMore: false,
        footerItem: '',
        showLogoutModalMore: false,
        showHeader: true,
    }
}