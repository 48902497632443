var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-page"},[_c('UserInfoNav'),_c('div',{staticClass:"quiz-content",style:({ visibility: _vm.isLoading ? 'hidden' : 'visible' })},[_c('div',{staticClass:"question-body"},[_c('div',{staticClass:"question-title"},[_vm._v(" "+_vm._s(_vm.question["description_" + _vm.language])+" ")]),_c('div',{staticClass:"points",style:({ visibility: !_vm.isSubmit ? 'hidden' : 'visible' }),on:{"click":function($event){_vm.showPointsDialog = true}}},[_vm._v(" "+_vm._s(_vm.points + " " + _vm.$t("quiz.points"))+" "),_c('el-button',{staticClass:"add-points",attrs:{"type":"primary","icon":"el-icon-plus"}})],1),(!_vm.isSubmit && this.$i18n.locale !== 'en')?_c('div',{staticClass:"answer-points"},[_c('img',{attrs:{"src":require('@/assets/medal.svg')}}),_c('p',{staticStyle:{"color":"#FFC008"}},[_c('b',[_vm._v(_vm._s(_vm.$t("quiz.points_earned") + ' : ' + _vm.pointsEarned + ' ' + _vm.$t("quiz.points")))])])]):_vm._e(),(!_vm.isSubmit && this.$i18n.locale === 'en')?_c('div',{staticClass:"answer-points"},[_c('img',{attrs:{"src":require('@/assets/medal.svg')}}),_c('p',{staticStyle:{"color":"#FFFFFF"}},[_c('b',[_vm._v(_vm._s(_vm.$t("quiz.points_earned") + ' : ' + _vm.pointsEarned + ' ' + _vm.$t("quiz.points")))])])]):_vm._e(),_c('div',{staticClass:"answers",attrs:{"align":"center"}},_vm._l((2),function(pos){return _c('div',{key:pos,class:{
            answer: true,
            selectedAnswer: _vm.selectedAnswer == pos && _vm.isSubmit && _vm.released,
            wrongAnswer: !_vm.isSubmit && _vm.rightAnswer != pos && _vm.selectedAnswer == pos,
            correctAnswer: !_vm.isSubmit && _vm.rightAnswer == pos && _vm.selectedAnswer == pos,
            rtlDirection: _vm.language != 'en',
          },on:{"click":function($event){return _vm.selectAnswer(pos)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.question["answer" + pos + "_" + _vm.language])+" ")]),(!_vm.isSubmit && _vm.rightAnswer != 0 && _vm.selectedAnswer == pos)?_c('img',{staticClass:"answer-icon",attrs:{"src":_vm.rightAnswer == pos ? require('@/assets/check-quiz.svg') : require('@/assets/new_wrong_answer.svg')}}):_vm._e(),(_vm.isSubmit && !_vm.released)?_c('img',{staticClass:"locked-icon",attrs:{"src":require('@/assets/locked.svg')}}):_vm._e()])}),0),(_vm.isSubmit && _vm.released)?_c('div',{staticClass:"submit-content",on:{"click":function($event){return _vm.anwserQuestion()}}},[_c('div',{staticClass:"total-coins"},[_c('i',{staticClass:"el-icon-coin"}),_vm._v(" "+_vm._s(_vm.currentBetCoins + ' ' + _vm.$t("quiz.coins"))+" ")]),_c('div',{staticClass:"submit-button"},[_vm._v(_vm._s(_vm.$t("quiz.submit"))+" ")])]):_vm._e(),(_vm.isSubmit && !_vm.released)?_c('div',{staticClass:"submit-content",on:{"click":function($event){return _vm.anwserQuestion()}}},[_c('div',{staticClass:"submit-full d-flex justify-content-center"},[_c('span',[_vm._v(_vm._s(_vm.$t("quiz.needCoins")))]),_c('img',{staticClass:"mx-2",attrs:{"src":require('@/assets/locked.svg')}})])]):_vm._e(),(!_vm.isSubmit)?_c('div',{staticClass:"submit-content",on:{"click":function($event){return _vm.showNextQuestion()}}},[_c('div',{staticClass:"submit-full"},[_vm._v(" "+_vm._s(_vm.$t("quiz.next_question"))+" ")])]):_vm._e()])]),(_vm.showPointsDialog)?_c('ModalPoints',{attrs:{"points":_vm.points,"minPoints":100},on:{"closeDialog":_vm.onCloseDialog,"setPoints":_vm.onSetPoints}}):_vm._e(),(_vm.showModalNeedCoins)?_c('ModalNeedCoins',{on:{"actionGetCoins":_vm.onActionGetCoins}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }