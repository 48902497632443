<template>
  <div class="about-us" @click="goBack">

    <div class="about" :style="{ direction: language != 'en' ? 'rtl' : 'ltr' }">


      <div class="image_bg">
          <img :src="require('@/assets/goalpro.svg')" alt="" />
        </div>


      <div class="about-text">
        <span v-if="language == 'en'">
          <p class="en">
            This service is provided by MidSense. MidSense is a company specialized in providing mobile services and applications to telecommunications operators.        </p>
        </span>
        <span v-if="language == 'ar'">
          <p class="en">
            تم تقديم هذه الخدمة من قبل شركة MidSense. <br>
 MidSense شركة متخصصة في تزويد خدمات و تطبيقات الموبايل لشركات الإتصالات.

          </p>
        </span>
        <span v-if="language == 'ku'">
          <p class="en">
            ئەم خزمەتگوزارییە لە لایەن کۆمپانیای MidSense پێشکەش کرا.کە  کۆمپانیایەکی تایبەتە بە دابینکردنی خزمەتگوزاری مۆبایل و بەرنامەکان بۆ کۆمپانیاکانی پەیوەندیکردن.        </p>
        </span>

      </div>
    </div>
  </div>
</template>

<script>
  import i18n from "@/translation";
  import {
    store,
    notify
  } from "../store";

  export default {
    name: "AboutComponent",
    data() {
      return {};
    },
    methods: {
      goBack() {
        this.$router.back();
      },
      goToRoute(route) {
        this.$router.push("/" + route);
      },
    },
    computed: {
      language() {
        return i18n.locale;
      },
    },
    mounted() {
      if (store.userToken) {
        store.layout.footerType = "FooterHome";
        store.layout.footerItem = "more";
        store.layout.showUserWallet = true;
      } else {
        store.layout.footerType = "";
        store.layout.showUserWallet = false;
      }
      store.layout.showLanguageSwitch = true

    },
  };
</script>

<style scoped>
  .image_bg{
    background: #1A296C !important;
    border-radius: 15px !important;
    padding: 50px 90px !important;

  }
  .en{
    text-align: justify;
  }

  .arrow {
    padding-left: 15px;
    width: 55px;
  }

  .arrow-title img {
    vertical-align: middle;
  }

  .arrow-title .title {
    padding-right: 55px;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-size: cover;
    width: 100%;
    color: #D74EF8;
    padding: 0;
    font-family: 'Rabar_031';
    font-size: 26px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
  }

  .about-us {
    flex-direction: column;
    display: flex;
    overflow-y: hidden;
  }

  .about {
    background-color: #0C1538;
    color: #000;
    flex-grow: 1;
    padding: 0px 10px;

  }

  .about-text {
    margin-top: 20px;
    font-family: 'Rabar_031';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    padding: 5px 10px;
    color: #FFFFFF;
    height: var(--appHeight)
  }

  .text-item {
    align-self: center;
    margin-left: 15px;
    font-size: 17px;
  }

  .list-item {
    display: flex;
    background-color: #2a3757;
    border-radius: 6px;
    padding: 5px 10px 7px 10px;
    width: 65%;
    margin: 0 auto;
  }

  .list-item i {
    font-size: 22px;
    margin-left: auto;
    align-self: center;
    padding-right: 5px;
    margin-top: 0 !important;
  }

  .items :not(:first-child) {
    margin-top: 18px;
  }
</style>