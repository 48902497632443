<template>
  <div :class="!otp ? 'around-input' : ''">
    <!-- if otp -->
    <div v-if="otp">
      <div class="input-otp-label">{{ label }}
      </div>
      <div class="around-input-otp">
        <div class="input-otp-item">
          <input class="input-otp" type="number" :name="'0'" :placeholder="placeholder" maxlength="1" @input="onInput"
            @change="onChange" @keyup="onKeyUp" @keydown="onKeyDown" />
        </div>
        <div class="input-otp-item">
          <input class="input-otp" type="number" :name="'1'" :placeholder="placeholder" maxlength="1" @input="onInput"
            @change="onChange" @keyup="onKeyUp" @keydown="onKeyDown" />
        </div>
        <div class="input-otp-item">
          <input class="input-otp" type="number" :name="'2'" :placeholder="placeholder" maxlength="1" @input="onInput"
            @change="onChange" @keyup="onKeyUp" @keydown="onKeyDown" />
        </div>
        <div class="input-otp-item">
          <input class="input-otp" type="number" :name="'3'" :placeholder="placeholder" maxlength="1" @input="onInput"
            @change="onChange" @keyup="onKeyUp" @keydown="onKeyDown" />
        </div>
      </div>
    </div>
    <!-- if otp -->
    <!-- if cellphone -->
    <div class="input-cellphone" v-if="cellphone && !otp">
      <div class="input-cellphone-icon">
        <img :src="require('@/assets/cellphone.svg')" alt="" />
      </div>
      <div class="input-cellphone-label">
        {{ label }}
      </div>
      <div class="input-cellphone-input">
        <input class="input" type="text" :name="name" :placeholder="placeholder" :maxlength="maxlength" :value="value"
          @input="onInput" @change="onChange" @keyup="onKeyUp" />
      </div>
    </div>
    <!-- if cellphone -->
    <!-- if others -->
    <div class="input-input1" v-if="register">
      <div class="input-label1">
        <div class="input-label-input1">
          <div v-if="type == 'phonenumber'" class="prefix1">+964</div>
          <input class="custom-input1" :type="showpassword ? 'text' : type" :name="name"
            :value="value" @input="onInput" @change="onChange" :disabled="disable" autofocus
            v-maska="'7## ### ####'" />
        </div>
      </div>
    </div>
    <!-- if others -->
    <div class="input-input" v-if="!cellphone && !otp && !register">
      <div class="input-label">
        <div class="input-label-label">
          {{ label }}
        </div>
        <div class="input-label-input">
          <div v-if="type == 'phonenumber'" class="prefix">+964</div>
          <input class="custom-input" :type="showpassword ? 'text' : type" :name="name" :placeholder="placeholder"
            :value="value" :maxlength="maxlength" @input="onInput" @change="onChange" :disabled="disable" />
        </div>
      </div>
      <div class="input-label-optional" v-if="optional">
        {{ $t("inputs.optional") }}
      </div>
      <div class="input-icon">
        <img v-if="type == 'password'" :src="showpassword ? require('@/assets/eye.svg') : require('@/assets/eye-slash.svg')"
          alt="" width="23px" height="16px" @click="showpassword = !showpassword" />
        <img v-show="checked" :src="require('@/assets/check.svg')" alt="" width="23px" height="16px" />
      </div>
    </div>
    <!-- if others -->

  </div>
</template>

<script>
import { maska } from "maska";

export default {
  name: 'CustomInput',
  directives: { maska },
  props: {
    type: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
    placeholder: {
      type: String
    },
    maxlength: {
      type: Number
    },
    optional: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    },
    cellphone: {
      type: Boolean,
      default: false
    },
    register: {
      type: Boolean,
      default: false
    },
    otp: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    name() {
      return this.label.toLowerCase();
    },
  },
  data() {
    return {
      showpassword: false,
      width: '',
      height: '',
      fullOtp: ['', '', '', '',]
    };
  },
  methods: {
    onInput(event) {
      //if otp, returns the full otp value end the id of next input to focus
      if (this.otp) {
        this.fullOtp[event.target.name] = event.target.value
        this.$emit('setInput', [this.fullOtp[event.target.name], Number(event.target.name) + 1]);
        if (this.fullOtp[event.target.name].length > 1) {
          this.fullOtp[event.target.name] = this.fullOtp[event.target.name].slice(0, 1)
          document.getElementsByName(event.target.name)[0].value = this.fullOtp[event.target.name].slice(0, 1)
        }
      } else {
        this.$emit('setInput', event.target.value);
      }
    },
    onKeyUp(event) {
      // if(this.otp){
      //   this.fullOtp[event.target.name] = event.target.value
      //   this.$emit('setKeyUp', this.fullOtp[event.target.name].toString(),event.keyCode);
      // }else{
      //   this.$emit('setKeyUp', event.target.value,event.keyCode);
      // }
    },
    onKeyDown(event) {
      if (this.otp) {
        this.fullOtp[event.target.name] = event.target.value
        this.$emit('onKeyDown', [this.fullOtp[event.target.name], Number(event.target.name) + 1, event.keyCode]);
        if (this.fullOtp[event.target.name].length > 1) {
          this.fullOtp[event.target.name] = this.fullOtp[event.target.name].slice(0, 1)
          document.getElementsByName(event.target.name)[0].value = this.fullOtp[event.target.name].slice(0, 1)
        }
      } else {
        this.$emit('onKeyDown', [event.target.value, event.keyCode]);
      }
    },
    onChange(event) {
      if (this.otp) {
        this.fullOtp[event.target.name] = event.target.value
        this.$emit('setChange', this.fullOtp[event.target.name].toString());
      } else {
        this.$emit('setChange', event.target.value);
      }
    },
    onClickTurnShowPassword() {
      this.showpassword = !this.showpassword
    },
    setInputWidtHeight() {
      let a = document.getElementsByClassName("around-input")
      if (a.length > 0) {
        this.width = a[0].clientWidth;
        this.height = a[0].clientHeight;

        let initWidth = this.width;

        //for all inputs
        let iconWidth = (0.20 * this.width) + "px";
        document.documentElement.style.setProperty("--customInputWidthIcon", iconWidth);
        this.width = (0.80 * this.width) + "px";
        document.documentElement.style.setProperty("--customInputWidth", this.width);
        this.height = this.height + "px";
        document.documentElement.style.setProperty("--customInpuHeight", this.height);
        //for otp input
        let customInputOtpIconWidth = (0.15 * initWidth) + "px";
        document.documentElement.style.setProperty("--customInputOtpIconWidth", customInputOtpIconWidth);
        let customInputOtpLabelWidth = (0.15 * initWidth) + "px";
        document.documentElement.style.setProperty("--customInputOtpLabelWidth", customInputOtpLabelWidth);
        let customInputOtpWidth = (0.70 * initWidth) + "px";
        document.documentElement.style.setProperty("--customInputOtpWidth", customInputOtpWidth);
      }
    }
  },
  mounted() {
    this.setInputWidtHeight();
  }
}
</script>


<style>
.input-input1{
  direction: ltr !important;
  /* background-color: ; */
}

.around-input-otp{
  direction: ltr !important;
}
</style>

<style scoped>



:root {
  --customInputWidth: 1px;
  --customInpuHeight: 1px;
  --customInputWidthIcon: 1px;
  --customInputOtpIconWidth: 1px;
  --customInputOtpLabelWidth: 1px;
  --customInputOtpWidth: 1px;
}

.around-input-otp {
  display: flex;
  justify-content: space-evenly;
}

.input-otp-label {
  font-family: 'Rabar_031';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  /* line-height: 26px; */
  color: #FFFFFF;
  padding: 14px;
}

.input-otp-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0C1538;
  border-radius: 6px;
  width: 52px;
  height: 52px;
  color: #C1DEFF;
  padding: 4px;
}

.input-otp {
  padding: 0px !important;
  border-width: 0px;
  width: 20px;
  height: 20px;
  background-color: #0C1538;
  border-radius: 6px;
  display: flex;
  align-items: center;
  font-size: 15px;
  text-align: center;
  justify-content: center;
  color: #C1DEFF;

}

.input-cellphone {
  display: flex;
}

.input-cellphone-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--customInputOtpIconWidth);
}

.input-cellphone-label {
  width: var(--customInputOtpLabelWidth);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Rabar_031';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  /* line-height: 22px; */
  color: #27324D;
}

.input-cellphone-input {
  display: flex;
  width: 100%;
  padding: 3px !important;
}

.shadow input[type="text"]:focus {
  outline: none;
}

::placeholder {
  font-family: 'Rabar_031';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 21px; */
  color: #26314A;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: 'Rabar_031';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 21px; */
  color: #26314A;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: 'Rabar_031';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 21px; */
  color: #26314A;
}

.around-input {
  display: flex;
  background-color: #0C1538;
  border-radius: 6px;
  width: 100%;
  height: 42px;
}

.input {
  padding: 0px !important;
  border-width: 0;
  width: var(--inputWidth);
  font-family: 'Rabar_031';
  font-size: 15px;
  font-weight: 400;
  /* line-height: 20px; */
  letter-spacing: 0em;
  color: #252E45;
}

.input-input {
  display: flex;
  width: 100%
}

.input-label {
  text-align: left;
  padding-left: 10px;
  width: var(--customInputWidth);
  height: var(--customInpuHeight);
  flex-direction: column;
  font-family: 'Rabar_031';
  font-style: normal;
}

.input {
  padding: 0px !important;
  border-width: 0;
  width: var(--inputWidth);
  font-family: 'Rabar_031';
  font-size: 15px;
  font-weight: 400;
  /* line-height: 20px; */
  letter-spacing: 0em;
  color: #252E45;
}

.input-input1 {
  display: flex;
  width: 100%;
  direction: ltr !important;
  /* background-color: ; */
}

.input-label1 {
  text-align: left;
  padding-left: 10px;
  width: var(--customInputWidth);
  height: var(--customInpuHeight);
  flex-direction: column;
  font-family: 'Rabar_031';
  font-style: normal;
}

.input-label-label1 {
  display: flex;
  align-items: flex-end;
  height: 50%;
  font-family: 'Rabar_031';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  /* line-height: 15px; */
  color: #FFFFFF;
}

.input-label-input1 {
  height: 50%;
  display: flex;
  align-items: flex-start;
}

.prefix1 {
  border-width: 0;
  padding-left: 3px !important;
  padding-top: 7px !important;
  font-family: 'Rabar_031';
  font-size: 20px;
  font-weight: 400;
  /* line-height: 20px; */
  letter-spacing: 0em;
  color: #D74EF8;
}

.custom-input1 {
  border-width: 0;
  width: 100%;
  padding: 7px 0px 0px 3px !important;
  font-family: 'Rabar_031';
  font-size: 20px;
  font-weight: 400;
  color: #C1DEFF;
  background-color: #0C1538 !important;
}

.input-label-input {
  height: 50%;
  display: flex;
  align-items: flex-start;
}

.input-icon {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-items: space-evenly;
  margin-left: 5px;
}

.prefix {
  border-width: 0;
  padding-right: 3px !important;
  font-family: 'Rabar_031';
  font-size: 13px;
  font-weight: 400;
  /* line-height: 20px; */
  letter-spacing: 0em;
  color: #252E45;
}



.custom-input {
  border-width: 0;
  width: 100%;
  padding: 0px !important;
  font-family: 'Rabar_031';
  font-size: 13px;
  font-weight: 400;
  color: #252E45;
}



.input-label-optional {
  padding-top: 5px;
  font-family: Rabar_031;
  font-size: 10px;
  font-weight: 400;
  /* line-height: 15px; */
  letter-spacing: 0em;
  text-align: left;
}




</style>