<template>
  <div class="content-page">

    <UserInfoNav/>

    <div class="rakning-content">
      <div class="fixed-header col-10 mx-auto">
        <div class="ranking-header">
          <div @click="changeType('overall')" :class="{ active: type == 'overall', overall: true }">
            {{ $t('ranking.overall') }}
          </div>
        </div>
      </div>


      <div class="ranking-items">

<!--
            <div class="user-ranking d-flex col-11 mx-auto">
              <div>{{ type == 'week' ? userInfo.weekRanking : userInfo.overallRanking }}</div>
              <div>{{ userInfo.firstName + " " + userInfo.lastName }}</div>
              <div>{{ userInfo.monthPoints }}</div>
              <div>{{ userInfo.overallPoints }}</div>
            </div> -->

            <div class="rankingAll d-flex col-11 mx-auto">
              <div >{{ $t('ranking.pos') }}</div>
              <div>{{ $t('ranking.username') }}</div>

               <!-- <div>{{ $t('ranking.monthPoints') }}</div> -->
              <div>{{ $t('ranking.overallPoints') }}</div>
            </div>


            <div class="tableRanks d-flex col-11 mx-auto" v-for="ranking in rankingList" :key="ranking.userId">
              <div class="order"><span>{{ ranking[type + "Position"] }}</span></div>
              <div>
                {{ ranking["user"]["firstName"] }}
                {{ ranking["user"]["lastName"] }}
              </div>

             <!-- <div class="points">{{ ranking["monthPoints"] }}</div> -->
              <div class="points">{{ ranking["overallPoints"] }}</div>
            </div>


      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/translation";
import { Service } from "../services";
import UserInfoNav from "../components/UserInfoNav.vue";

import { store } from "../store";
export default {
  name: "RankingComponent",
  components:{UserInfoNav},
  data() {
    return {
      rankingList: [],
      page: 1,
      pageSize: 30,
      type: "overall",
      totalRows: 30,
      totalPage: 0,
      userInfo: {}
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },

    async getRanking() {
      let response = await Service.getRankingList(
        this.page,
        this.pageSize,
        this.type
      );
      if (response && response.status === 200) {
        this.rankingList = response.data["ranking"];
        this.userInfo = response.data.currentUserRanking;
        this.totalRows = 20 //response.data["totalRows"];
        this.totalPage = Math.round(this.totalRows / this.pageSize);
      }
    },
    setPage(val) {
      this.page = val;
      this.getRanking();
    },
    changeType(type) {
      this.page = 1;
      this.type = type;
      this.getRanking();
    },
  },
  computed: {},
  mounted() {
    this.getRanking();
    store.layout.showUserWallet = true;
    store.layout.footerItem = "play-match";
    store.layout.footerType = "FooterHome";
    store.layout.showLanguageSwitch = true

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to tdis component only -->
<style scoped>

.content-page {
  justify-content: flex-start;
}
.rakning-content {
  padding: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* overflow-y: scroll !important; */
  margin-bottom: 100px !important;
}



.arrow-title {
  display: flex;
  justify-items: center;
  align-items: center;
}

.arrow {
  padding-left: 15px;
  width: 55px;
}

.arrow-title img {
  vertical-align: middle;
}

.arrow-title .title {
  padding-right: 55px;
}

.title {
  background-size: cover;
  width: 100%;
  color: #ffffff;
  font-size: 26px;
  padding: 0;

  padding: 8px 0;
  background: none;
}

.ranking-header {
  color: #2a3757;
  background-color: transparent;
  color: #ffffff;
  display: flex;
  font-size: 22px;
  padding: 2px 0;
  border-radius: 15px;
}

.ranking-items {
    background-color: #0C1538;
    color: #ffffff;
    padding-bottom: 0px;
    height: calc(100% - 90px);
    /* margin-top: 10px; */
    width: 100%;
    overflow-y: scroll;
    padding: 20px 0 ;
  }

.week {
  width: 50%;
}

.overall {
  width: 100%;
}

.week.active {
  background: linear-gradient(#3046BA,#18235D);
  color: white;
  border-radius: 15px;
  border-bottom: none;
}

.overall.active {
  background: linear-gradient(#3046BA,#18235D);
  color: white;
  border-radius: 15px;
  border-bottom: none;
}

.user-ranking {
  background-color: #D74EF8;
  color: white !important;
  border-radius: 15px !important;
  margin-bottom: 20px !important;
  padding: 10px !important;

}

.user-ranking div {
  text-align: center;
  width: 25% !important;
}
.rankingAll div {
  text-align: center;
  width: 33% !important;
  color: #FFCBAA !important;
}
.tableRanks div {
  text-align: center !important;
  width: 33% !important;
}


.tableRanks{
  background: #101A41 !important;
  color: white !important;
  border-radius: 10px !important;
  margin-bottom: 10px !important;
  padding: 10px !important;
}

.tableRanks .points{
  color: #FFCBAA !important;
}

table {
  text-align: left;
  margin: 0rem;
  margin-bottom: 1rem;
  width: 96%;
  margin: 0 2%;
  border-collapse: collapse;
  font-size: 14px;
}




table th {
  text-align: center;
}

table td,
table th {
  padding: 0.3rem;
  text-align: center;
}

td i {
  color: #a9fb03;
}



.rankingAll th {
  padding-top: 10px;
  color:#FFCBAA !important ;
}



:deep(.number){
  min-width: 70px;
}

:deep(.el-pagination button){
  min-width: 70px;
}

:deep(.el-pagination){
  margin-top: 10px!important;
}
</style>
