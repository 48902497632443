<template>
  <div class="content-page">
    
    
   
    <el-tabs  class="demo-tabs " >
      <el-tab-pane v-for="(league,i) in leagues" v-bind:key="i"  >
        
        <template #label>
        <span @click="getLeagueInfo(league.leagueId,league.year)">{{league['name_' + $i18n.locale]}}</span>
      </template>
      
      
      
     
      
      
        <div class="items">
        <div v-for="(group, key) in ligaData" :key="group">
          <div class="title"> {{ key }}</div>
          
          <div class="rankingAll d-flex col-12 mx-auto">
          <div> # </div>
          <div>{{ $t("league.team") }}</div>
          <div>{{ $t("league.pl") }}</div>
          <div>{{ $t("league.gw") }}</div>
          <div>{{ $t("league.total") }}</div>
      </div>
      
      <div class="tableRanks d-flex col-12 mx-auto" v-for="(team, key) in data[key]" :key="key">
          <div class="order">{{ team['ranking'] }}</div>
          <div class="team_name">{{ team['team']['name_' + $i18n.locale] }}</div>
          <div>{{ team['lastMatches'] }}</div>
          <div class="gw">{{ team['matchesPlayed'] }}</div>
          <div class="points">{{ team['points'] }}</div>
      </div>
          
    
        </div>
      </div>
        
        
      </el-tab-pane>
    </el-tabs>
    
    
    
    <div class="league_list_content">
    
        
    
    
    
      <!-- <div :class="{ 'rtl': language != 'en' ? true : false, 'items': true }">
        <div class="list-item" v-for="league in leagues" v-bind:key="league.leagueId" @click="goToRoute(league.leagueId,league.year)">
          <div class="text-item">{{ league['name_' + $i18n.locale] }} </div>
          <i class="el-icon-right"></i>
        </div>
      </div> -->
      
    </div>
  </div>
</template>
  
<script>
import i18n from "@/translation";
import { store, notify } from "../store";
import router from "../router";
import { Service } from "../services";

export default {
  name: "NewsMatchResultsComponent",
  data() {
    return {
      leagues: [],
      data: {},
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    // goToRoute(leagueID, year) {
    //   localStorage.setItem("leagueID", leagueID);
    //   localStorage.setItem("leagueYear", year);
      
    //   let league = {"leagueID":leagueID, "year":year};
    //   this.$router.push({name: "league", params: { league: league }});
    // },
    async getSeasonsInfo() {
      store.layout.loading=true;
      let response = await Service.getSeasonsInfo();
      if (response && response.status === 200) {
        this.leagues = response.data;
        const leagueID=response.data[0].leagueId;
        const leagueYear=response.data[0].year;
        this.getLeagueInfo(leagueID, leagueYear);

      store.layout.loading=true;
      }
    },
    
    async getLeagueInfo(leagueID, leagueYear) {
      console.log(leagueID);
      let response = await Service.getLeagueStanding(
        leagueID,
        leagueYear,
      );
      if (response && response.status === 200) {
        this.data = response.data['standings'];
        console.log(response);
      }
    },
    
    
  },
  computed: {
   
    showValidToken() {
      if (store.userToken != null && store.userToken !== "") {
        return true;
      } else {
        return false;
      }
    },
    language() {
      return i18n.locale;
    },
    
    ligaData() {
      return this.data;
    },  
    
  },
  mounted() {
    store.layout.footerType = "";
    // store.layout.footerItem = "more";
    store.layout.showUserWallet = true;
    this.getSeasonsInfo();
    store.layout.showUserWallet = true;
    
    store.layout.footerItem = "more";
    store.layout.footerType = "FooterHome";
  },
};
</script>
  
<style scoped>
  .content-page {
    justify-content: flex-start;
  }
  
  
  .team_name{
    font-size: 13px !important;
  }
  
  .rankingAll div {
  text-align: start;
  /* width: 25% !important; */
  color: #FFCBAA !important;
}



.rankingAll{
  padding:8px 0px 5px 15px !important;
}


.rankingAll div:nth-child(1) {
  text-align: start;
  width: 10% !important;
  color: #FFCBAA !important;
}
.rankingAll div:nth-child(2) {
  text-align: start;
  width: 40% !important;
  color: #FFCBAA !important;
}
.rankingAll div:nth-child(3) {
  text-align: start;
  width: 45% !important;
  color: #FFCBAA !important;
}
.rankingAll div:nth-child(4) {
  text-align: start;
  width: 15% !important;
  color: #FFCBAA !important;
}
.rankingAll div:nth-child(5) {
  text-align: start;
  width: 15% !important;
  color: #FFCBAA !important;
}
  


.tableRanks div {
  text-align: start !important;
  /* width: 25% !important; */
}


.tableRanks div:nth-child(1) {
  text-align: start;
  width: 10% !important;
}
.tableRanks div:nth-child(2) {
  text-align: start;
  width: 40% !important;
}
.tableRanks div:nth-child(3) {
  text-align: start;
  width: 45% !important;
}
.tableRanks div:nth-child(4) {
  text-align: start;
  width: 15% !important;
}
.tableRanks div:nth-child(5) {
  text-align: start;
  width: 15% !important;
}


.tableRanks{
  background: #101A41 !important;
  color: white !important;
  border-radius: 10px !important;
  margin-bottom: 10px !important;
  padding:8px 0px 5px 15px !important;
}

.tableRanks .points{
  color: #D74EF8 !important;
}
.tableRanks .gw{
  color: #FFC115 !important;
}
  
  
  .league_list_content {
    padding: 0px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .fixed-header {
    width: 100%;
    z-index: 100;
  }

  .arrow-title {
    display: flex;
    justify-items: center;
    align-items: center;
  }

  .arrow {
    padding-left: 15px;
    width: 45px;
  }

  .arrow-title img {
    vertical-align: middle;
  }

  .arrow-title .title {
    padding-right: 45px;
  }
  .title {
    width: 100%;
    color: #FFFFFF;
    font-size: 20px;
    padding: 0;
    padding: 3px 0;
    font-family: 'Rabar_031';
    text-align:start !important;

  }
  
  .star-icon{
    position:relative;top:-2px !important
  }
  .items {
    background-color: transparent;
    color: #ffffff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    flex-grow: 1;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 5px;
    overflow-y: scroll;
    height: calc(100% - 100px);
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  .items::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
  .items .title {
    color: #FFCBAA;
  }

  table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
  }
  table tr {
    border-bottom: .25px solid #000000;
  }
  thead tr {
    border-top: .25px solid #000000;
    color: #FFCBAA;
  }
  table td,
  table th {
    padding: .3rem;
  }
  td i {
    color: #FFCBAA;
  }
</style>
