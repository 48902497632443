<template>
  <div class="about-us">
    <div
      class="about col-11 mx-auto"
      :style="{ direction: language != 'en' ? 'rtl' : 'ltr' }"
    >
      <div class="form-group mb-3 mt-5">
        <input
          type="text"
          class="form-control input_design"
          :placeholder="$t('inputs.name_placeholder')"
        />
      </div>

      <div class="form-group mb-3">
        <input
          type="text"
          class="form-control input_design"
          :placeholder="$t('inputs.email_placeholder')"
        />
      </div>

      <div class="form-group mb-3">
        <input
          type="text"
          class="form-control input_design"
          :placeholder="$t('inputs.phonenumber_short')"
        />
      </div>

      <div class="form-group mb-3">
        <input
          type="text"
          class="form-control input_design"
          :placeholder="$t('inputs.subject')"
        />
      </div>

      <div class="form-group">
        <textarea
          class="form-control input_design"
          :placeholder="$t('inputs.message')"
          rows="6"
        ></textarea>
      </div>

      <button class="btn d-block save-btn">
        {{ $t("manage_account.save") }}
      </button>
    </div>
  </div>
</template>

<script>
import i18n from "@/translation";
import { store, notify } from "../store";

export default {
  name: "ContactComponent",
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    goToRoute(route) {
      this.$router.push("/" + route);
    },
  },
  computed: {
    language() {
      return i18n.locale;
    },
  },
  mounted() {
    store.layout.showUserWallet = true;
    store.layout.footerItem = "more";
    store.layout.footerType = "FooterHome";
    store.layout.showLanguageSwitch = true

  },
};
</script>

<style scoped>
.input_design {
  background: #0c1538 !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  color: #ffffff !important;
  border-radius: 10px !important;
  padding: 20px 10px !important;
}

.save-btn {
  width: 100% !important;
  background-color: #d74ef8 !important;
  color: #ffffff !important;
  margin: 0 auto !important;
  margin-top: 60px !important;
  padding: 10px 20px !important;
  border-radius: 20px !important;
  font-size: 25px !important;
}
.arrow-title {
  display: flex;
  justify-items: center;
  align-items: center;
  height: 54px;
}
.arrow {
  padding-left: 15px;
  width: 55px;
}

.arrow-title img {
  vertical-align: middle;
}

.arrow-title .title {
  padding-right: 55px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: cover;
  width: 100%;
  color: #fff;
  padding: 0;
  font-family: "Rabar_031";
  font-size: 26px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
}

.about-us {
  background-image: url("../assets/background.png") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100% !important;
}

.about-text {
  font-family: "Rabar_031";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
}

.text-item {
  align-self: center;
  width: 100%;
  position: relative;
  font-size: 17px;
}

.text-item-selected {
  align-self: center;
  width: 100%;
  position: relative;
  font-size: 17px;
  color: #232c40;
}

.list-item {
  display: flex;
  flex-direction: row;
  background-color: #2a3757;
  border-radius: 6px;
  padding: 5px 10px 7px 10px;
  width: 65%;
  margin: 0 auto;
  margin-top: 10px;
}

.list-item-selected {
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  padding: 5px 10px 7px 10px;
  width: 65%;
  margin: 0 auto;
  margin-top: 10px;
  background-color: #a9fb03;
}

.list-item i {
  font-size: 22px;
  align-self: center;
  margin-top: 0 !important;
}

.items :not(:first-child) {
  margin-top: 18px;
}
</style>
