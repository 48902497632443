<template>
  <div class="privacy-policy">

    <div class="privacy" :style="{ direction: language != 'en' ? 'rtl' : 'ltr' }">

      <!-- english -->

      <div class="privacy-text privacy-ltr" style="margin-top: 20px;" v-if="language == 'en'">
        <p><strong>Goalpro Mobile Application Privacy Policy</strong></p>
        <p><strong>Last Updated: 10<sup>th</sup>, Dec 2023.</strong></p>
        <h5>1. Introduction</h5>
        <p>Welcome to Goalpro! This Privacy Policy explains how we collect, use, and protect your personal information
          when you use our mobile application. By using Goalpro, you agree to the terms outlined in this policy.</p>

        <h5>2. Information We Collect</h5>
        <p>2.1 <strong>Personal Information:</strong></p>
        <ul>
          <li>When you register for Goalpro, we may collect personal information such as your name, email address, and
            phone number.</li>
        </ul>
        <p>2.2 <strong>Predictions and Trivia Answers:</strong></p>
        <ul>
          <li>We collect data on your football match predictions and trivia answers to enhance your user experience and
            improve our services.</li>
        </ul>
        <p>2.3 <strong>Subscription Information:</strong></p>
        <ul>
          <li>To facilitate your daily subscription, we collect information related to your mobile operator, phone number,
            and voucher details.</li>
        </ul>
        <p>2.4 <strong>Usage Data:</strong></p>
        <ul>
          <li>We may collect information on how you use the Goalpro app, such as the features you access and the frequency
            of your interactions.</li>
        </ul>

        <h5>3. How We Use Your Information</h5>

        <p>3.1 <strong>Provide Services:</strong></p>
        <ul>
          <li>We use your information to deliver the services offered by Goalpro, including processing predictions,
            trivia, and managing your subscription.</li>
        </ul>
        <p>3.2 <strong>Improve User Experience:</strong></p>
        <ul>
          <li>Your data helps us enhance our app, tailor content to your preferences, and provide a more personalized
            experience.</li>
        </ul>
        <p>3.3 <strong>Communication:</strong></p>
        <ul>
          <li>We may use your contact information to send you important updates, notifications, and promotional materials.
            You can opt out of promotional communications at any time.</li>
        </ul>

        <h5>4. Subscription and Billing</h5>

        <p>4.1 <strong>Daily Subscription:</strong></p>
        <ul>
          <li>Goalpro offers a daily subscription service through mobile operators. Subscriptions are renewed daily upon
            your consent.</li>
        </ul>
        <p>4.2 <strong>Payment Information:</strong></p>
        <ul>
          <li>We do not process or store payment information. Subscription payments are facilitated through mobile
            operator vouchers.</li>
        </ul>
        <p>4.3 <strong>Cancellation:</strong></p>
        <ul>
          <li>You can unsubscribe from Goalpro at any time. To cancel your subscription, follow the instructions provided
            in the app.</li>
        </ul>

        <h5>5. Security Measures</h5>

        <p>We implement reasonable security measures to protect your personal information. However, no method of
          transmission or storage is completely secure, and we cannot guarantee the absolute security of your data.</p>

          <h5>6. Children's Privacy</h5>

        <p>Goalpro is not intended for children under the age of 13. We do not knowingly collect personal information from
          individuals under 13 years old.</p>

        <h5>7. Changes to the Privacy Policy</h5>

        <p>We reserve the right to update this Privacy Policy. We will notify you of any significant changes through the
          app or other communication channels.</p>

          <h5>8. Contact Us</h5>

        <p>If you have any questions or concerns about this Privacy Policy, please contact us at</p>
        <p>info@midsense-jo.com</p>
        <p>By using Goalpro, you acknowledge that you have read and understood this Privacy Policy.</p>
      </div>

      <!-- end english -->


      <!-- arabic -->

      <div class="privacy-text privacy-rtl" style="margin-top: 20px;" v-if="language == 'ar'">

        <p><strong>سياسة الخصوصية لتطبيق Goalpro على الهواتف المحمولة</strong></p>
        <p><strong>تم آخر تحديث: 10 ديسمبر 2023.</strong></p>
        <h5>1. المقدمة</h5>
        <p>مرحبًا بك في Goalpro! تشرح هذه سياسة الخصوصية كيفية الاستخدام وحماية معلوماتك الشخصية عند استخدام تطبيقنا على الهواتف المحمولة. من خلال استخدام Goalpro، فإنك توافق على الشروط المفصلة في هذه السياسة.</p>

        <h5>2. المعلومات التي نجمعها</h5>
        <p>2.1 <strong> المعلومات الشخصية : </strong></p>
        <ul>
          <li>عند تسجيلك في Goalpro، قد نجمع معلومات شخصية مثل اسمك وعنوان بريدك الإلكتروني ورقم هاتفك.</li>
        </ul>
        <p>2.2 <strong>التوقعات وإجابات الألغاز :</strong></p>
        <ul>
          <li>نقوم بجمع بيانات حول توقعاتك لمباريات كرة القدم وإجاباتك على الألغاز لتعزيز تجربتك كمستخدم وتحسين خدماتنا.</li>
        </ul>
        <p>2.3 <strong>معلومات الاشتراك : </strong></p>
        <ul>
          <li>لتسهيل اشتراكك اليومي، نقوم بجمع معلومات تتعلق بمشغل هاتفك المحمول ورقم هاتفك وتفاصيل القسيمة.</li>
        </ul>
        <p>2.4 <strong>بيانات الاستخدام : </strong></p>
        <ul>
          <li>قد نقوم بجمع معلومات حول كيفية استخدامك لتطبيق Goalpro، مثل الميزات التي تستخدمها وتكرار تفاعلاتك.</li>
        </ul>

        <h5>3. كيف نستخدم معلوماتك</h5>

        <p>3.1 <strong>تقديم الخدمات :</strong></p>
        <ul>
          <li>نستخدم معلوماتك لتقديم الخدمات التي يقدمها Goalpro، بما في ذلك معالجة التوقعات والألغاز وإدارة اشتراكك.</li>
        </ul>
        <p>3.2 <strong>تحسين تجربة المستخدم :</strong></p>
        <ul>
          <li>تساعدنا بياناتك في تحسين تطبيقنا، وتخصيص المحتوى وفقًا لتفضيلاتك، وتوفير تجربة شخصية أكثر.</li>
        </ul>
        <p>3.3 <strong>الاتصال : </strong></p>
        <ul>
          <li>قد نستخدم معلومات الاتصال الخاصة بك لإرسال تحديثات هامة وإشعارات ومواد ترويجية. يمكنك الانسحاب من التواصل الترويجي في أي وقت.</li>
        </ul>

        <h5>4. الاشتراك والرصيد</h5>

        <p>4.1 <strong>الاشتراك اليومي : </strong></p>
        <ul>
          <li>يقدم Goalpro خدمة اشتراك يومي من خلال مشغلات الهواتف المحمولة. يتم تجديد الاشتراك يوميًا بموافقتك.</li>
        </ul>
        <p>4.2 <strong>معلومات الدفع :</strong></p>
        <ul>
          <li>لا نعالج أو نخزن معلومات الدفع. يتم تسهيل دفعات الاشتراك من خلال قسائم مشغل الهاتف المحمول.</li>
        </ul>
        <p>4.3 <strong>لإلغاء :</strong></p>
        <ul>
          <li>يمكنك إلغاء الاشتراك في Goalpro في أي وقت. لإلغاء الاشتراك، اتبع التعليمات المقدمة في التطبيق.</li>
        </ul>

        <h5>5. تدابير الأمان</h5>

        <p>نقوم بتنفيذ تدابير أمان معقولة لحماية معلوماتك الشخصية. ومع ذلك، لا يوجد أي طريقة للنقل أو التخزين تكون آمنة تمامًا، ولا يمكننا ضمان الأمان المطلق لبياناتك.</p>

          <h5>6. خصوصية الأطفال </h5>

        <p>لا يتم تصميم Goalpro للأطفال دون سن 13 عامًا. نحن لا نقوم بجمع معلومات شخصية عن عمد من الأفراد الذين تقل أعمارهم عن 13 عامًا.</p>

        <h5>7. تغييرات على سياسة الخصوصية</h5>

        <p>نحتفظ بحق تحديث سياسة الخصوصية. سنخبرك بأي تغييرات كبيرة من خلال التطبيق أو وسائل الاتصال الأخرى.</p>

          <h5>8. اتصل بنا</h5>

        <p>إذا كان لديك أي أسئلة أو مخاوف حول سياسة الخصوصية هذه، يرجى الاتصال بنا على:</p>
        <p>info@midsense-jo.com</p>
        <p>من خلال استخدام Goalpro، تقر بأنك قد قرأت وفهمت هذه سياسة الخصوصية.</p>

      </div>

      <!-- end arabic -->



      <!-- kurdish -->

      <div class="privacy-text privacy-rtl" style="margin-top: 20px;" v-if="language == 'ku'">

        <p><strong>سیاسەتی تایبەتمەندی ئەپی  Goalpro Mobile</strong></p>
        <p><strong>دوا نوێکردنەوە: ۱۰ی کانوونی یەکەمی ۲۰۲۳.</strong></p>
        <h5>1. پێشەکی</h5>
        <p>بەخێربێن بۆ Goalpro! ئەم سیاسەتی تایبەتمەندیە ڕوونی دەکاتەوە کە ئێمە چۆن زانیاریە کەسیەکانت بەکاردەهێنین و دەپارێزین کاتێک ئەپی مۆبایلەکەمان بەکاردەهێنیت. بە بەکارهێنانی گۆڵپرۆ، تۆ ڕازی دەبیت بەو مەرجانەی کە لەم سیاسەتەدا وردەکارییان هەیە.</p>

        <h5>2. ئەو زانیاریانەی کە کۆیان دەکەینەوە</h5>
        <p>2.1 <strong>زانیاری کەسی : </strong></p>
        <ul>
          <li>کاتێک خۆت تۆمار دەکەیت بۆ Goalpro ، ئێمە دەتوانین زانیاری کەسی کۆ بکەینەوە وەک ناو، ئیمەیڵ و ژمارەی تەلەفۆن.</li>
        </ul>
        <p>2.2 <strong>پێشبینی و وەڵام بۆ پرسیارەکان : </strong></p>
        <ul>
          <li>ئێمە زانیاری کۆ دەکەینەوە دەربارەی پێشبینیەکانت بۆ یارییەکانی تۆپی پێ و وەڵامەکانت بۆ پرسیارەکان بۆ باشترکردنی ئەزموونی بەکارهێنەر و باشترکردنی خزمەتگوزاریەکانمان.</li>
        </ul>
        <p>2.3 <strong>زانیاری بەشداربوون : </strong></p>
        <ul>
          <li>بۆ ئاسانکردنی بەشداریکردنی ڕۆژانەت، ئێمە زانیاری کۆ دەکەینەوە دەربارەی کارپێکەری مۆبایلەکەت، ژمارەی تەلەفۆن و وردەکاری باڵانس.</li>
        </ul>
        <p>2.4 <strong>بەکارهێنانی داتا :</strong></p>
        <ul>
          <li>ئێمە لەوانەیە زانیاری کۆبکەینەوە دەربارەی چۆنیەتی بەکارهێنانی ئەپی Goalpro ، وەک ئەو تایبەتمەندییانەی بەکاریان دەهێنن و ڕێژەی کارلێکەکانت.</li>
        </ul>

        <h5>3. چۆن زانیاریەکانت بەکاردەهێنین</h5>

        <p>3.1 <strong>دابینکردنی خزمەتگوزاری : </strong></p>
        <ul>
          <li>ئێمە زانیاریەکانت بەکاردەهێنین بۆ دابینکردنی ئەو خزمەتگوزارییانەی کە لەلایەن Goalpro وە پێشکەش دەکرێن، لەوانە پرۆسەکردنی پێشبینیەکان و پرسیارەکان و بەڕێوەبردنی بەشداریکردنت.</li>
        </ul>
        <p>3.2 <strong>باشترکردنی ئەزموونی بەکارهێنەر :</strong></p>
        <ul>
          <li>داتاکەت یارمەتیمان دەدات بۆ باشترکردنی ئەپەکەمان، تایبەتمەندکردنی ناوەڕۆک بەپێی پەسەندکراوەکانت، و ئەزموونێکی تایبەتمەندتر پێشکەش دەکات.</li>
        </ul>
        <p>3.3 <strong>پەیوەندی :</strong></p>
        <ul>
          <li>ئێمە لەوانەیە زانیاری پەیوەندیکردنەکەت بەکاربهێنین بۆ ناردنی نوێکردنەوەی گرنگ، تێبینی و ماددەی پێشکەشکردن. دەتوانیت لە هەر کاتێکدا ڕاگرتنی هەڵبژێریت.</li>
        </ul>

        <h5>4. بەشداربوون و هاوسەنگی</h5>

        <p>4.1 <strong>بەشداریکردنی ڕۆژانە : </strong></p>
        <ul>
          <li>Goalpro خزمەتگوزاری بەشداریکردنی ڕۆژانە پێشکەش دەکات لە ڕێگەی کارپێکەرانی مۆبایلەوە. بەشداریکردنەکە ڕۆژانە نوێ دەکرێتەوە بە ڕەزامەندی تۆ.</li>
        </ul>
        <p>4.2 <strong>زانیاری پارەدان : </strong></p>
        <ul>
          <li>ئێمە زانیاری پارەدان وەرناگرین یان خەزن ناکەین. ڕێگەی پارەدان لە لایەن کۆمپانیاکانی مۆبایلەوە بەڕێوە دەچێت</li>
        </ul>
        <p>4.3 <strong>ڕاگرتن :</strong></p>
        <ul>
          <li>دەتوانن بەشداری کردنەکە ڕابگرن لە هەر کاتێکدا ، بە پێی ڕێنماییەکان کە لە ئەپەکەدا دابینکراوە.</li>
        </ul>

        <h5>5. ڕێوشوێنەکانی سەلامەتی</h5>

        <p>ئێمە ڕێوشوێنی سەلامەتی گونجاو جێبەجێ دەکەین بۆ پاراستنی زانیاری کەسیت. لەگەڵ ئەوەشدا، هیچ ڕێگایەکی گواستنەوە یان هەڵگرتن بە تەواوی پارێزراو نییە، و ئێمە ناتوانین بەرپرسیاری تەواوی داتاکانت بکەین.</p>

          <h5>6. تایبەتمەندی منداڵان</h5>

        <p>Goalpro بۆ منداڵانی خوار تەمەن 13 ساڵ دروست نەکراوە. ئێمە بە نیاز نین زانیاری کەسی لە کەسانی خوار تەمەن 13 ساڵ وەربگرین.</p>

        <h5>7. گۆڕانکاری لە سیاسەتی تایبەتمەندی</h5>

        <p>ئێمە مافی نوێکردنەوەی سیاسەتی تایبەتمەندیمان هەیە. ئێمە باس لە هەر گۆڕانکارییەکی گەورە دەکەین لە ڕێگەی ئەپلیکەیشن یان ڕێگایەکی تری پەیوەندیکردن.</p>

          <h5>8. پەیوەندیمان پێوە بکە</h5>

        <p>ئەگەر هەر پرسیارێکت هەیە یان نیگەرانیت هەیە دەربارەی ئەم سیاسەتی تایبەتمەندیە، تکایە پەیوەندیمان پێوە بکە لە :</p>
        <p>info@midsense-jo.com</p>
        <p>بە بەکارهێنانی Goalpro ، تۆ دان بەوەدا دەنێیت کە تۆ ئەم سیاسەتی تایبەتمەندییەت خوێندووەتەوە و تێگەیشتوویت.</p>
      </div>
      <!-- end kurdish -->



      <div style="height: 500px"></div>
    </div>
  </div>
</template>

<script>
import i18n from "@/translation";
import {
  store,
  notify
} from "../store";

export default {
  name: "PrivacyPolicyComponent",
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    goToRoute(route) {
      this.$router.push("/" + route);
    },
  },
  computed: {
    language() {
      return i18n.locale;
    },
  },
  mounted() {
    if (store.userToken) {
      store.layout.footerType = "FooterHome";
      store.layout.footerItem = "more";
      store.layout.showUserWallet = true;
    } else {
      store.layout.footerType = "";
      store.layout.showUserWallet = false;
    }
    store.layout.showLanguageSwitch = true

  },
};
</script>

<style scoped>
.arrow-title {
  display: flex;
  justify-items: center;
  align-items: center;
  margin-top: 10px;
  height: 54px;
}

.arrow {
  padding-left: 15px;
  width: 55px;
}

.arrow-title img {
  vertical-align: middle;
}

.arrow-title .title {
  padding-right: 55px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: cover;
  width: 100%;
  color: #D74EF8;
  padding: 0;
  font-family: 'Rabar_031';
  font-size: 26px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
}

.privacy-policy {
  flex-direction: column;
  display: flex;
  overflow-y: hidden;
}

.privacy {
  background-color: #0C1538;
  color: #000;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  flex-grow: 1;
  padding: 0px 10px;
  overflow-y: auto;
}

.privacy-text {
  font-family: 'Rabar_031';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  padding: 5px 10px;
  line-height: 27px;
  color: #FFFFFF;

}

.privacy-ltr{
  text-align: left !important;
}
.privacy-rtl{
  text-align: right !important;
}



.text-item {

  margin-left: 15px;
  font-size: 17px;
}

.list-item {
  display: flex;
  background-color: #2a3757;
  border-radius: 6px;
  padding: 5px 10px 7px 10px;
  width: 65%;
  margin: 0 auto;
}

.list-item i {
  font-size: 22px;
  margin-left: auto;
  align-self: center;
  padding-right: 5px;
  margin-top: 0 !important;
}

.items :not(:first-child) {
  margin-top: 18px;
}</style>