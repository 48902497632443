import Vue from 'vue'
import ElementUI from 'element-ui'
import './theme/index.css';
import App from './App.vue'
import router from "./router.js"

import i18n from './translation'
import {
  store,
  resetLayout
} from './store';
import VueAnalytics from 'vue-analytics';
import bottomNavigationVue from "bottom-navigation-vue";

// import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


Vue.component('font-awesome-icon', FontAwesomeIcon)


Vue.use(ElementUI);
Vue.use(bottomNavigationVue);

Vue.config.productionTip = false

//Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'G-HLLBQC9R2X',
  router
});

store.userToken = localStorage.getItem("userToken");

router.beforeEach((to, from, next) => {
  // resetLayout()
  if ((typeof store.userToken != "undefined" && store.userToken != "" && store.userToken && to.name == 'login')) {
    next('/home')
  }
  // else if(!to.meta.public && to.name != 'login' && (typeof store.userToken == "undefined" || store.userToken == "" || !store.userToken) ){
  //   next('/')
  // }
  else {
    next();
  }
})

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')