<template>
  <transition name="slide-fade">
    <div id="myModal" class="modal">
      <div class="modal-content">
        <div class="more">
          <div :class="{ 'rtl': language != 'en' ? true : false, 'items': true }">
            <div class="list-item" @click="goToRoute('manage-account')">
              <div class="text-item">{{ $t("more.manage_account") }} </div>
              <i class="el-icon-right"></i>
            </div>
            <div class="list-item" @click="goToRoute('about-us')">
              <div class="text-item">{{ $t("more.about_us") }} </div>
              <i class="el-icon-right"></i>
            </div>
            <div :class="{'list-item': true,'change-list-item-inline': this.showChangeLanguage}" @click="toShowChangeLanguage()">
              <div v-if="this.showChangeLanguage" :class="{'text-item':true, 'text-item-change-language': this.showChangeLanguage}">
                <span @click="changeLanguage('en')">{{ $t("more.languages.english") }}</span>
                <span class="separator"></span>
                <span style="margin:0px" @click="changeLanguage('ar')">{{ $t("more.languages.arabic") }}</span>
                <span class="separator"></span>
                <span style="margin:0px" @click="changeLanguage('ku')">{{ $t("more.languages.kurdish") }}</span>
              </div>
              <span class="text-item" v-if="!this.showChangeLanguage">{{ $t("more.languages.text") }}</span>
              <i :class="{'el-icon-right': !this.showChangeLanguage}"></i>
            </div>
            <!-- <div class="list-item" @click="goToRoute('faqs')">
              <div class="text-item">{{ $t("more.FaQs") }} </div>
              <i class="el-icon-right"></i>
            </div> -->
            <div class="list-item" @click="goToRoute('terms-and-conditions')">
              <div class="text-item">{{ $t("more.terms") }} </div>
              <i class="el-icon-right"></i>
            </div>
            <div class="list-item" @click="goToRoute('privacy-policy')">
              <div class="text-item">{{ $t("more.privacy_policy") }} </div>
              <i class="el-icon-right"></i>
            </div>
            <div class="list-item" @click="goToRoute('contact-us')">
              <div class="text-item">{{ $t("more.contact_us") }} </div>
              <i class="el-icon-right"></i>
            </div>
            <div class="list-item" @click="goToRoute('winners')">
              <div class="text-item">{{ $t("more.winners") }} </div>
              <i class="el-icon-right"></i>
            </div>
            <!-- <div :class="{'list-item': true,'change-list-item-inline': this.showChangeTheme}" @click="changeTheme()" >
              <div v-if="this.showChangeTheme" :class="{'text-item':true, 'text-item-change-language': this.showChangeTheme}">
                <span @click="changeThemeTo(false)">{{ $t("more.theme.dark") }}</span>
                <span class="separator"></span>
                <span style="margin:0px" @click="changeThemeTo(true)">{{ $t("more.theme.ligth") }}</span>
                <span class="separator"></span>

                <span style="margin:0px" @click="changeThemeTo('green')">{{ $t("more.theme.green") }}</span>
                <span class="separator"></span>
                <span style="margin:0px" @click="changeThemeTo('yallow')">{{ $t("more.theme.yallow") }}</span>
                <span class="separator"></span>
                <span style="margin:0px" @click="changeThemeTo('red')">{{ $t("more.theme.red") }}</span>
              </div>


              <div v-if="!this.showChangeTheme" class="text-item">{{ $t("more.theme.text") }} </div>
              <i v-if="!this.showChangeTheme" class="el-icon-right"></i>
            </div> -->
            <div class="list-item" @click="goToRoute('leagues')">
              <div class="text-item">{{ $t("more.league") }} </div>
              <i class="el-icon-right"></i>
            </div>
            <div class="list-item" @click="showLogout()">
              <div class="text-item">{{ $t("more.sign_out") }} </div>
              <i class="el-icon-right"></i>
            </div>
          </div>
          <ModalLogout v-if="showLogoutModalMore" @closeDialog="onCloseDialog" @logout="doLogout"></ModalLogout>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import i18n from "@/translation";
import {store} from "../store";
import ModalLogout from './ModalLogout.vue'
export default {
  name: "ModalMoreComponent",
  components: {
    ModalLogout
  },
  data() {
    return {
      showChangeLanguage: false,
      showChangeTheme: false,
    };
  },
  methods: {
    onCloseDialog(){
      store.layout.showLogoutModalMore = false;
    },
    doLogout(){
      store.layout.showModalMore = false;
      store.layout.showLoading = false;
      localStorage.removeItem("userToken");
      store.userToken = "";
      store.layout.showLogoutOverlay = false;
      this.$router.push("/");
    },
    showLogout(){
      store.layout.showLogoutModalMore = true;
    },
    toShowChangeLanguage(){
      this.showChangeLanguage = true;
    },
    changeLanguage(value){
      i18n.locale = value;
    },
    changeTheme(){
      this.showChangeTheme = true;
    },
    changeThemeTo(value){
      store.layout.theme ="var(--torliga" + value + ")"
      console.log(store.layout.theme);
    },
    goToRoute(route){
      this.$router.push("/" + route);
      store.layout.showModalMore = false
    },
    checkActive(item) {
      return [
        "item-footer",
        this.activeItem === item ? "item-footer-active" : "",
      ];
    }
  },
  computed: {
    activeItem() {
      return store.layout.footerItem;
    },
    language() {
      return i18n.locale;
    },

    showLogoutModalMore() {
      return store.layout.showLogoutModalMore
    }
  },
  watch: {
  },
  mounted() {
    this.showChangeLanguage = false
    this.showChangeTheme = false
    document.addEventListener('mousedown', (event) => {
      if(event.target.className == 'modal') {
        store.layout.showModalMore = this.showChangeLanguage = this.showChangeTheme = false;
      }
    })
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .2s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
.modal {
  display: block;
  position: absolute;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-weight: 400;
}
.modal-content {
  float: right;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  width: 80%;
  box-shadow: 0px 6px 10px 0px black;
  height: calc(var(--appHeight) - 30px);
  margin-top: calc(var(--contentTop) + 30px);
}

.more {
  flex-direction: column;
  display: flex;
  height: 100%;
}

.items {
  padding-top: 20px;
  background-color: #232c41;
  color: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 20px;
}

.text-item {
  align-self: center;
  margin-left: 15px;
  font-family: 'Rabar_031';
  font-style: normal;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
}
.text-item-change-language{
  display: flex;justify-content: space-evenly; width: 100%;
  color: #232C40;
  margin-left: 0px;
}

.list-item {
  display: flex;
  justify-content: space-between;
  background-color: #2a3757;
  border-radius: 6px;
  padding: 5px 10px 7px 10px;
  width: 70%;
  margin: 0 auto;
  cursor: pointer;
}

.change-list-item-inline{
  background-color: #A9FB03;
}

.rtl .list-item {
  flex-direction: row-reverse;
}

.list-item i {
  font-size: 22px;
 /* margin-left: auto; */
  align-self: center;
  padding-right: 5px;
  margin-top: 0 !important;
}
.separator {
  margin: 0px !important;
  border-left: #000000 1px solid;
}

.rtl .list-item i {
  transform:  rotate(180deg);;
}

.items :not(:first-child) {
  margin-top: 10px;
}
</style>
