<template>
    <div class="col-11 mx-auto">
        <div class="card tesxt-center">
    
            <img class="mx-auto medal" :src="require('@/assets/head-to-head/medal.svg')" width="130"/>
            <span v-if="winner.name" class="text">
                    {{ this.interpolate($t("headToHead.congratulations"), [winnerLabel, userScore.points, userScore.extraPoints])}}
                </span>
                <span v-else class="text">
                    {{ this.interpolate($t("headToHead.competitionDraw"), [userScore.points, userScore.extraPoints])}}
                </span>
                
                
          <div class="loading">
          <img class="rotate" :src="require('@/assets/head-to-head/loading.svg')" />
        </div>
                
        </div>
        

      </div>
  </template>
  
  <script>

  import i18n from "@/translation";
  import utils from "@/utils";
  
  export default {
    name: "CongratularionCardH2hComponent",
    mixins: [utils],
    props: ["winner", "playerID", "userScore"],
    methods: {          
    },
    computed: {
      language() {
        return i18n.locale;
      },
      winnerLabel(){
        return this.winner.name
      }
    }
  };
  </script>
  
  <style scoped>
    
    
    .card{
      background: #51105F !important;
      border-radius: 15px !important;
      padding: 20px;
    
    }
    
    .card .medal{
      position: absolute;
      top: 0px !important;
      left: 0px !important;
      right: 0px !important;
      
    }
    
    .card .text{
      margin-top: 200px !important;
      color: #FFCBAA !important;
      font-size: 18px !important;
    }
    
    .loading{
      margin-top: 40px;
    }
    .rotate {
      animation: rotation 2s infinite linear;
    }

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
</style>