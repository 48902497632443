<template>
  <div class="about-us">
    <div class="arrow-title">
      <div class="arrow">
        <img @click="goBack" :src="require('@/assets/arrow.svg')"/>
      </div>
      <div class="title">{{ $t("faqs.title") }}</div>            
    </div>
    <div class="about" :style="{ direction: language != 'en' ? 'rtl' : 'ltr' }">
      <div class="list-item-selected">
        <div class="text-item-selected">
          {{ $t("contact.email") }}
        </div>
        <i style="display: flex;justify-content: center;align-items: center;width: 22px;height: 22px;position: relative;float: right;">
          <img src="@/assets/arrow-dark.svg" alt="" />
        </i>
      </div>
      <div class="list-item">
        <div class="text-item">{{ $t("faqs.torliga") }}</div>
        <i style="display: flex;justify-content: center;align-items: center;width: 22px;height: 22px;position: relative;float: right;color: #a9fb03;">
          <img style="transform: rotate(180deg);" src="@/assets/arrow.svg" alt="" />
        </i>
      </div>
      <div class="list-item">
        <div class="text-item">{{ $t("faqs.play") }}</div>
        <i style="display: flex;justify-content: center;align-items: center;width: 22px;height: 22px;position: relative;float: right;color: #a9fb03;">
          <img style="transform: rotate(180deg);" src="@/assets/arrow.svg" alt="" />
        </i>
      </div>
      <div class="list-item">
        <div class="text-item">{{ $t("faqs.points") }}</div>
        <i style="display: flex;justify-content: center;align-items: center;width: 22px;height: 22px;position: relative;float: right;color: #a9fb03;">
          <img style="transform: rotate(180deg);" src="@/assets/arrow.svg" alt="" />
        </i>
      </div>
      <div class="list-item">
        <div class="text-item">{{ $t("faqs.win_prizes") }}</div>
        <i style="display: flex;justify-content: center;align-items: center;width: 22px;height: 22px;position: relative;float: right;color: #a9fb03;">
          <img style="transform: rotate(180deg);" src="@/assets/arrow.svg" alt="" />
        </i>
      </div>
      <div class="list-item">
        <div class="text-item">{{ $t("faqs.whats_prizes") }}</div>
        <i style="display: flex;justify-content: center;align-items: center;width: 22px;height: 22px;position: relative;float: right;color: #a9fb03;">
          <img style="transform: rotate(180deg);" src="@/assets/arrow.svg" alt="" />
        </i>
      </div>
      <div class="list-item">
        <div class="text-item">{{ $t("faqs.coins") }}</div>
        <i style="display: flex;justify-content: center;align-items: center;width: 22px;height: 22px;position: relative;float: right;color: #a9fb03;">
          <img style="transform: rotate(180deg);" src="@/assets/arrow.svg" alt="" />
        </i>
      </div>
      
    </div>
  </div>
</template>

<script>
import i18n from "@/translation";
import { store, notify } from "../store";

export default {
  name: "FaqsComponent",
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    goToRoute(route) {
      this.$router.push("/" + route);
    },
  },
  computed: {
    language() {
      return i18n.locale;
    },
  },
  mounted() {
    store.layout.showUserWallet = true;
    store.layout.footerItem = "more";
    store.layout.footerType = "FooterHome";
    store.layout.showLanguageSwitch = true

  },
};
</script>

<style scoped>
.arrow-title {
    display: flex;
    justify-items: center;
    align-items: center;
    height: 54px;
  }
  .arrow {
    padding-left: 15px;
    width: 55px;
  }

  .arrow-title img {
    vertical-align: middle;
  }

  .arrow-title .title {
    padding-right: 55px;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-size: cover;
    width: 100%;
    color: #fff;
    padding: 0;
    font-family: 'Rabar_031';
    font-size: 26px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
  }
.about-us {
  flex-direction: column;
  display: flex;
  height: 100%;
}

.about {
  background-color: #232C40;
  color: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  flex-grow: 1;
  padding: 8px;
  padding-top: 70px;
  flex-grow: 1;
  height: calc(var(--appHeight) - 106px) !important;
}

.about-text{
  font-family: 'Rabar_031';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
}

.text-item {
  align-self: center;
  width: 100%;
  position: relative;
  font-size: 17px;
}

.text-item-selected {
  align-self: center;
  width: 100%;
  position: relative;
  font-size: 17px;
  color: #232C40;
}


.list-item {
  display: flex;
  flex-direction: row;
  background-color: #2a3757;
  border-radius: 6px;
  padding: 5px 10px 7px 10px;
  width: 65%;
  margin: 0 auto;
  margin-top: 10px;
}

.list-item-selected {
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  padding: 5px 10px 7px 10px;
  width: 65%;
  margin: 0 auto;
  margin-top: 10px;
  background-color: #a9fb03;
}


.list-item i {
  font-size: 22px;
  align-self: center;
  margin-top: 0 !important;
}

.items :not(:first-child) {
  margin-top: 18px;
}
</style>
