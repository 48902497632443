<template>
  <div class="content-page">
    <div class="status-content">
      <div class="fixed-header col-10 mx-auto">
        <div class="status-header">
          <div
            @click="getBetsStatus('current')"
            :class="{ active: type == 'current', current: true }"
          >
            {{ $t("status.current") }}
          </div>
          <div
            @click="getBetsStatus('history')"
            :class="{ active: type == 'history', history: true }"
          >
            {{ $t("status.history") }}
          </div>
        </div>
      </div>
      <div class="status-items">
        <div
          v-for="item in status"
          :key="item.day"
          class="list-item"
          @click="seeDetail(item)"
        >
          <div class="text-item">
            {{ moment(item.day, "YYYY-MM-DD").format("DD/MM/YYYY") }}
          </div>
          <div class="points-item">
            {{ item.points + " " + $t("status.points") }}
          </div>
          <i class="el-icon-caret-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Service } from "../services";
import i18n from "@/translation";
import { store, notify } from "../store";
import moment from "moment";

export default {
  name: "StatusComponent",
  data() {
    return {
      status: [],
      type: "current",
    };
  },
  methods: {
    moment: (date) => {
      return moment(date);
    },

    goBack() {
      this.$router.back();
    },

    seeDetail(item) {
      this.$router.push({
        name: "status-detail",
        params: { statusItem: item, type: this.type },
      });
    },

    sumPoints(bets) {
      let total = 0;
      bets.forEach((b) => {
        // [0] because bets is an array on response object
        total += b[0].betWin ? b[0].points : 0;
      });
      return total;
    },

    getBetTeams(item) {
      let betTeam = [];
      if (item.bets[0].homeWin)
        betTeam.push(item.homeTeam["name_" + this.language]);
      if (item.bets[0].awayWin)
        betTeam.push(item.awayTeam["name_" + this.language]);
      if (item.bets[0].draw) betTeam.push("Draw");
      return betTeam.join(" / ");
    },

    getDetail(matches) {
      return matches.map((m) => {
        return {
          match:
            m.homeTeam["name_" + this.language] +
            " - " +
            m.awayTeam["name_" + this.language],
          winner:
            m.winner == "A"
              ? m.awayTeam["name_" + this.language]
              : m.winner == "H"
              ? m.homeTeam["name_" + this.language]
              : "Draw",
          betTeams: this.getBetTeams(m),
          matchStart: m.matchStart,
          processed: m.bets[0].processedAt != null,
          scores: "(" + (m.homeScore || 0) + ":" + (m.awayScore || 0) + ")",
          betWin: m.bets[0].betWin,
        };
      });
    },

    setStatusData(data) {
      this.status = data.map((d) => {
        let o = {};
        o.day = d.day;
        o.points = this.sumPoints(d.matches.map((m) => m.bets));
        o.detail = this.getDetail(d.matches);
        return o;
      });
    },

    async getBetsStatus(type) {
      this.type = type;
      let response = await Service.getBetsStatus(type);
      if (response && response.status === 200) {
        this.setStatusData(response.data);
      }
    },
  },
  computed: {
    language() {
      return i18n.locale;
    },
  },
  mounted() {
    store.layout.showUserWallet = true;
    store.layout.footerItem = "status";
    store.layout.footerType = "FooterHome";
    store.layout.showGoBack = true;
    this.getBetsStatus("current");
    console.log(store.userToken)
    store.layout.showLanguageSwitch = true

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content-page {
  justify-content: flex-start;
}
.status-content {
  padding: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.arrow-title{
  display: flex;
  justify-items: center;
  align-items: center;
}

.arrow {
  padding-left: 15px;
  width: 55px;
}

.arrow-title img {
  vertical-align: middle;
}

.arrow-title .title {
  padding-right: 55px;
}

.title {
  background-size: cover;
  width: 100%;
  color: #ffffff;
  font-size: 26px;
  padding: 0;

  padding: 8px 0;
  background: none;
}
.status-items {
  background-color: #0C1538;
  color: #ffffff;
  padding-bottom: 0px;
  /* height: calc(100% - 90px); */
  margin-top: 20px;
  width: 100%;
  /* overflow-y: scroll; */
  padding-bottom: 15px;
  direction: ltr !important;
}

.status-header {
  color: #2a3757;
  background-color: #182252;
  color: #ffffff;
  display: flex;
  font-size: 22px;
  padding: 2px 0;

  border-radius: 15px;
  border-radius: 15px;
}

.current {
  width: 50%;
}

.history {
  width: 50%;
}

.current.active {
  background: linear-gradient(#3046BA,#18235D);
  color: white;
  border-radius: 15px;
  border-bottom: none;
}

.history.active {
  background: linear-gradient(#3046BA,#18235D);
  color: white;
  border-radius: 15px;
  border-bottom: none;
}

.text-item {
  align-self: center;
  margin-right: auto;
  font-size: 15px;
  color: #FFCBAA;
  padding-left: 20px;
}

.points-item {
  align-self: center;
  padding-right: 30px;
  font-size: 15px;
  color: #FFC115;
}

.list-item {
  display: flex;
  background-color:transparent;
  border-radius: 6px;
  padding: 5px 10px 7px 10px;
  /* width: 65%; */
  margin: 0 auto;
  justify-content: right;
  margin-top: 12px;
}

.list-item i {
  font-size: 16px;
  align-self: center;
  padding-right: 10px;
  margin-top: 0 !important;
}
</style>
