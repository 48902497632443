<template>
  <div class="content-page">

    <div align="center">
      <div class="logo">
        <img src="../assets/goalpro.svg" alt="logo" />
      </div>

      <div class="col-10 bottom-section ">
        <label for="">{{ $t('inputs.otp') }}</label>
        <CustomInput :otp="true" style="margin-top: 15px;" :type="'number'" @setInput="onInputOtp" v-maska="'#'">
        </CustomInput>
        <div>
          <el-button @click="verifyPinCode" class="button button-valid mt-4" id="cta_button" type="primary">
            <p>{{ $t("forgot_password.continue") }}</p>
          </el-button>
        </div>
      </div>



    </div>
  </div>
</template>

<script>
import {
  maska
} from "maska";
import i18n from "@/translation";
import {
  store,
  notify
} from "../store";
import CustomInput from "../components/CustomInput.vue"
import {
  Service
} from "../services";

export default {
  name: "LoginOtpComponent",
  components: {
    CustomInput
  },
  directives: {
    maska
  },
  data() {
    return {
      otp: ["", "", "", ""],
      ts: 0
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    goToRoute(route) {
      this.$router.push("/" + route);
    },
    onInputOtp(event) {
      this.otp[event[1] - 1] = event[0];
      if (event[1] <= 3)
        document.getElementsByName(event[1])[0].focus()
    },
    async verifyPinCode() {
      let response = await Service.loginVerifyPinCode(this.otp.join(''), this.ts)
      if (response.data.token) {
        store.userToken = response.data.token
        localStorage.setItem("userToken", store.userToken)
        this.goToRoute("home")
      } else {
        notify(this.$t("error.error"), this.$t("OTP is invalid"), "error");
      }
    },
    getNowEpoch() {
      return parseInt(new Date().getTime() / 1000);
    },

  },
  computed: {
    language() {
      return i18n.locale;
    },
  },
  async mounted() {
    store.layout.showGoBack = false;
    store.layout.footerType = 'FooterInitial'
    store.layout.showUserWallet = false;
    store.layout.textHeader = 'Log In'
    store.layout.showLanguageSwitch = true
    store.layout.showHeader = false;
    store.layout.showLoading = false;
    this.ts = this.getNowEpoch();
  },
};
</script>

<style scoped>
.content-page {
  background-image: url("../assets/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100% !important;
}




.bottom-section {
  position: relative;
  bottom: -300px !important;
}

.logo {
  margin-top: 120px;
}



.arrow-title {
  display: flex;
  justify-items: center;
  align-items: center;
  height: 54px;
}

.arrow {
  padding-left: 15px;
  width: 55px;
}

.arrow-title img {
  vertical-align: middle;
}

.arrow-title .title {
  padding-right: 55px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: cover;
  width: 100%;
  color: #fff;
  padding: 0;
  font-family: 'Rabar_031';
  font-size: 26px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 40px;
}

.button-valid {
  background-color: #D74EF8 !important;
  color: #FFFFFF !important;
  border-radius: 15px !important;

}

label {
  color: #ffffff;
  font-size: 14px;
  display: block;
  margin-bottom: -28px !important;


}

.el-button p {
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.button {
  width: 100%;
  border-radius: 15px !important;
  font-family: 'Rabar_031';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #fff;
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

.el-button--primary {
  background-color: #445C87;
  border: 0px;
  border-radius: 20px;
  line-height: 15px !important;
}

@media (max-width: 600px) {
  .login-icons-page {
    font-size: 14px;
    margin-top: 30px;
  }

  .login-icons-page>div {
    width: 100px;
  }

  .login-icons-page>div img {
    height: 20px;
    margin-left: 5px;
  }
}
</style>