<template>
  <div class="content-page">
    <div align="center">
      <div class="titles">
        <h1>{{ $t("signup.title") }}</h1>
      </div>
      <div style="max-width: 250px">
        <CustomInput
          style="margin-top: 15px"
          :type="'text'"
          :label="$t('inputs.first_name')"
          :placeholder="$t('inputs.name_placeholder')"
          @setInput="onInputFirstName"
        >
        </CustomInput>
        <CustomInput
          style="margin-top: 15px"
          :type="'text'"
          :label="$t('inputs.last_name')"
          :placeholder="$t('inputs.name_placeholder')"
          @setInput="onInputLastName"
        >
        </CustomInput>
        <CustomInput
          :optional="true"
          style="margin-top: 15px"
          :type="'phonenumber'"
          :label="$t('inputs.phonenumber_short')"
          :placeholder="'7XX XXX XXXX'"
          v-maska="'7## ### ####'"
          @setInput="onInputCellphone"
        >
        </CustomInput>
        <CustomInput
          :value="this.email"
          :optional="true"
          @setKeyUp="isEmailValid"
          style="margin-top: 15px"
          :checked="emailValid"
          :type="'text'"
          :label="$t('inputs.email')"
          :placeholder="$t('inputs.email_placeholder')"
          @setInput="onInputEmail"
        >
        </CustomInput>
        <div class="label-select">{{ $t("inputs.country") }}</div>
        <el-select
          v-model="countryId"
          class="m-2"
          :placeholder="$t('inputs.country_placeholder')"
          style="margin-top: 15px; width: 250px; height: 42px"
        >
          <el-option
            v-for="item in countries"
            :key="item.id"
            :label="item['name_'+language]"
            :value="item.id"
          />
        </el-select>
        <div>
          <el-button
            @click="sendInformation"
            :class="{ button: true, 'button-valid': true }"
            type="primary"
          >
            {{ $t("signup.continue") }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { maska } from "maska";
import i18n from "@/translation";
import { store, notify } from "../store";
import { Service } from "../services";
import CustomInput from "../components/CustomInput.vue";

export default {
  name: "SingUpComponent",
  props: {
    msg: String,
  },
  components: {
    CustomInput,
  },
  directives: { maska },
  data() {
    return {
      firstName: "",
      lastName: "",
      cellPhone: "",
      email: "",
      countryId: "",
      countries: "",
      formValid: false,
      emailValid: false,
    };
  },
  methods: {
    goToRoute(route) {
      this.$router.push("/" + route);
    },
    onInputFirstName(event) {
      this.firstName = event;
    },
    onInputLastName(event) {
      this.lastName = event;
    },
    onInputCellphone(event) {
      this.cellPhone = event;
    },
    onInputEmail(event) {
      this.email = event;
    },
    validateEmail() {
      if (!this.isEmailValid()) {
        notify(this.$t("error.error"), this.$t("error.email_invalid"), "error");
        return false;
      }
      return true;
    },
    isEmailValid() {
      const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      this.emailValid = expression.test(this.email);
      return this.emailValid;
    },
    validateCellphone() {
      if (!this.containsOnlyNumbers(this.cellPhone)) {
        notify(
          this.$t("error.error"),
          this.$t("error.invalid_phone_number"),
          "error"
        );
        return false;
      }
      return true;
    },
    containsOnlyNumbers(value) {
      return /^\d+$/.test(value);
    },
    validateRquiredInputs() {
      if (this.firtName == "") {
        notify(
          this.$t("error.error"),
          this.$t("error.first_name_required"),
          "error"
        );
        return false;
      }
      if (this.lastName == "") {
        notify(
          this.$t("error.error"),
          this.$t("error.last_name_required"),
          "error"
        );
        return false;
      }
      if (this.cellPhone != "") {
        this.validateCellphone();
      }
      if (this.email != "") {
        this.validateEmail();
      }
      if (this.countryId == "") {
        notify(this.$t("error.error"), this.$t("error.country_required"), "error");
        return false;
      }
      return true;
    },
    async sendInformation() {
      if (this.validateRquiredInputs()) {
        Service.createAccountUserData(
          this.firstName,
          this.lastName,
          this.email,
          Number("964" + this.cellPhone.replaceAll(" ", "")),
          this.countryId
        ).then((response) => {
          if (response.status == 200) {
            this.goToRoute(Service.nextStepToRoute(store.nextStep));
          }
        });
      }
    },
    async loadCountries() {
      Service.loadCountries(1, 50000).then((response) => {
        if (response.status == 200) {
          this.countries = response.data.result;
        }
      });
    },
  },
  computed: {
    language() {
      return i18n.locale;
    },
  },
  mounted() {
    if (store.nextStep == "") {
      this.goToRoute("");
    } else {
      this.loadCountries();
      store.layout.showGoBack = false;
      store.layout.footerType = "FooterInitial";
      store.layout.showUserWallet = false;
      store.layout.textHeader = "Log In";
      store.layout.showLanguageSwitch = true;
    }
  },
};
</script>

<style scoped>
.label-select {
  position: absolute;
  z-index: 1001;
  margin-top: 20px;
  margin-left: 10px;
  font-family: Rabar_031;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #252f45;
}
.el-select >>> .el-input__inner {
  border-radius: 6px !important;
  padding-left: 9px !important;
  padding-top: 15px !important;
  font-family: "Rabar_031" !important;
  font-size: 13px !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #252f45 !important;
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: #252f45 !important;
}

.el-select >>> .el-input__inner:focus {
  border-color: #252f45 !important;
}

.el-select-dropdown__item.selected {
  color: #252f45 !important;
  font-weight: 1000;
}

.content-page {
  justify-content: space-around;
}
.titles {
  display: flex;
  flex-direction: column;
  align-content: stretch;
}

h1 {
  font-family: "Rabar_031";
  font-size: 28px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  color: #ffffff;
}
.button-valid {
  background-color: #aaff03 !important;
  color: #26314a !important;
  margin-top: 45px !important;
}
.button {
  width: 100%;
  border-radius: 6px !important;
  font-family: "Rabar_031";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #fff;
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}
.el-button--primary {
  background-color: #445c87;
  border: 0px;
  border-radius: 20px;
  line-height: 15px !important;
}

@media (max-width: 600px) {
  .login-icons-page {
    font-size: 14px;
    margin-top: 30px;
  }

  .login-icons-page > div {
    width: 100px;
  }
  .login-icons-page > div img {
    height: 20px;
    margin-left: 5px;
  }
}
</style>
