<template>
  <div v-if="showFooter" class="footer-text">
    <!-- <span @click="goToRoute('terms-and-conditions')">{{ $t("footer.terms") }}</span>
    <span class="footer-separator"></span>
    <span @click="goToRoute('privacy-policy')">{{ $t("footer.policy") }}</span>
    <span class="footer-separator"></span>
    <span @click="goToRoute('about-us')">{{ $t("footer.about") }}</span> -->
  </div>
</template>

<script>
import { store } from "../store";
export default {
  name: "FooterInitial",
  data() {
    return {};
  },
  methods: {
    goToRoute(route) {
      this.$router.push("/" + route);
    },
  },
  computed: {
    showFooter() {
      return store.layout.footerType;
    },
    themeLayout(){
      return store.layout.theme;
    },
  }
};
</script>

<style scoped>
/* @import '../assets/css/colorcode.css'; */

.footer-text {
  color: #ffffff;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 5px;
  justify-content: space-evenly;
  font-family: var(--appFontFamily);
  font-size: 14px;
  align-items: center;
  z-index: 2;
}

.footer-text .footer-separator {
  border-left: #D74EF8 1px solid;
  height: 15px;
  margin: 0px 10px;
}

@media (max-width: 350px) {
  .footer-text .footer-separator {
    margin: 0px 3px;
  }
}

.footer-text span:not(.footer-separator) {
  padding:4px 8px 6px 8px;
}
</style>
