<template>
  <span>
    <el-dialog :visible.sync="showBuyCoinsDialog" width="90%" center @close="closeShowBuyCoinsDialog" :show-close="false">
      <div class="get-coins-content"> 
        <div class="items">
          <div class="promo-text" v-if="type == 'P'">{{ $t('getCoins.promo') }}</div>
          <div v-for="item in coinsOptions.filter(c => (c.promo && c.value == buyCoinsAmount) || type == 'N')" 
               :key="item.value" :class="{'list-item':true,'list-item-video':item.video}" @click="buyCoinsDialog(item.value)">
            <div :class="{'text-item':true,'text-item-video':item.video}">{{ type == 'N' ? item.label : item.promoLabel }} </div>
            <div :class="{'buy-item':true,'buy-item-video':item.video}">{{ $t('getCoins.buy') }} </div>
          </div>                        
          <div v-if="type == 'P'" class="close" @click="closePromo">{{ $t('getCoins.close')  }} </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="buttons-container">
          <div v-if="type != 'P'" class="buttons" @click="closeShowBuyCoinsDialog">{{ $t("getCoins.close")}} </div>
        </div>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="showValidateBuyCoinsDialog" width="90%" style="margin-top:5vh" center @close="closeValidateBuyCoinsDialog" :show-close="false">
      <div class="get-coins-validate-content">
        <img src="@/assets/pin-lock.svg" />
        <h2 class="pin-header-title">Enter Pin</h2>
        <div class="dialog-message">{{ $t('getCoins.enter_pin') }}</div>
        <el-row style="margin: 30px 0 ;" :gutter="20">
          <el-col v-for="item in 4" :key="item" :span="6">
            <el-input
              @keydown.native="keyup($event, item)"
              maxlength="1"
              type="number"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              inputmode="numeric" 
              pattern="[0-9]*"
              :ref="'pinCode_' + item"
              autocomplete="off"
              class="pinCode"
              placeholder="0"
              v-model="pinCode[item - 1]">
            </el-input>
          </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
          <div class="buttons-container">
            <div class="buttons1" @click="sendValidation">{{ $t("getCoins.submit")}} </div>
          </div>
        </span>
      </div>
    </el-dialog>
  </span>
</template>

<script>
import { Service } from "../services";
import i18n from "@/translation";
import { store, notify } from "../store";

export default {
  name: "GetCoinsComponent",
  data() {
    return {
      coinsOptions: [
        { 'label': this.$t('getCoins.prices.label_3'), 'value': 3, 'promo': true, 'promoLabel': this.$t('getCoins.promoPrices.label_3') },
        { 'label': this.$t('getCoins.prices.label_6'), 'value': 6, 'promo': true, 'promoLabel': this.$t('getCoins.promoPrices.label_6') },
        { 'label': this.$t('getCoins.prices.label_9'), 'value': 9, 'promo': true, 'promoLabel': this.$t('getCoins.promoPrices.label_9') },
        { 'label': this.$t('getCoins.prices.label_12'), 'value': 12, 'promo': true, 'promoLabel': this.$t('getCoins.promoPrices.label_12') },
        { 'label': this.$t('getCoins.prices.label_48'), 'value': 48, 'promo': false },
        { 'label': this.$t('getCoins.prices.label_70'), 'value': 70, 'promo': false },
      ],
      buyCoinsAmount: 3,
      showValidateBuyCoinsDialog: false,
      showBuyCoinsDialog: false,
      pinCode: [],
      type: 'N',
      allowPromo: false
    };
  },
  methods: {
    async buyCoinsDialog(buyCoinsAmount) { 
      this.allowPromo = this.type == 'N' 
      this.buyCoinsAmount = buyCoinsAmount
      store.ignoreDefaultError = true
      let response = await Service.purchaseCoinsRequestPinCode(this.buyCoinsAmount, this.type)
      if (response && (response.status === 200 || response.status === 400) && response.data.status && response.data.status.toLowerCase() === 'failed') {
        notify(this.$t('getCoins.recharge'), '', 'error')
      } else {
        if (response && response.status === 200) {
          this.showValidateBuyCoinsDialog = true
          setTimeout(() => {
            this.pinCode = []
            setTimeout(() => {
              this.$refs.pinCode_1[0].focus()
              this.showBuyCoinsDialog = false
            }, 100)
          }, 50)
        }
      }
    },
    
    async sendValidation() {
      if (this.pinCode.filter(f => { return f }).length != 4) {
        notify(this.$t('error.error'), this.$t('error.invalid_pin_code'), 'error')
        return
      }
      let response = await Service.purchaseCoinsVerifyPinCode(this.buyCoinsAmount, this.pinCode.join(''), this.type)
      if (response && (response.status === 200 || response.status === 400 ) && response.data.status && response.data.status.toLowerCase() === 'failed') {
        notify(this.$t('getCoins.recharge'), '', 'error')
      } else {
        if (response && response.status === 200) {
          this.showValidateBuyCoinsDialog = false
          let isPromo = this.coinsOptions.find(c => { return c.value == this.buyCoinsAmount }).promo
          if (isPromo && this.allowPromo) {
            setTimeout(() => {
              this.type = 'P'
              this.showBuyCoinsDialog = true
              this.pinCode = []
              this.allowPromo = false
            }, 200)
          }
          else {
            store.layout.showGetCoins = false
            this.type = 'N'
          }
        }
      }
    },

    keyup(event, item) {
      if (item < 4 && event.keyCode != 8) {
        setTimeout(() => {
          this.$refs['pinCode_' + (item + 1)][0].focus()
        }, 30)
      }
      else if (item > 1 && event.keyCode == 8) {
        setTimeout(() => {
          this.$refs['pinCode_' + (item - 1)][0].focus()
        }, 30)
      }
    },
    closePromo(){
      this.showBuyCoinsDialog = false
      this.pinCode = []
      this.type = 'N'
    },
    closeShowBuyCoinsDialog(){
      store.layout.showGetCoins = false
      this.pinCode = []
    },
    closeValidateBuyCoinsDialog(){
      this.pinCode = []
    }
  },
  computed: {
    language() {
      return i18n.locale;
    },
    showGetCoins() {
      return store.layout.showGetCoins
    }
  },
  watch: {
    showGetCoins(){
      this.showBuyCoinsDialog = store.layout.showGetCoins
    }
  }
};

</script>

<style scoped>
.buttons-container{
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}
.buttons{
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  width: 110px;
  height: 33px;
  border-radius: 50px;
  margin: 5px;
  background: linear-gradient(180deg, #3046BA 0%, #18235D 100%);
  font-family: 'Rabar_031';
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  position: absolute;
  bottom: -19px;
}

.pin-header-title{
  color: #FFCBAA;
  font-family: 'Rabar_031';
  font-size: 26px;
  font-weight: 400;
  margin: 20px 0;
}

.buttons1{
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  width: 110px;
  height: 33px;
  border-radius: 50px;
  margin: 5px;
  background: linear-gradient(180deg, #FFCBAA 0%, #C3B9B4 100%);
  font-family: 'Rabar_031';
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #0C1538;
  position: absolute;
  bottom: -19px;
}
.get-coins-content {
  display: flex;
}

.get-coins-validate-content {
  margin-top: 0px;
  background-color: #6C1681;
  color: #ffffff;
  border-radius: 20px;
  color: white;
  padding: 50px 20px 30px 20px;
  text-align: center;
}

.items {
  margin-top: 0px;
  padding-top: 20px;
  background-color: #182252;
  border-radius: 30px;
  flex-grow: 1;
  padding-bottom: 20px;
}

.text-item {
  align-self: center;
  margin-right: auto;
  padding-left: 20px;
  font-family: 'Rabar_031';
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.text-item-video {
  color: #2A3757 !important;
}

.buy-item {
  height: 25px;
  align-self: center;
  background: linear-gradient(180deg, #FFCBAA 0%, #C3B9B4 100%);
  padding: 3px 22px;
  border-radius: 10px;
  padding-top: 0px;
  font-family: 'Rabar_031';
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #0C1538
}

.buy-item-video {
  background-color: #232C40 !important;
  color: #FFFFFF !important;
}

.list-item {
  display: flex;
  background: linear-gradient(180deg, #213182 0%, #111941 100%);
  border-radius: 10px;
  padding:6px 10px 6px 10px;
  width: 90%;
  margin: 0 auto;
  justify-content: right;
  margin-top: 12px;
}

.list-item-video {
  background-color: #FFB000 !important;
}


:deep(.el-dialog__wrapper) {
  top: calc(100vh / 12) !important;
}

:deep(.el-dialog) {
  background-color: #182252;
  border-radius: 20px !important;
  box-shadow: -3px 3px 6px 0px black;
}

:deep(.el-input-number) {
  width: 100%;
}

:deep(.el-select) input {
  font-size: 16px;
}

:deep(.dialog-footer) button {
  /* width: 100%;
  font-size: 16px;
  display: block; */
  background-color: #182252;
  /* border-radius: 5px;
  opacity: 0.98;
  cursor: pointer;
  border-color: #1c0049; */
}
:deep(.dialog-footer ) button:hover {
  background-color: #2A3757;
  color: #fff;
}

:deep(.dialog-footer) {
  padding: 0 !important;
}

.dialog-message {
  font-size: 18px;
  word-break: break-word;
  margin-bottom: 15px;
  color: #ffffff;
}

:deep(.el-dialog__header) {
    padding: 0;
}

:deep(.el-dialog__header) i{
    color: #ffffff;
    font-size: 20px;
}

:deep(.el-dialog--center) .el-dialog__body {
  padding: 0 !important;
  text-align: center !important;
}

:deep(.el-input__inner) {
  font-size: 16px;
}


.el-select {
  width: 100%;
  margin-top: 20px;
}

:deep(.pinCode input) {
  text-align: center !important;
  font-size: 16px;
  background-color: #51105F;
  color: #FFFFFF;
  border: 1px solid #51105F;
  max-width: 40px;
  padding: 0 5px !important;
}

.promo-text {
  font-size: 18px;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 40px;
}

.close {
    font-size: 16px;
    background: linear-gradient(180deg, #3046BA 0%, #18235D 100%);
    color: #FFFFFF;
    padding: 3px 22px;
    border-radius: 4px;
    padding-top: 0px;
    width: 100px;
    margin: 50px auto 10px auto;
}
</style>
