<template>
  <div class="content-page">
  <div align="center">
    <div class="titles" >
      <div class="h1"> {{ $t("signup.title") }}</div>
      <div class="h2">{{ $t("signup.subtitle") }}</div>
    </div>
    <div class="inputs">
      <CustomInput 
        style="margin-top: 15px;" 
        :value="this.username"
        :checked="this.username.length > 0"
        :type="'text'" 
        :label="$t('inputs.username')" 
        :placeholder="$t('inputs.email_phone_placeholder')" 
        @setInput="onInputUsername">
      </CustomInput>
      <CustomInput 
        style="margin-top: 15px;margin-right: 10px;"
        :type="'password'" 
        :value="this.password"
        :label="$t('inputs.create_password')"
        :placeholder="$t('inputs.create_password_placeholder')" 
        @setInput="onInputPassword">
      </CustomInput>
      <CustomInput 
        style="margin-top: 15px;"
        :type="'password'" 
        :value="this.confirmPassword"
        :label="$t('inputs.confirm_password')" 
        :placeholder="$t('inputs.confirm_password_placeholder')" 
        @setInput="onInputConfirmPassword">
      </CustomInput>
      <div>
        <el-button
            @click="validateForm"
            :class="{'button': true, 'button-valid': (this.username.length > 0) && (this.password.length>0) && (this.confirmPassword.length>0)}"
            type="primary">
            {{ $t("signup.continue") }}
        </el-button>
      </div>
      <h3>
        {{ $t("signup.text1")}} <strong>{{ $t("signup.text2")}}</strong>
      </h3>
      <h4>
        {{ $t("signup.text3")}} <a class="label-login" @click="goToRoute('login')">{{ $t("signup.login")}}</a>
      </h4>
    </div>
  </div>
</div>
</template>

<script>
import { maska } from "maska";
import i18n from "@/translation";
import { store, notify } from "../store";
import { Service } from "../services";
import CustomInput from "../components/CustomInput.vue"

export default {
  name: "SingUpComponent",
  props: {
    msg: String,
  },
  components: {
    CustomInput
  },
  directives: { maska },
  data() {
    return {
      username: "",
      password: "",
      confirmPassword: "",
      formValid: false,
      emailValid: false
    };
  },
  methods: {
    goToRoute(route) {
      this.$router.push("/" + route);
    },
    onInputUsername(event){
      this.username = event;
    },
    onInputPassword(event){
      this.password = event;
    },
    onInputConfirmPassword(event){
      this.confirmPassword = event;
    },
    async validateForm(){
     
      //for password
      if(this.password.length == 0){
          notify(this.$t("error.error"),this.$t("error.password.required"),"error");
          return;
        }else{
          if(this.password != this.confirmPassword){
            notify(this.$t("error.error"),this.$t("error.password.confirm_identical"),"error");
            return;
          }else{
            // Password must be at least 8 characters long
            if (this.password.length < 8) {
              notify(this.$t("error.error"),this.$t("error.password.eight_character"),"error");
              return;
            }
            // Password must contain at least one lowercase letter
            if (!/[a-z]/.test(this.password)) {
              notify(this.$t("error.error"),this.$t("error.password.one_lower"),"error");
              return;
            }
            // Password must contain at least one uppercase letter
            if (!/[A-Z]/.test(this.password)) {
              notify(this.$t("error.error"),this.$t("error.password.one_upper"),"error");
              return;
            }
            // Password must contain at least one digit
            if (!/\d/.test(this.password)) {
              notify(this.$t("error.error"),this.$t("error.password.one_digit"),"error");
              return;
            }
            // Password must contain at least one special character
            if (!/[^a-zA-Z0-9]/.test(this.password)) {
              notify(this.$t("error.error"),this.$t("error.password.one_symbol"),"error");
              return;
            }
          }
        }
        this.formValid = true;

        if(this.formValid){
          let response = await Service.createAccount(this.username.replaceAll(" ",""),this.password,this.confirmPassword);
          if(response.status == 201)
            this.goToRoute(Service.nextStepToRoute(store.nextStep));
        }
    }
  },
  computed: {
    language() {
      return i18n.locale;
    }
  },
  mounted() {
	  store.layout.showGoBack = false;
    store.layout.footerType = 'FooterInitial'
    store.layout.showUserWallet = false;
    store.layout.textHeader = 'Log In'
    store.layout.showLanguageSwitch = true
  },
};
</script>

<style scoped>
.content-page {
  justify-content: space-around;
}
.inputs{
  max-width: 250px;
}
.titles{
  display: flex;
  flex-direction: column;
  align-content: stretch;
  margin-top: 60px;
}

.h1{
  font-family: 'Rabar_031';
  font-size: 28px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  color: #FFFFFF;
}
.h2{
  font-family: 'Rabar_031';
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: #FFFFFF;
}

h3{
  font-family: 'Poppins';
  font-size: 9px;
  line-height: 14px;
  letter-spacing: 0em;
  font-weight: 400;
  color: #FFFFFF;
}
h4{
  font-family: 'Poppins';
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0em;
  font-weight: 400;
  color: #FFFFFF;
}
.div-errors{
  display: flex;
  height: 15px;
  align-items: center;
  justify-content: center;
}
.label-errors{
  font-size: 10px;
  font-family: 'Rabar_031';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: red;
}
.label-login{
  color:#AAFF03
}
.button-valid {
  background-color: #aaff03 !important;
  color: #26314A !important;
}
.button{
  width: 100%; 
  border-radius: 6px !important;
  font-family: 'Rabar_031';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #fff;
  margin-top: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}
.el-button--primary {
  background-color: #445C87;
  border: 0px;
  border-radius: 20px;
  line-height: 15px !important;
}

@media (max-width: 600px) {
  .login-icons-page {
    font-size: 14px;
    margin-top: 30px;
  }

  .login-icons-page > div{
    width: 100px;
  }
  .login-icons-page > div img{
    height: 20px;
    margin-left: 5px;
  }
}
</style>
