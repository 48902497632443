import i18n from "./translation";
import axios from "axios";
import mock from "./mocks/mock-tests";

import {
  store,
  notify
} from "./store";
import router from "./router";

export const Service = {
  $http: axios.create({
    headers: {
      "Content-Type": "application/json"
    }
  }),
  nextStepToRoute,
  loginWithKey,
  loginRequestPinCode,
  loginVerifyPinCode,
  createAccount,
  createAccountUserData,
  securityQuestionFindRandom,
  saveSecurityQuestionUserAnswers,
  userIsKorek,
  securityQuestionFindByUser,
  forgotPasswordSecurityQuestions,
  changePassword,
  loadCountries,
  subscriptionsTypes,
  requestOTPSubscription,
  verifyPinCodeSubscritpion,
  getNextQuestion,
  anwserQuestion,
  getMatchDay,
  sendMatchDayBet,
  getMatchResults,
  purchaseCoinsRequestPinCode,
  purchaseCoinsVerifyPinCode,
  changeAccoutInfo,
  getAccountInfo,
  getBetsStatus,
  getLeagueStanding,
  sendFavourites,
  getNews,
  getUserInfo,
  getSeasonsInfo,
  getPrizesList,
  getRankingList,
  getImages,
  getFavouritesPopularTeams,
  getUserFavourites,
  getWinners,
  getLeagues,
  getTeamsByContinent,
  getTeamsByLeague,
  rankingHeadToHead,
  getUserRankingHeadToHead,
  getUserIpInfo,
  getActivePlans,
  getLastMatches,
  deleteFavourites
};

Service.$http.interceptors.request.use((config) => {
  store.layout.showLoading = true;
  config.headers.Authorization =
    "Bearer " + (store.userToken ? store.userToken : "");
  return config;
});

Service.$http.interceptors.response.use(
  (response) => {
    let userBalance = response && response.headers && response.headers["user-balance"];
    let userPoints = response && response.headers && response.headers["user-points"];
    if (typeof userBalance != "undefined" && userBalance)
      store.userBalance = isNaN ? parseInt(userBalance) : "Error";
    if (typeof userPoints != "undefined" && userPoints)
      store.userPoints = isNaN ? parseInt(userPoints) : "Error";
    store.layout.showLoading = false;
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response && error.response.status == 401) {
      if(!store.ignoreDefaultError){
        notify(i18n.t("error.error"), i18n.t("error.auth_error"), "error");
      }
      setTimeout(() => {
        store.layout.showLoading = false;
        if (router.currentRoute.name && router.currentRoute.name != "login")
          router.push("/");
        localStorage.removeItem("userToken");
        store.userToken = null;
      }, 3000);
    } else if (error.response.status == 400) {
      if(!store.ignoreDefaultError){
        notify(
          i18n.t("error.error"),
          error.response.data.message || error.response.data.msg,
          "error",
          true
        );
      }
      setTimeout(() => {
        store.layout.showLoading = false;
      }, 3000);
    } else if (error.response.status !== 409) {
      if(!store.ignoreDefaultError){
        notify(
          i18n.t("error.error"),
          i18n.t("error.connexion_error"),
          "error",
          true
        );
      }
      setTimeout(() => {
        store.layout.showLoading = false;
      }, 3000);
    }
    store.ignoreDefaultError = false
    return Promise.reject(error);
  }
);

//process.env.NODE_ENV == "development" ? mock(Service.$http) : "";

function nextStepToRoute(value) {
  var dict = {
    "/user/createAccount/userData": "signup-personal-data",
    "/user-security-question": "signup-security-questions"
  };
  return dict[value];
}

async function loginWithKey(loginKey, password) {
  try {
    const response =
      await Service.$http.post(store.API_URL + "/user/login", {
        "login": loginKey,
        "password": password
      });
    return response;
  } catch (e) {
    return e.response;
  }
}

async function loginRequestPinCode(msisdn) {
  try {
    const response = await Service.$http.get(`${store.API_URL}/user/requestPinCode/964${msisdn}/${store.serviceLang}`);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function loginVerifyPinCode(pincode, ts) {
  try {
    const response = await Service.$http.get(`${store.API_URL}/user/verifyPinCode/964${store.phoneNumber}/${pincode}/${store.serviceLang}`);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function createAccount(login, password, passwordConfirm) {
  try {
    const response =
      await Service.$http.post(store.API_URL + "/user/createAccount", {
        "login": login,
        "password": password,
        "passwordConfirm": passwordConfirm
      });
    if (response.status == 201) {
      store.nextStep = response.data.nextStep;
      store.userToken = response.data.token;
      store.userName = login;
    }
    return response;
  } catch (e) {
    return e.response;
  }
}

async function createAccountUserData(firstName, lastName, email, phoneNumber, countryId) {
  try {
    let obj = {
      "firstName": firstName,
      "lastName": lastName,
      "countryId": countryId,
    }
    if (email) {
      obj.email = email;
    }
    if (phoneNumber) {
      obj.phoneNumber = phoneNumber;
    }
    const response = await Service.$http.post(store.API_URL + "/user/createAccount/userData", JSON.stringify(obj));
    if (response.status == 200) {
      store.nextStep = response.data.nextStep;
    }

    return response;
  } catch (e) {
    return e.response;
  }
}

async function securityQuestionFindRandom(qtd) {
  try {
    const response = await Service.$http.get(
      `${store.API_URL}/security-question/findRandom?qtd=${qtd}`
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

async function saveSecurityQuestionUserAnswers(securityAnswersArray) {
  try {
    const response =
      await Service.$http.post(store.API_URL + "/user-security-question", JSON.stringify(securityAnswersArray));
    return response;
  } catch (e) {
    return e.response;
  }
}

async function userIsKorek(login) {
  try {
    const response = await Service.$http.get(
      `${store.API_URL}/user/isKorekUser/${login}`
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

async function securityQuestionFindByUser(login, qtd) {
  try {
    const response = await Service.$http.get(`${store.API_URL}/security-question/findByUser?login=${login}&qtd=${qtd}`);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function forgotPasswordSecurityQuestions(login, securityAnswersArray) {
  try {
    let answers = securityAnswersArray
    const response =
      await Service.$http.post(store.API_URL + "/user/forgotPassword", {
        "login": login,
        answers
      });
    return response;
  } catch (e) {
    return e.response;
  }
}

async function changePassword(password, passwordConfirm) {
  try {
    const response =
      await Service.$http.post(store.API_URL + "/user/changePassword", {
        "password": password,
        "passwordConfirm": passwordConfirm
      });
    return response;
  } catch (e) {
    return e.response;
  }
}

async function loadCountries(page, pageSize) {
  try {
    const response = await Service.$http.get(`${store.API_URL}/country?page=${page}&pageSize=${pageSize}`);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function subscriptionsTypes() {
  try {
    const response = await Service.$http.get(`${store.API_URL}/subscription-type/actives/${store.serviceLang}`);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function requestOTPSubscription(phoneNumber, serviceID) {
  try {
    const response = await Service.$http.get(`${store.API_URL}/user/requestPinCode/964${phoneNumber}/${store.serviceLang}/${serviceID}`);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function verifyPinCodeSubscritpion(phoneNumber, pinCode, ti, ts, serviceID) {
  try {
    const response = await Service.$http.get(`${store.API_URL}/user/verifyPinCode/964${phoneNumber}/${pinCode}/${ti}/${ts}/${serviceID}`);
    return response;
  } catch (e) {
    console.error("Error trying to verifyPinCode from API", e.message, e);
    return e.response;
  }
}

async function getNextQuestion() {
  try {
    const response = await Service.$http.get(
      store.API_URL + "/user-answer/nextQuestion/"
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

async function anwserQuestion(userAnswerID, selectedAnswer, betPoints) {
  try {
    const response = await Service.$http.patch(
      store.API_URL + "/user-answer/answerQuestion/" + userAnswerID, {
        answer: selectedAnswer,
        points: betPoints
      }
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getMatchDay() {
  try {
    const response = await Service.$http.get(
      store.API_URL + "/match-day/matchesByDay"
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

async function sendMatchDayBet(bets, isEdit) {
  try {
    const response = isEdit ?
      await Service.$http.post(store.API_URL + "/bet/editBets", bets) :
      await Service.$http.post(store.API_URL + "/bet/addBets", bets);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getMatchResults() {
  try {
    const response = await Service.$http.get(
      store.API_URL + "/match-day/matchesResult"
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getBetsStatus(type) {
  try {
    const response = await Service.$http.get(
      `${store.API_URL}/bet/betCardsByUser/${type}`
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

async function purchaseCoinsRequestPinCode(coins, type) {
  try {
    const response = await Service.$http.get(
      `${store.API_URL}/user/purchaseCoins/requestPinCode/${store.serviceLang}/${coins}/${type}`
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

async function purchaseCoinsVerifyPinCode(coins, pincode, type) {
  try {
    const response = await Service.$http.get(
      `${store.API_URL}/user/purchaseCoins/verifyPinCode/${coins}/${pincode}/${type}`
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

async function changeAccoutInfo(firstName, lastName, phoneAccount, email, countryId) {
  try {
    let obj = {
      "firstName": firstName,
      "lastName": lastName,
      "countryId": countryId,
    }
    if (email) {
      obj.email = email;
    }
    if (phoneAccount) {
      obj.phoneAccount = phoneAccount;
    }
    const response = await Service.$http.patch(`${store.API_URL}/user`, JSON.stringify(obj));
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getAccountInfo() {
  try {
    const response = await Service.$http.get(`${store.API_URL}/user/me`);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getLeagueStanding(leagueID, year) {
  try {
    const response = await Service.$http.get(
      `${store.API_URL}/standings/getSeasonStandings/${leagueID}/${year}`
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

async function sendFavourites(selectedFavorites) {
  try {
    const json = JSON.stringify(selectedFavorites);
    const response = await Service.$http.post(`${store.API_URL}/favourite-team`, json);
    return response;
  } catch (e) {
    return e.response;
  }
}
async function deleteFavourites(teamId) {
  try {
    const response = await Service.$http.delete(`${store.API_URL}/favourite-team/${teamId}`);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getNews(id) {
  try {
    const response = await Service.$http.get(
      `${store.API_URL}/news/details/${id}`
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getUserInfo() {
  try {
    const response = await Service.$http.get(
      `${store.API_URL}/user/me`
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getSeasonsInfo() {
  try {
    const response = await Service.$http.get(
      `${store.API_URL}/standings/getSeasonsInfo`
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getPrizesList(type, lang) {
  try {
    const response = await Service.$http.get(
      `${store.API_URL}/prize/active?type=${type}&lang=${lang}`
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getRankingList(page, pageSize, type) {
  try {
    const response = await Service.$http.get(`${store.API_URL}/user-ranking/get-current-ranking?type=${type}&page=${page}&pageSize=${pageSize}`);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getImages(type, lang) {
  try {
    const response = await Service.$http.get(`${store.API_URL}/images/${type}/${lang}`);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getFavouritesPopularTeams() {
  try {
    const response = await Service.$http.get(`${store.API_URL}/team/popularTeams`);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getUserFavourites() {
  try {
    const response = await Service.$http.get(`${store.API_URL}/favourite-team`);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getLeagues() {
  try {
    const response = await Service.$http.get(`${store.API_URL}/league`);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getTeamsByContinent(continent) {
  try {
    const response = await Service.$http.get(`${store.API_URL}/team/nationalTeamsByContinent/${continent}`);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getTeamsByLeague(leagueId) {
  try {
    const response = await Service.$http.get(`${store.API_URL}/team/teamsByLeague/${leagueId}`);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getWinners(type, year) {
  try {
    const response = await Service.$http.get(`${store.API_URL}/winner/byTypeYear/${type}/${year}`);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function rankingHeadToHead(type) {
  try {
    const response = await Service.$http.get(`${store.API_URL}/user-ranking/head-to-head-ranking?type=${type}`);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getUserRankingHeadToHead(){
  try {
    const response = await Service.$http.get(`${store.API_URL}/user-ranking/head-to-head-current-user-ranking`);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getUserIpInfo() {
  try {
    const response = await Service.$http.get(`${store.API_URL}/ipInfo`);
    return response;
  } catch (e) {
    return e.response;
  }
}

async function getActivePlans(){
  try {
    const response = await Service.$http.get(`${store.API_URL}/head-to-head-plan/actives`);
    return response;
  } catch (e) {
    return e.response;
  }
}
async function getLastMatches(page,pageSize){
  try {
    const response = await Service.$http.get(`${store.API_URL}/match/lastMatches?page=${page}&pageSize=${pageSize}`);
    return response;
  } catch (e) {
    return e.response;
  }
}