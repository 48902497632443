<script >

import { store, notify } from "../store"
import { Service } from "../services"

export default {
    data() {
        return {

        }
    },
    computed: {
    userPoints() {
      return store.userPoints
    },
    userBalance() {
      return store.userBalance
    },
    isSubscribed(){
      return store.subscribed
    }
  },

  methods:{
    openGetCoins() {
      store.layout.showGetCoins = true
    },
    async getUserInfo(){
      let response = await Service.getUserInfo();
      if (response && response.status === 200) {
        store.userBalance = response.data.balance
        store.userPoints = response.data.currentPoints
        store.subscribed = response.data.subscribed
        this.ranking = response.data.currentRanking
        this.fullName = response.data.firstName + " " + response.data.lastName
      }
      else if (response && response.status === 409) {
        notify(this.$t("error.error"),  this.$t("error.waiting_subscription"), "warning", false)
        store.layout.showLogoutOverlay = true
        setTimeout(async () => {
          await this.getUserInfo()
        }, 5000)
      }
    }
  },
  async mounted() {
    await this.getUserInfo();
  }
}


</script>

<template>


    <div class="col-12 mb-3 d-flex jsutify-content-between align-items-center">

        <div class="col-7" :class="this.$i18n.locale == 'en' ? 'user-info' : 'user-info-rtl'">
                <div class="d-flex justify-content-around align-items-center">

                    <div><span class="point-title">{{ $t("home.points") }}</span> <span class="point">{{ isSubscribed ? userPoints : '-' }}</span></div>
                    <div><span class="coin-title">{{ $t("home.coins") }}</span> <span class="coin">{{ userBalance }}</span></div>

                </div>
        </div>

        <!-- <div class="col-5 ">
            <img @click="openGetCoins()" class="add_coin_image" src="../assets/add_coin.svg" alt="add_coin">
        </div> -->

    </div>

</template>


<style scoped>
    .user-info{
        background: #544C5A !important;
        padding: 5px !important;
        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 20px !important;

    }
    .user-info-rtl{
        background: #544C5A !important;
        padding: 5px !important;
        border-top-left-radius: 20px !important;
        border-bottom-left-radius: 20px !important;

    }

    .coin-title, .point-title{
        color: #C1DEFF;
        font-size: 16px;
        font-weight: 400;

    }

    .point, .coin{
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 400;
    }

    .add_coin_image{
        float: right !important;
        margin-right: 25px !important;
    }

</style>
