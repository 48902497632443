<template>
  <el-dialog :visible="true" width="90%" center :append-to-body="true">
    <div align="center">
      <div class="dialog-message">
        {{ $t("needSubscribe.text") }}
      </div>
      <el-row class="button-need-coins">
        <el-button class="button-close-buy" @click="goToRoute('choose-subscription')">
          {{ $t("needSubscribe.subscribe") }}
        </el-button>
        <!-- <el-button class="button-close-buy" @click="goToRoute('watchads')">
          {{ $t("needSubscribe.watchAds") }}
        </el-button> -->
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ModalNeedSubscribeComponent",
  methods: {
    goToRoute(route) {
      if(route == 'watchads')
        console.log('Watch Ads')
      else
        this.$router.push("/" + route);
    },
  },
};
</script>

<style scoped>
:deep(.el-dialog) {
  padding: 15px 20px;
  background-color: #2A3757;
  border-radius: 20px !important;
  box-shadow: -3px 3px 6px 0px black;
}

:deep(.el-dialog__header) {
  display: none;
}

:deep(.el-dialog__body) {
  padding: 20px 15px !important;
}

:deep(.dialog-message) {
  color: #ffffff;
  word-break: break-word !important;
  font-family: 'Rabar_031';
  font-size: 26px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
}

.button-need-coins {
  justify-content: space-around;
  margin-top: 30px;
}

.button-close-buy {
  background-color: #aaff01;
  padding: 6px 25px;
  border-radius: 6px;
  border: none !important;
  font-family: 'Rabar_031';
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #2A3757;
}
</style>
