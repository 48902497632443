<template>
    <el-dialog
        :append-to-body="true"
        :visible="true"
        width="90%"
        @close="closeDialog"
        center>
        <div align="center">
          <img class="coin-icon" :src="require('@/assets/coins1.svg')">
          <div class="dialog-message">

            {{ $t("playMatch.add_coins") }}
            <div class="sub-message">{{ $t("playMatch.points_per_coin") }}</div>
          </div>
          <div align="center">
            <el-input-number
              @change="setPoints"
              v-model="modalPoints"
              :min="minPoints"
              :max="1000"
              :step="100">
            </el-input-number>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="closeDialog">
            {{ $t("playMatch.submit")}}
          </el-button>
        </span>
    </el-dialog>
</template>

<script>

export default {
  name: "ModalPointsComponent",
  data() {
    return {
        modalPoints: 100,
    }
  },
  props: ["minPoints", "points"],
  methods: {
    closeDialog(){
        this.$emit("closeDialog", true);
    },
    setPoints(currentValue){
        this.$emit("setPoints", this.modalPoints, currentValue);
    }
  },
  computed: {},
  async mounted() {
    this.modalPoints = this.points
  },
};
</script>

<style scoped>

:deep(.el-dialog) {
  top: calc(100vh/5);
  padding: 15px 20px;
  margin-top: 0!important;
  background-color: #182252;
  border-radius: 20px !important;
  box-shadow: -3px 3px 6px 0px black;
}

:deep(.dialog-message) {
  color: #ffffff;
  word-break: break-word !important;
  font-family: 'Rabar_031';
  font-size: 26px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
}
.el-dialog--center .el-dialog__body {
  padding: 25px 20px 15px 20px !important;
}

:deep(.el-input-number__decrease),
:deep(.el-input-number__increase) {
  background-color: #0C1538 !important;
  color: rgb(28, 0, 73);
  font-size: 14px;
  border: none !important;
}

:deep(.el-input-number) {
  width: 100%;
  background-color: #0C1538;
}

:deep(.el-input-number) .el-input {
  border: none !important;
  background-color: #0C1538;
}

:deep(i.el-icon-plus) {
  font-weight: bold!important;
  color: #FFFFFF;
  background-color: #0C1538!important;
  text-transform: uppercase;
}

:deep(i.el-icon-minus) {
  font-weight: bold!important;
  color: #FFFFFF;
  background-color: #0C1538!important;
  text-transform: uppercase;
}

:deep(.dialog-footer) button {
  width: 100%;
  display: block;
  background: linear-gradient(180deg, #FFD865 0%, #FFBE00 100%);
  color: #182252;
  border-radius: 6px;
  border: none !important;
  font-family: 'Rabar_031';
  font-size: 18px;
  font-weight: 400;
}
:deep(.el-input-number) .el-input__inner{
  font-family: 'Rabar_031';
  font-size: 30px;
  font-weight: 400;
  background: #0C1538;
  color: #FFC721;
  z-index:initial;
}

.dialog-message .sub-message {
  font-size: 13px;
}

.coin-icon {
  width: 100px;
  height: 100px;
  margin: 10px 0px;
}

</style>
