<template>
  <div class="content-page">

    <el-dialog :visible="dialogMsgPlayWithMsisdn" width="90%" center :show-close="false" :modalAppendToBody="false">
      <div class="play-with-msisdn-content">
        <div class="items">
          <span class="text-item">
            {{ interpolate($t("headToHead.msgPlayWithMsisdn"), [partner.name]) }}
          </span>
          <div class="dialog-play-with-msisdn-content">
            <div class="dialog-play-with-msisdn-button-content">
              <div class="accept-play-button" @click="acceptChalenge()">
                <span class="text"> {{ $t("headToHead.accept") }} </span>
              </div>
              <div class="accept-play-button decline-play-button" @click="closeDialogMsgPlayWithMsisdn()">
                <span class="text"> {{ $t("headToHead.decline") }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible="dialogMsgPlayerNotFound" width="90%" center @close="closeDialogMsgPlayerNotFound"
      :show-close="false" :modalAppendToBody="false">
      <div class="play-with-msisdn-content">
        <div class="items">
          <span class="text-item msg-not-found">
            {{ $t("headToHead.msgPlayerNotFound") }}
          </span>
          <span slot="footer" class="dialog-footer">
            <div class="buttons-container">
              <div class="accept-play-button decline-play-button">
                <span class="text" @click="closeDialogMsgPlayerNotFound"> {{ $t("headToHead.close") }} </span>
              </div>
            </div>
          </span>
        </div>
      </div>
    </el-dialog>

    <!-- get started -->
    <div v-if="status.getStarted" class="mb-3 profile_page ">
      <!-- user name container -->
      <div>
        <div class="user_name_container col-11 mx-auto">
          <div class="row">
            <img src="../assets/head-to-head/profile_placeholder.svg" alt="profile_icon">
          </div>
          <div class="row username">
            <h1>{{ fullName }}</h1>
          </div>
        </div>
      </div>
      <!-- end user name container -->

      <!-- user information -->

      <div class="d-flex justify-content-between col-12 px-4 mx-auto align-items-center mb-2 mt-2">

        <div class="col-4 ">
          <div class="card-info  " :class="this.$i18n.locale == 'en' ? 'text-start' : 'text-end'"
            @click="goToRoute('ranking')">
            <div class="card-body">
              <h5 class="card-title">{{ isSubscribed ? ranking : '-' }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">{{ $t("home.ranking") }}</h6>
            </div>
          </div>
        </div>

        <div class="col-4 ">
          <div class="card-info " :class="this.$i18n.locale == 'en' ? 'text-start' : 'text-end'">
            <div class="card-body">
              <h5 class="card-title">{{ isSubscribed ? userData.h2hPoints : '-' }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">{{ $t("headToHead.overviewTable.points") }}</h6>
            </div>
          </div>
        </div>

        <div class="col-4 ">
          <div class="card-info " :class="this.$i18n.locale == 'en' ? 'text-start' : 'text-end'">
            <div class="card-body">
              <h5 class="card-title">{{ userBalance }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">{{ $t("home.coins") }}</h6>
            </div>
          </div>
        </div>
      </div>
      <!-- end user information -->

      <!-- user other info -->
      <div class="mb-2">
        <!-- <button @click="openGetCoins" class="buying_coin_btn btn col-9" >Buy Coin</button> -->
        <div class="user_other_info_container d-flex col-11 mx-auto">

          <div class="col-6 ">
            <div class="row mb-3 ">
              <h5 class="title">{{ $t("home.ranking") }}</h5>
              <h4 class="value">{{ isSubscribed ? userData.h2hRanking : '-' }}</h4>
            </div>
            <div class="row mb-3">
              <h5 class="title">{{ $t("home.coins") }}</h5>
              <h4 class="value">{{ userBalance }}</h4>
            </div>
            <!-- <div class="row mb-3">
              <h5 class="title">{{ $t("headToHead.overviewTable.points") }}</h5>
              <h4 class="value">{{ isSubscribed ? userData.h2hPoints : '-' }}</h4>
            </div> -->
          </div>

          <!-- phone email country -->
          <div class="col-6 ">
            <div class="row mb-3">
              <h5 class="title-second" style="direction:ltr !important">{{ $t("inputs.phonenumber_short") }}</h5>
              <h4 class="value">{{ phone_number }}</h4>
            </div>
            <div class="row mb-3">
              <h5 class="title-second">{{ $t("inputs.email") }}</h5>
              <h4 class="value">{{ email_address }}</h4>
            </div>
            <!-- <div class="row mb-3">
             <h5 class="title-second">Country</h5>
             <h4 class="value">Baghdad</h4>
         </div> -->
          </div>
          <!-- end phone email country -->
        </div>
      </div>
      <!-- end user other info -->
      <div v-if="socketConnected" class="col-12">
        <button @click="goToChoosePlan()" class="get_started btn col-11">{{ $t("headToHead.getStarted") }}</button>
      </div>

      <div v-else class="col-12 mt-40">

        <img class="rotate" :src="require('../assets/head-to-head/loading.svg')" />

      </div>
      <!-- end playmatch and head to head container -->
    </div>
    <!-- end get started -->

    <!-- choose plan -->
    <div v-if="status.choosePlan" class="head-to-head-content head-to-head-content-choose-plan">
      <div class="choose-plan-title"> {{ $t("headToHead.chooseYourPlan") }} </div>
      <div class="choose-plan-content mt-5">
        <div v-for="plan in plans" :key="plan.id" class="choose-plan-item-content">
          <div class="card card-plan choose-plan-item p-4" style="width: 18rem;">
            <img src="../assets/head-to-head/plan-image.svg" width="180" alt="plan_image">
            <div class="card-body">
              <h5 class="card-title">{{ plan.name_en }}</h5>
            </div>
            <a href="#" class="card-plan-btn mx-auto d-flex ">
              <img src="../assets/head-to-head/coins.png" class="mx-3" width="20" alt="coins">
              <span class="text">{{ interpolate($t("headToHead.plans.playCoins"), [plan.coins]) }}</span>
            </a>
            <a href="#" class="card-plan-btn mx-auto d-flex">
              <img src="../assets/head-to-head/football.png" class="mx-3" width="20" height="20" alt="point">
              {{ interpolate($t("headToHead.plans.getPoints"), [plan.totalPoints]) }}
            </a>
            <a href="#" class="btn plan-play-btn mx-auto" @click="goToSearchPlayer(plan.id, plan.coins)">{{
              $t("headToHead.play") }}</a>

          </div>
        </div>
      </div>
    </div>
    <!-- end choose plan -->

    <!-- search for player -->
    <!-- v-if="status.searchPlayer" -->

    <div v-if="status.searchPlayer" class="head-to-head-content">
      <div class="search-player-content">
        <div class="search-player-around-button mx-auto">
          <img :src="require('@/assets/head-to-head/search.svg')" @click="goToWaitingForConfirmation(msisdn)" />
          <input class="input-search text" type="number" :placeholder="$t('headToHead.searchForPlayer')"
            v-maska="'##########'" v-model="msisdn" @keydown="onKeyDown($event)" />
        </div>
        <!-- <div class="search-player-around-button" @click="goToWaitingForConfirmation('random')">
          <span class="text"> {{ $t("headToHead.selectRandomly") }} </span>
        </div> -->
      </div>


      <img :src="require('@/assets/head-to-head/vs.svg')" class="mx-auto mb-5" />
      <div class="players-header-content">

        <div class="column-direction-column">
          <div>
            <img :src="require('@/assets/head-to-head/player1.svg')" />
          </div>
          <div>
            <div class="player-name"> {{ userName }}</div>
          </div>
        </div>

        <div class="column">

        </div>

        <div class="column-direction-column">
          <div>
            <img :src="require('@/assets/head-to-head/player2.svg')" />
          </div>
          <div>
            <div class="player-name"> {{ $t("headToHead.choosePlayer") }} </div>
          </div>
        </div>

      </div>

      <img :src="require('@/assets/head-to-head/ball.svg')" class="mx-auto " />

      <button class="btn select-randomly-btn " @click="goToWaitingForConfirmation('random')">{{
        $t("headToHead.selectRandomly") }}</button>


    </div>

    <!-- end search player -->



    <div v-if="status.waitingForConfirmation" class="head-to-head-content">

      <div class="d-flex justify-content-between align-items-center waiting-confirm">

        <div class="col-4 mx-auto text-center">
          <div class="card " style="background:transparent;border:none">
            <div class="background_user">

            </div>

            <div class="card-title">{{ userData.firstName }}</div>
          </div>
        </div>
        <div class="col-4 mx-auto text-center">
          <div class="card" style="background:transparent;border:none">
            <img :src="require('@/assets/head-to-head/vs.svg')" height="50" />
          </div>
        </div>
        <div class="col-4 mx-auto text-center">
          <div class="card" style="background:transparent;border:none">
            <div class="background_waiting">
              <img class="rotate" style="position: absolute;left: 38px;top: 47px"
                :src="require('@/assets/head-to-head/synchronize.svg')" />
            </div>
            <div class="card-title">{{ $t("headToHead.waiting") }}</div>
          </div>
        </div>

      </div>


      <div class="container_numberOfPointAndQuestion">

        <div
          class="col-11 mb-3 fixed numberOfQuestion d-flex justify-content-between align-items-center flex-wrap text-center">
          <div class="text">
            {{ $t("headToHead.noOfQuestion") }}
          </div>

          <div class="number">
            {{ 5 }}
          </div>
        </div>
        <div
          class="col-11 fixed mb-3 numberOfQuestion d-flex justify-content-between align-items-center flex-wrap text-center">
          <div class="text">
            {{ $t("headToHead.noOfPoints") }}
          </div>
          <div class="number">
            {{ plans.find(p => p.id == selectedPlan).totalPoints }}
          </div>
        </div>
        <!-- <button class="btn start-btn">{{ $t("headToHead.Start") }}</button> -->
      </div>
    </div>

    <!-- questions -->
    <!-- v-if="status.started" -->
    <div v-if="status.started" class="head-to-head-content">

      <div class="d-flex justify-content-between align-items-center waiting-confirm">

        <div class="col-4 mx-auto text-center">
          <div class="card " style="background:transparent;border:none">
            <div class="background_user">
            </div>
            <div class="card-title">{{ player1.name }}</div>
          </div>
        </div>
        <div class="col-4 mx-auto text-center">
          <div class="card" style="background:transparent;border:none">
            <img :src="require('@/assets/head-to-head/vs.svg')" height="50" />
          </div>
        </div>
        <div class="col-4 mx-auto text-center">
          <div class="card" style="background:transparent;border:none">
            <div class="background_user2">
            </div>
            <div class="card-title">{{ player2.name }}</div>
          </div>
        </div>
      </div>

      <div class="question_square_box d-flex justify-content-center col-11">
        <div v-for="i of 5" :key="i" class="col-2"
          :class="{ 'active_square': currentQuestionIndex >= i, 'square': currentQuestionIndex < i }">
        </div>
      </div>

      <!-- total points earned -->
      <div class="d-flex justify-content-around col-11 mt-3 total_points_earned">
        <div class="points">
          <h4>{{ player1.points }}</h4>
        </div>
        <div class="text">
          <h4>{{ $t("headToHead.totalPointsEarned") }}</h4>
        </div>
        <div>
          <h4 class="points">{{ player2.points }}</h4>
        </div>

      </div>



      <!-- timer count -->

      <div
        v-if="!showPoints && counterTimer > 0 && (currentIntervalType == 'answer' || currentIntervalType == 'showAnswers')"
        class="timer-count d-flex justify-content-center mx-auto text-aligns-center">
        <div>
          <img :src="require('@/assets/head-to-head/time_up.svg')" />
        </div>
        <h1 class="number">{{ counterTimer }}</h1>
      </div>



      <!-- question box -->

      <div class="col-11 question_box mt-2">

        <div class="mx-auto question_number_of">{{ "Q" + currentQuestionIndex }}</div>

        <div class="question_text mx-auto text-center mt-2">
          <h4 class="text-center">
            {{ currentQuestion['description_'+ this.$i18n.locale] }}
          </h4>
        </div>


        <div v-if="showAnswers" class="d-flex  col-11 flex-wrap mx-auto answers mt-5">

          <div class="col-6" style="text-align: -webkit-center" v-for="(anwserURL, i) in currentAnswers" :key="i">
            <div class="card  bg-none border-none mx-auto text-center" :class="{
              'card-answer': true,
              'card-active-answer': !showPoints && selectedAnswer == i + 1 && rightAnswer != i + 1,
              'card-right-answer': rightAnswer == i + 1
            }" @click="sendAnswer(i + 1)">
              <div>
                <img class="answer_image" :src="anwserURL" />
                <img v-show="!showPoints && pairAnswered == i + 1" class="p2"
                  :src="require('@/assets/head-to-head/p2.svg')" />

              </div>


            </div>
          </div>


        </div>


      </div>




      <div v-if="score != null" class="col-11 info_box mt-2">
        <h5 class="text mb-2 text-white">{{ $t("headToHead.pointsEarned") }}</h5>

        <div class="d-flex justify-content-between align-items-center ">

          <div class="col-4 mx-auto text-center">
            <div class="card " style="background:transparent;border:none">
              <div class="card-title name">{{ score.player1.name }}</div>
              <div class="card-title point">{{ lastQuestionPoints.player1 }}</div>
            </div>
          </div>

          <div class="col-4 mx-auto text-center">
            <div class="card" style="background:transparent;border:none">
              <img class="rotate" :src="require('../assets/head-to-head/loading.svg')" height="30" />
            </div>
          </div>

          <div class="col-4 mx-auto text-center">
            <div class="card" style="background:transparent;border:none">

              <div class="card-title name">{{ score.player2.name }}</div>
              <div class="card-title point">{{ lastQuestionPoints.player2 }}</div>
            </div>
          </div>

        </div>


        <button class="btn next-question-btn mx-auto" v-show="counterTimer > 0 && currentIntervalType == 'score'">{{
          interpolate($t("headToHead.nextQuestion"), [counterTimer]) }}</button>

      </div>

    </div>
    <!-- end question and ANSWER -->








    <div v-if="status.finished" class="head-to-head-content">
      <CongratulationCardH2h :playerID="userId" :userScore="userScore" :winner="winner">
      </CongratulationCardH2h>
    </div>




    <div v-if="status.summary">


      <div class="d-flex justify-content-between align-items-center " style="margin-bottom:40px !important">

        <div class="col-4 mx-auto text-center">
          <div class="card " style="background:transparent;border:none">
            <div class="background_user">

            </div>

            <div class="card-title" style="color:#C1DEFF">{{ summaryData.player1.name }}</div>
          </div>
        </div>
        <div class="col-4 mx-auto text-center">
          <div class="card" style="background:transparent;border:none">
            <img :src="require('@/assets/head-to-head/vs.svg')" height="70" />
          </div>
        </div>
        <div class="col-4 mx-auto text-center">
          <div class="card" style="background:transparent;border:none">
            <div class="background_user2">
            </div>
            <div class="card-title" style="color:#C1DEFF">{{ summaryData.player2.name }}</div>
          </div>
        </div>

      </div>


      <div class="head-to-head-content col-11 mx-auto match_fact">





        <div class="match_title col-10">{{ $t("headToHead.matchFacts") }}</div>

        <div class="d-flex col-12 align-items-center player_name justify-content-between">
          <h4>{{ summaryData.player1.name }}</h4>
          <h4>{{ summaryData.player2.name }}</h4>
        </div>

        <div class="d-flex col-12 mx-auto align-items-center player_data_table justify-content-between">
          <h4 class="point">{{ score != null ? (summaryData.player1.points + summaryData.player1.extraPoints) : 0 }}</h4>
          <h4 class="title">{{ $t("headToHead.totalPointsEarned") }}</h4>
          <h4 class="point">{{ score != null ? (summaryData.player2.points + summaryData.player2.extraPoints) : 0 }}</h4>
        </div>


        <div class="divider"></div>

        <div class="d-flex col-12 mx-auto align-items-center player_data_table justify-content-between">
          <h4 class="point">{{ score != null ? summaryData.player1.h2hRanking : 0 }}</h4>
          <h4 class="title">{{ $t("headToHead.ranking") }}</h4>
          <h4 class="point">{{ score != null ? summaryData.player2.h2hRanking : 0 }}</h4>
        </div>


        <div class="divider"></div>

        <div class="d-flex col-12 mx-auto align-items-center player_data_table justify-content-between">
          <h4 class="point">{{ (summaryData.player1.h2hTotalPoints + summaryData.player1.points +
            summaryData.player1.extraPoints) }}</h4>
          <h4 class="title">{{ $t("headToHead.overviewTable.points") }}</h4>
          <h4 class="point">{{ (summaryData.player2.h2hTotalPoints + summaryData.player2.points +
            summaryData.player2.extraPoints) }}</h4>
        </div>


        <div class="divider"></div>



        <button @click="rematch()" class="btn rematch-btn mt-4">{{ $t("headToHead.rematch") }}</button>


        <div class="d-flex col-12 justify-content-between mt-5">
          <button @click="newMatch()" class="btn  newmatch-btn">{{ $t("headToHead.newMatch") }}</button>
          <button @click="goToRoute('home')" class="btn  backtohome-btn">{{ $t("headToHead.backToHome") }}</button>
        </div>

      </div>


    </div>








  </div>
</template>

<script>
import { maska } from "maska";
import { store, notify } from "../store";
import { Service } from "../services";
import SocketioService from "../socket"
import utils from "@/utils";
import CongratulationCardH2h from "../components/CongratulationCardH2h.vue";

export default {
  name: "HeadToHeadComponent",
  mixins: [utils],
  components: { CongratulationCardH2h },
  directives: { maska },
  data() {
    return {
      plans: [],
      selectedPlan: null,
      summaryData: [],
      dialogMsgPlayWithMsisdn: false,
      partner: {
        name: '',
        msisdn: ''
      },
      msisdn: '',
      dialogMsgPlayerNotFound: false,
      socketConnected: false,
      isBotPartner: false,
      showAnswers: false,
      status: {
        getStarted: false,
        choosePlan: false,
        searchPlayer: false,
        waitingForConfirmation: false,
        started: false,
        finished: false,
        summary: false
      },
      userData: [],
      player1: {},
      player2: {},
      winner: {},
      matchInfo: null,
      currentQuestion: { matchId: null, id: null },
      currentAnswer: 0,
      currentQuestionIndex: 0,
      selectedAnswer: -1,
      pairAnswered: -1,
      rightAnswer: -1,
      userId: null,
      counterTimer: 0,
      questionTime: null,
      deltaTime: 0,
      timerInterval: null,
      showPoints: false,
      currentIntervalType: null,
      score: null,
      lastQuestionPoints: null,

      matchConfig: {
        "prize": 500,
        "questionNumber": 5,
        "points": 500,
      },
      ranking: "",
      fullName: "",
      phone_number: "",
      email_address: "",
    }
  },
  methods: {
    closeDialogMsgPlayWithMsisdn() {
      this.msisdn = ''
      this.dialogMsgPlayWithMsisdn = false
    },

    closeDialogMsgPlayerNotFound() {
      this.dialogMsgPlayerNotFound = false
      this.status.searchPlayer = true;
      this.status.waitingForConfirmation = false;
    },

    onKeyDown(e) {
      if (e.keyCode === 13) {
        this.goToWaitingForConfirmation(this.msisdn);
      }
    },
    goBack() {
      if (this.status.choosePlan) {
        this.status.choosePlan = false
        this.status.getStarted = true
      }
      else if (this.status.searchPlayer) {
        this.status.searchPlayer = false
        this.status.choosePlan = true
      }
      else {
        this.goToRoute('home')
      }
    },

    goToRoute(route) {
      this.resetHeadToHead()
      this.$router.push(route)
    },

    resetHeadToHead() {
      this.resetData()
      this.status.getStarted = true
      SocketioService.socket.removeAllListeners()
      SocketioService.socket.disconnect()
    },

    newMatch() {
      this.resetData()
      this.status.searchPlayer = true;
    },

    buyCoins() {
      store.layout.showGetCoins = true
    },

    resetData() {
      clearInterval(this.timerInterval)
      this.isBotPartner = false
      this.status = {
        getStarted: false,
        choosePlan: false,
        searchPlayer: false,
        waitingForConfirmation: false,
        started: false,
        finished: false,
        summary: false
      },
        this.player1 = {}
      this.player2 = {}
      this.winner = {}
      this.currentQuestion = { matchId: null, id: null }
      this.currentAnswer = 0
      this.showAnswers = false
      this.currentQuestionIndex = 0
      this.selectedAnswer = -1
      this.pairAnswered = -1
      this.rightAnswer = -1
      this.userId = null
      this.counterTimer = 0
      this.questionTime = null
      this.deltaTime = 0
      this.showPoints = false
      this.currentIntervalType = null
      this.score = null
      this.lastQuestionPoints = null
    },

    setupSocketListeners() {
      SocketioService.addListener("ready-for-match", (response) => {
        this.resetData()
        this.status.waitingForConfirmation = true
        this.currentQuestion.matchId = response.matchId
        this.userId = response.loggedUser
        this.player1 = response.player1
        this.player2 = response.player2
        this.isBotPartner = this.player1.id == 'cpu' || this.player2.id == 'cpu'
        if (!this.isBotPartner) {
          this.partner.msisdn = this.player1.id == this.userId ? this.player2.msisdn : this.player1.msisdn
        }
      })

      SocketioService.addListener("match-finished", (response) => {
        this.player1 = response.summary.player1
        this.player2 = response.summary.player2
        this.status.finished = true
        setTimeout(() => {
          this.summaryData = response.summary
          this.status.finished = false
          this.status.summary = true
        }, 1 * 5000)
        this.status.started = false
      })

      SocketioService.addListener("score", (response) => {
        this.score = response.score
        this.lastQuestionPoints = response.lastQuestionPoints
        this.player1.points = response.score.player1.points
        this.player2.points = response.score.player2.points
        this.winner = this.getWinner()
        this.rightAnswer = response.lastQuestionAnswer
        this.showPoints = true
        this.setTimer("score")
      })

      SocketioService.addListener("exception", (response) => {
        //NotFound, BadRequest, Unauthorized and HttpException
        if (response && ['requested-user-not-connected', 'requested-user-not-available', 'user-not-connected'].includes(response.errorCode.trim())) {
          this.dialogMsgPlayerNotFound = true;
        }
        else if (response && response.errorCode in this.$t("headToHead.error")) {
          notify(this.$t('error.error'), this.$t("headToHead.error." + response.errorCode + ".msg"), 'error')
          if (response.errorCode == 'user-has-not-balance') {
            this.resetHeadToHead()
          }
        }
        else {
          notify(this.$t('error.error'), this.$t("error.connexion_error"), 'error')
        }
      })

      SocketioService.addListener("match-request-not-answered", () => {
        this.dialogMsgPlayerNotFound = true;
      })

      SocketioService.addListener("match-request-notification", (response) => {
        this.partner.msisdn = response.from.msisdn
        this.partner.name = response.from.name
        this.dialogMsgPlayWithMsisdn = true;
      })

      SocketioService.addListener("new-question", (response) => {
        this.status.searchPlayer = false
        this.status.waitingForConfirmation = false
        this.status.started = true
        this.showAnswers = false
        this.showPoints = false
        this.currentQuestionIndex++
        this.selectedAnswer = -1
        this.rightAnswer = -1
        this.pairAnswered = -1
        this.score = null
        this.currentQuestion = response
        this.setTimer("showAnswers")
        setTimeout(() => {
          this.showAnswers = true
          this.questionTime = new Date()
          this.setTimer("answer")
        }, 5000)
      })

      SocketioService.addListener("answers-updated", (response) => {
        let pairResponse = response && response.lastAnswers ? (response.lastAnswers.filter(r => r.userId != this.userId && this.currentQuestion.id == r.questionId) || false) : false
        if (pairResponse && pairResponse.length > 0) {
          this.pairAnswered = pairResponse[0].answer
          if (response && response.lastAnswers.length > 1) {
            if (this.currentQuestionIndex < 5)
              this.setTimer("score")
          }
        }
      })

      SocketioService.addListener("connect", () => {
        setTimeout(() => {
          this.socketConnected = true
        }, 1000)
      })

      SocketioService.addListener("user-already-connected", () => {
        notify(this.$t('error.error'), this.$t("headToHead.userConnected"), 'error')
      })
    },

    goToChoosePlan() {
      this.status.getStarted = false;
      this.status.choosePlan = true;
      setTimeout(() => {
        let el = document.querySelector('.choose-plan-content')
        el.scrollLeft = (el.scrollWidth - document.querySelector('body').clientWidth) / 2
      }, 10)
    },

    goToSearchPlayer(planID, planCoins) {
      if (this.userBalance < planCoins) {
        notify(this.$t('error.error'), this.$t("needCoins.text"), 'warning')

      }
      else {
        this.selectedPlan = planID
        this.status.choosePlan = false;
        this.status.searchPlayer = true;
        this.startMatch()
      }
    },

    goToWaitingForConfirmation(type) {
      this.status.searchPlayer = false;
      this.status.waitingForConfirmation = true;
      this.chooseMatchType(type != 'random' ? '964' + type : type)
    },

    startMatch() {
      SocketioService.emit("head-to-head", { h2hPlanId: this.selectedPlan })
    },

    chooseMatchType(type) {
      this.partner.msisdn = type
      SocketioService.emit("match-request", { playWith: type })
    },

    sendAnswer(answer) {
      if (this.selectedAnswer != -1 || !this.checkAnswerTime())
        return
      this.selectedAnswer = answer
      SocketioService.emit("new-answer", {
        "matchId": this.currentQuestion.matchId,
        "questionId": this.currentQuestion.id,
        "answer": answer,
      });
    },

    getWinner() {
      if (this.score.player1.points > this.score.player2.points)
        return this.score.player1
      else if (this.score.player1.points < this.score.player2.points)
        return this.score.player2
      else
        return "DRAW"
    },

    getMatchFactsUser(id) {
      let totalCorrect = 0
      this.summaryData.questions.forEach((q) => {
        q.answers.forEach((a) => {
          if (a.userId == id && a.isCorrect) {
            totalCorrect++
          }
        })
      })
      return totalCorrect
    },

    async rematch() {
      if (this.isBotPartner) {
        SocketioService.emit("rematch-request", {
          "matchId": this.currentQuestion.matchId,
        });
      }
      else {
        SocketioService.emit("match-request", { playWith: this.partner.msisdn })
      }
      this.resetData()
      await this.getUserRankingHeadToHead()
      this.status.waitingForConfirmation = true;
    },

    acceptChalenge() {
      this.dialogMsgPlayWithMsisdn = false;
      SocketioService.emit("match-request-accepted", {
        "msisdn": this.partner.msisdn,
      });
    },

    setTimer(type) {
      this.currentIntervalType = type
      clearInterval(this.timerInterval)
      if (type == "score") {
        this.counterTimer = 5
        this.timerInterval = setInterval(() => {
          if (this.socketConnected && this.counterTimer > 0) {
            this.counterTimer--
          }
          else if (this.socketConnected) {
            this.counterTimer = 0
            clearInterval(this.timerInterval)
          }
          else {
            clearInterval(this.timerInterval)
          }
        }, 1000)
      }
      else if (type == "answer") {
        this.counterTimer = 10
        this.timerInterval = setInterval(() => {
          if (this.socketConnected && this.counterTimer > 0) {
            this.counterTimer--
          }
          else if (this.socketConnected) {
            this.counterTimer = 0
            clearInterval(this.timerInterval)
          }
          else {
            clearInterval(this.timerInterval)
          }
        }, 1000)
      }
      else if (type == "showAnswers") {
        this.counterTimer = 5
        this.timerInterval = setInterval(() => {
          if (this.socketConnected && this.counterTimer > 0) {
            this.counterTimer--
          }
          else if (this.socketConnected) {
            this.counterTimer = 0
            clearInterval(this.timerInterval)
          }
          else {
            clearInterval(this.timerInterval)
          }
        }, 1000)
      }
    },
    checkAnswerTime() {
      let current = new Date()
      return current.getTime() < (this.questionTime.getTime() + (10 * 1000))
    },
    async getUserRankingHeadToHead() {
      let response = await Service.getUserRankingHeadToHead();
      if (response && response.status === 200) {
        this.userData = response.data
      }
    },
    async getActivePlans() {
      let response = await Service.getActivePlans();
      if (response && response.status === 200) {
        this.plans = response.data
      }
    },

    openGetCoins() {
      store.layout.showGetCoins = true
    },
    async getUserInfo() {
      let response = await Service.getUserInfo();
      if (response && response.status === 200) {
        console.log(response.data)
        store.userBalance = response.data.balance
        store.userPoints = response.data.currentPoints
        store.subscribed = response.data.subscribed
        this.ranking = response.data.currentRanking
        this.phone_number = response.data.phoneNumber
        this.email_address = response.data.email
        this.fullName = response.data.firstName + " " + response.data.lastName
      }
      else if (response && response.status === 409) {
        notify(this.$t("error.error"), this.$t("error.waiting_subscription"), "warning", false)
        store.layout.showLogoutOverlay = true
        setTimeout(async () => {
          await this.getUserInfo()
        }, 5000)
      }
    }
  },
  computed: {
    currentAnswers() {
      return [
        this.currentQuestion.answer1,
        this.currentQuestion.answer2,
        this.currentQuestion.answer3,
        this.currentQuestion.answer4,
      ]
    },
    userName() {
      return [this.userData.firstName, this.userData.lastName].join(" ")
    },
    userBalance() {
      return store.userBalance
    },
    userScore() {
      return this.userId == this.player1.id ? this.player1 : this.player2
    },
    userPoints() {
      return store.userPoints
    },
    isSubscribed() {
      return store.subscribed
    }
  },

  beforeUnmount() {
    SocketioService.socket.disconnect()
  },

  async mounted() {
    await this.getUserInfo();
    store.layout.showHeader = true
    store.layout.showLanguageSwitch = true

    store.layout.footerType = ""
    store.layout.footerItem = ""
    store.layout.showUserWallet = false

    this.resetData()
    this.getActivePlans()
    await this.getUserRankingHeadToHead()
    this.status.getStarted = true
    if (!this.socketConnected) {
      SocketioService.socket.connect()
      this.setupSocketListeners()
    }
  }
};
</script>

<style scoped>
.buttons-container {
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}

.get-coins-content {
  display: flex;
}

.items {
  margin-top: 0px;
  padding: 20px;
  background-color: #2A3757;
  border-radius: 30px;
}

.text-item {
  font-family: 'Rabar_031';
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}

.msg-not-found {
  padding-left: 0px !important;
}

:deep(.el-dialog__wrapper) {
  top: calc(100vh / 12) !important;
}

:deep(.el-dialog) {
  background-color: #2A3757;
  border-radius: 20px !important;
  box-shadow: -3px 3px 6px 0px black;
}

:deep(.el-input-number) {
  width: 100%;
}

:deep(.el-select) input {
  font-size: 16px;
}

:deep(.dialog-footer) button {
  background-color: #2A3757;
}

:deep(.dialog-footer) button:hover {
  background-color: #2A3757;
  color: #fff;
}

:deep(.dialog-footer) {
  padding: 0 !important;
}

:deep(.el-dialog__header) {
  padding: 0;
}

:deep(.el-dialog__header) i {
  color: #ffffff;
  font-size: 20px;
}

:deep(.el-dialog--center) .el-dialog__body {
  padding: 0 !important;
  text-align: center !important;
}

:deep(.el-input__inner) {
  font-size: 16px;
}

.el-select {
  width: 100%;
  margin-top: 20px;
}

:deep(.pinCode input) {
  text-align: center !important;
  font-size: 16px;
  background-color: #eee;
  color: #555;
  border: 1px solid #aaa;
  max-width: 40px;
  padding: 0 5px !important;
}

.flex-justify-content-center {
  justify-content: center !important;
}

.flex-align-items-center {
  display: flex;
  align-items: center;
}

.flex-column-align-items-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plans {
  width: calc(100% - 35px);
}



.input-search {
  border-width: 0px;
  background-color: #0C1538;


}



.search-player-content {

  margin-top: 60px;
  margin-bottom: 60px;
  width: 100%
}

.search-player-around-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px !important;
  align-items: center;
  background-color: #0C1538;
  height: 30px;
  width: 80%;
  border-radius: 7px;
  margin-bottom: 10px;
  border: 1px solid #2D3B4B;
}

.search-player-around-button .text {
  font-size: 14px !important;
  color: #FFFFFF !important
}


.select-randomly-btn {
  width: 80% !important;
  background-color: #ffffff !important;
  color: #0C1538 !important;
  margin: 0 auto !important;
  /* margin-top: 60px !important; */
  padding: 10px 20px !important;
  border-radius: 20px !important;
  font-size: 25px !important;
}


/* search player */

.search-bar {
  width: 80% !important;
}

.search-bar input {
  background: #0C1538 !important;
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #2D3B4B !important;
  color: #ffffff !important;
  border-radius: 10px !important;
  padding: 10px !important;

}

.input-search::placeholder {
  font-style: normal;
  font-weight: 400;
  color: #FFFFFF !important;
  font-size: 14px;
  text-align: center;
  margin-right: 20px;
}

.shadow input[type="text"]:focus {
  outline: none;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: 'DIN_Next_LT_Arabic';
  font-style: normal;
  font-weight: 400;
  color: #FFFFFF !important;
  font-size: 14px;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: 'DIN_Next_LT_Arabic';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.background-hexagon {
  background-image: url('../assets/hexagon_h2h.svg');
  height: 89px;
  width: 102px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
}

.background_waiting {
  background-image: url('../assets/head-to-head/newplayer2.svg');
  height: 126px;
  width: 102px;
  align-self: center;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
}

.background_user {
  background-image: url('../assets/head-to-head/newplayer1.svg');
  height: 126px;
  width: 102px;
  align-self: center;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
}

.background_user2 {
  background-image: url('../assets/head-to-head/player2_questions.svg');
  height: 126px;
  width: 102px;
  align-self: center;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  position: relative;
  background-position: center;
}

.waiting-confirm .card-title {
  color: #ffffff !important;
}

.waiting-confirm {
  width: 80% !important;
}

.background_waiting img {
  position: absolute;
  top: 43px;
  right: 33px;
}



.numberOfQuestion {
  background: #0C1538 !important;
  padding: 20px !important;
  border-radius: 15px !important;
}

.numberOfQuestion .text {
  font-size: 18px !important;
  color: #FFFFFF !important
}

.numberOfQuestion .number {
  font-size: 15px !important;
  color: #FFC115 !important
}

.container_numberOfPointAndQuestion {
  position: absolute;
  bottom: 0 !important;
  margin-bottom: 24px;
  width: 100% !important;
  text-align: -webkit-center !important;
}


.container_numberOfPointAndQuestion .start-btn {
  background: #D74EF8 !important;
  text-decoration: none !important;
  color: #ffffff;
  margin-top: 15px !important;
  padding: 10px 60px !important;
  border-radius: 20px !important;
}




/* question  */

.question_square_box {
  background: #131C47 !important;
  padding: 15px 10px !important;
  border-radius: 15px !important;
}

.question_square_box .active_square {
  height: 5px !important;
  background: #D74EF8 !important;
  margin: 0px 3px !important;
  border-radius: 15px !important;
}

.question_square_box .square {
  height: 5px !important;
  background: #595165 !important;
  margin: 0px 3px !important;
  border-radius: 15px !important;
}



.total_points_earned .text {
  color: #D74EF8 !important
}

.total_points_earned .points {
  color: #ffffff !important
}


.timer-count .number {
  font-size: 50px !important;
  color: white !important;
  margin: 0px 8px !important;
}


.background-hexagon-small {
  background-image: url('../assets/hexagon_h2h.svg');
  background-size: 70px 70px;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
}

.background-hexagon-small img {
  width: 30px;
  height: 30px;
}

@media (max-width: 600px) {
  .content-page {
    background-image: url("../assets/background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100% !important;
  }
}

@media (min-width: 600px) {
  .content-page {
    background-image: url("../assets/background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100% !important;
  }
}

.content-page {
  font-style: normal;
  font-weight: 400;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.w-100 {
  width: 100%;
}

.w-102 {
  width: 102px
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-40 {
  margin-top: 40px;
}

.mw-70 {
  max-width: 70px;
}

.around-overview {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #AAFF01;
  height: 160px;
  width: 80%;
}

.overview {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #445C87;
  height: 100%;
  width: 40%;
  max-width: 120px;
  border-radius: 10px
}

.head-to-head-content .question-mark {
  color: #ffffff;
  font-size: 50px;
  font-weight: bold;
  padding-bottom: 10px;
}

.overview .text {
  font-size: 12px;
  color: #FFFFFF;
}

.overview-data {
  align-items: start;
  margin-left: 30px;
  height: 100%;
  width: 60%;
  font-size: 11px;
  color: #FFFFFF;
}

.initial-buttons {
  display: flex;
  justify-content: space-around;
  align-self: center;
  margin-top: 10px;
  width: 80%;
}

.around-button {
  display: flex;
  justify-content: space-between;
  background-color: #445C87;
  height: 28px;
  width: 40%;
  max-width: 120px;
  border-radius: 10px;
}

.around-button .text {
  font-size: 12px;
  color: #FFFFFF;
}

.around-name {
  background: #2A3757;
  border-radius: 30px;
  height: 25px;
  width: 80%;
  border-style: solid;
  border-width: 5px;
  border-color: #AAFF01;
}

.around-name .text {
  font-size: 20px;
  color: #FFFFFF
}

.around-get-started-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #AAFF01;
  height: 30px;
  width: 80%;
  border-radius: 7px;
}

.around-get-started-button .text {
  margin-left: 10px;
  font-size: 14px;
  color: #2A3757;
}

.choose-plan-content {
  display: flex;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 30px;
  width: 100%;
  padding: 5px 0px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.choose-plan-content::-webkit-scrollbar {
  display: none;
}


.card-plan {
  background: #51105F !important;
  border-radius: 10px !important;
  color: #FFCBAA !important;
}

.card-plan .card-plan-btn {
  background: #74366E !important;
  text-decoration: none !important;
  color: #FFCBAA;
  width: 100% !important;
  margin-bottom: 10px !important;
  padding: 10px !important;
  border-radius: 8px !important;
}

.card-plan .plan-play-btn {
  background: #D74EF8 !important;
  text-decoration: none !important;
  color: #ffffff;
  margin-top: 15px !important;
  padding: 10px 60px !important;
  border-radius: 20px !important;
}



.card-plan .card-title {
  font-size: 23px !important;
  color: #ffffff !important;
  /* margin-bottom: 10px !important; */
}

.choose-plan-item {
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  position: relative;
  background-size: auto;
  align-items: center;
}

.choose-plan-item-content {
  width: 100%;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
}

/* .choose-plan-item-content:nth-child(1) {
    margin-left: 50px;
  }

  .choose-plan-item-content:nth-child(3) {
    margin-right: 50px;
  } */

.choose-plan-title {
  font-size: 25px;
  color: #FFFFFF;
}

.choose-plan-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  position: absolute;
  background-color: #2A3757;
  width: 230px;
  height: 180px;
  border-bottom-left-radius: 45%;
  border-bottom-right-radius: 45%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.choose-plan-item .box {
  background-color: #FFFFFF;
  width: 230px;
  height: 400px;
  border-radius: 30px;
  box-shadow: rgba(255, 255, 255, 0.3) 5px 10px 5px;
}

.choose-plan-item .box .info-box {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 200px;
  width: 100%;
  padding-top: 180px;
}

.choose-plan-item .box .info-box .play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: #AAFF01;
  width: 80%;
  height: 25px;
  border-radius: 30px;
}

.play-button .text {
  font-size: 12px;
}

.choose-plan-item .box .info-box .around-button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  background-color: #FFFFFF;
  min-width: 150px;
  height: 50px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 5px 5px 5px;
  padding: 5px;
}

.choose-plan-item .box .info-box .around-button .around-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2A3757;
  height: 35px;
  width: 35px;
  border-radius: 50%;
}

.content-page>ul:nth-child(1) {
  margin-top: 15px;
}

.fixed-header {
  width: 100%;
  z-index: 100;
  position: sticky;
  background-image: url(@/assets/background_h2h.png);
  height: 60px;
  top: 0;

}

.arrow-title {
  display: flex;
  justify-items: center;
  align-items: center;
  background: none;
  position: absolute;
  z-index: inherit;
}

.arrow {
  padding-left: 15px;
  padding-top: 20px;
  width: 45px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  position: relative;
}

.head-to-head-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 3;
}

.head-to-head-content-get-started {
  justify-content: start !important;
  margin-top: 40px !important;
}

.head-to-head-content-choose-plan {
  justify-content: start;
  margin-top: 40px;
}

.box-with-opacity {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  align-self: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  min-height: 140px;
  width: 100%;
  height: 100%;
}

.line-with-opacity {
  background: linear-gradient(90deg, #000000 0%, #FFFFFF 50%, #999999 70.5%, #000000 100%);
  width: 90%;
  height: 1px;
}

.box-content {
  position: relative;
  width: 100%;
}

.box-content .line {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: 15px;
}

.box-content .text {
  font-size: 12px;
  color: #FFFFFF;
}

.question-title {
  color: #FFFFFF;
  font-size: 18px;
  margin-top: 35px;
}

.trapezoid-center-content .text {
  font-size: 10px;
  color: #2F1E80
}

.players-header-content {
  display: flex;
  justify-content: space-around;
  width: 90%;
}

.players-header-content .column {
  display: flex;
  width: 1px;
  height: 150px;
  background: #FFCBAA !important;
}

.players-header-content .column-direction-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.players-header-content .player-name {
  font-size: 20px;
  color: #FFFFFF;
  max-width: 120px;

}

.players-header-content .icon {
  position: absolute;
  margin-top: 20px;
  margin-left: -75px;
}

.answer-content {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin-top: 15px;
  margin-top: 40px;
}

.answer-player {
  position: absolute;
  background-color: #AAFF01;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  top: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.answer-player-and-selected {
  top: -13px !important;
}

.answer-player .text {
  font-size: 11px;
  color: #2A3757;
}

.timer-next-question {
  width: 100%;
  display: flex;
  color: #FFFFFF;
  justify-content: flex-end;
}

.loading-answer-question {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.timer-to-anwser {
  position: absolute;
  color: #ffffff;
  font-size: 30px;
}


.question_box {
  background: #0C1538 !important;
  padding: 20px !important;
  border-radius: 15px !important;
  border: 1px dotted #D74EF8 !important;
}


.question_box .question_number_of {
  color: #D74EF8 !important;
}

.question_box .question_text {
  color: #ffffff !important;
}


.question_box .answers .card-answer {
  background: #51607D !important;
  border-radius: 15px !important;
  padding: 10px 0px 10px 0px !important;
  margin: 8px 8px !important;
  color: white !important;
  width: 78% !important;

}

.question_box .answers .p2 {
  position: absolute;
  top: -14px;

}

.question_box .answers .answer_image {
  border-radius: 15px !important;
  width: 60px;
  height: 60px;

}

.question_box .answers .card-active-answer {
  background: #ffffff !important;
  border-radius: 15px !important;
  padding: 10px 0px 10px 0px !important;
  margin: 8px 8px !important;
  color: #0C1538 !important;
  width: 78% !important;

}

.question_box .answers .card-right-answer {
  background: #D74EF8 !important;
  border-radius: 15px !important;
  padding: 10px 0px 10px 0px !important;
  margin: 8px 8px !important;
  color: #ffffff !important;
  width: 78% !important;
}



.info_box {
  background: #182252 !important;
  padding: 5px 10px !important;
  border-radius: 15px !important;
}


.info_box .name {
  font-size: 20px !important;
  color: #C1DEFF !important;
}

.info_box .point {
  font-size: 18px !important;
  color: #FFC115 !important;
}

.info_box .next-question-btn {
  background: #D74EF8 !important;
  text-decoration: none !important;
  color: #ffffff;
  padding: 10px 60px !important;
  font-size: 20px !important;
  border-radius: 20px !important;
}



.match_fact {
  background: #0C1538 !important;
  padding: 15px 20px !important;
  border-radius: 15px !important;

}

.match_fact .match_title {
  background: linear-gradient(#FFCBAA, #C3B9B4) !important;
  border-bottom-right-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  padding: 6px 0px !important;
  position: absolute;
  top: 0px !important;
}

.match_fact .divider {
  width: 100% !important;
  color: #FFCBAA !important;
  height: 2px !important;
  border-bottom: 1px dashed #FFCBAA !important;
  margin: 15px !important;
}

.match_fact .player_name {
  margin-top: 50px !important;
  color: #C1DEFF !important;
  margin-bottom: 10px !important;
}

.match_fact .player_data_table .point {
  color: #FFC115 !important;
}

.match_fact .player_data_table .title {
  color: #FFCBAA !important;
}


.match_fact .rematch-btn {
  background: #D74EF8 !important;
  text-decoration: none !important;
  color: #ffffff;
  margin-top: 15px !important;
  padding: 10px 60px !important;
  border-radius: 25px !important;
  font-size: 25px !important;
}

.match_fact .newmatch-btn {
  background: transparent !important;
  border: 1px solid #D74EF8 !important;
  text-decoration: none !important;
  color: #ffffff;
  padding: 10px 40px !important;
  border-radius: 25px !important;
  font-size: 16px !important;
}

.match_fact .backtohome-btn {
  background: transparent !important;
  border: 1px solid #FFCBAA !important;
  text-decoration: none !important;
  color: #ffffff;
  padding: 10px 40px !important;
  border-radius: 25px !important;
  font-size: 16px !important;
}

.summary-content {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 90%;
  padding-top: 20px;
}

.summary-content .text {
  font-size: 18px;
  color: #FFFFFF;
  text-align: center;
}

.around-summary {
  background-color: #2A3757;
  width: 80%;
  height: 25px;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.around-summary .text {
  font-size: 14px;
  color: #FFFFFF;
  margin-left: 10px;
}

.around-summary-points {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #A9FB03;
  width: 40px;
  height: 20px;
  border-radius: 30px;
  margin-right: 10px;
}

.around-summary-points .text {
  margin-left: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  color: #0064D1;
}

.players-results-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.players-results-content img {
  width: 20px;
  height: 35px;
}

.player-name-table {
  white-space: nowrap;
  width: 40px;
  overflow: hidden;
  text-overflow: clip;
}

.player-hexagon-icon-loading {
  position: absolute;
  margin-top: 10px;
  margin-left: -55px;
  width: 40px;
}

.player-hexagon-text {
  font-size: 13px;
  color: #FFFFFF;
}

.around-points-earned {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-width: 175px;
  width: 90%;
  height: 25px;
  background-color: #AAFF01;
  border-radius: 30px;
}

.around-points-earned .around-points-left {
  background: #2A3757;
  width: 30px;
  height: 20px;
  border-radius: 30px;
  width: 15%;
  margin-left: 5px;
}

.around-points-earned .around-points-right {
  background: #2A3757;
  width: 30px;
  height: 20px;
  border-radius: 30px;
  width: 15%;
  margin-right: 5px;
}

.around-points-earned .text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #FFFFFF;
}

.around-points-earned .total-text {
  font-size: 12px;
  color: #2A3757;
  width: 70%;
}

.question-circles {
  position: absolute;
  margin-top: 70px;
  display: flex;
  flex-direction: row;
}

.circle {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border-color: #AAFF01;
  border-width: 1px;
  border-style: solid;
  margin-right: 5px;
}

.circle-filled {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #AAFF01;
  margin-right: 5px;
}

.around-answer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  background: #2A3757;
  border-radius: 20px;
  position: relative;
}

.around-selected-answer {
  width: 64px;
  height: 64px;
  border: #AAFF01 solid 3px;
}

.around-right-answer {
  display: flex;
  align-items: center;
  align-content: center;
  align-self: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  background: #A9FB03;
  border-radius: 25px;
}

.answer-image {
  width: 42px;
  height: 42px;
}

li {
  color: #ffffff !important;
}

.message {
  color: #ffffff;
  font-size: 13px;
  font-family: 'Courier New', Courier, monospace;
}

.info-content {
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  width: 80%;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.trapezoid-top {
  height: 0px;
  border-bottom: 15px solid #AAFF01;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  width: 100px;
}

.trapezoid-center {
  display: flex;
}

.trapezoid-center-content {
  background-color: #ffffff;
  display: flex;
  width: 80px;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
}

.trapezoid-center-left {
  height: 0px;
  border-top: 15px solid #ffffff;
  border-left: 10px solid transparent;
  border-right: 0px solid transparent;
  width: 5px;
}

.trapezoid-center-right {
  height: 0px;
  border-top: 15px solid #ffffff;
  border-left: 0px solid transparent;
  border-right: 10px solid transparent;
  width: 5px;
}

.trapezoid-bottom {
  font-size: 10px;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #445c87;
  width: 100%;
}

.trapezoid-bottom-left {
  height: 0px;
  border-top: 15px solid #445c87;
  border-left: 10px solid transparent;
  border-right: 0px solid transparent;
  width: 15px;
  margin-left: -10px;
}

.trapezoid-bottom-right {
  height: 0px;
  border-top: 15px solid #445c87;
  border-left: 0px solid transparent;
  border-right: 10px solid transparent;
  width: 15px;
  margin-right: -10px;
}

.rotate {
  animation: rotation 2s infinite linear;
}

.button-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.new-match-button-content {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  width: 100%;
}

.dialog-play-with-msisdn-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 20px
}

.dialog-play-with-msisdn-button-content {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  width: 100%;
}

.new-match-button {
  min-width: 100px;
  width: 37%;
  height: 30px;
  border-radius: 5px;
  background-color: #689105;
  margin-right: 2%;
}

.accept-play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  width: 37%;
  height: 30px;
  border-radius: 5px;
  background-color: #D74EF8;
  margin-right: 2%;
  color: #2A3757;

}

.decline-play-button {
  background-color: #D74EF8 !important;
  color: #FFFFFF !important
}


.new-match-button .text {
  font-size: 14px;
  color: #FFFFFF;
}

.rematch-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 215px;
  width: 75%;
  height: 30px;
  border-radius: 5px;
  background-color: #D74EF8;
}

.rematch-button .text {
  font-size: 14px;
  color: #2A3757;
}

.back-home-button {
  min-width: 115px;
  width: 36%;
  height: 30px;
  border-radius: 5px;
  background-color: #D74EF8;
}

.back-home-button .text {
  font-size: 14px;
  color: #FFFFFF;
}


.user_name_container {
  background: #5D2C85 !important;
  padding: 40px !important;
  border-radius: 15px !important;
}

.user_name_container img {
  width: 200px;
  margin: 0 auto !important;
}

.user_name_container .username {
  text-align: center;
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 400;
}



.card-info {
  background: #51105F !important;
  border-radius: 15px !important;
  color: #C1DEFF !important;
  margin: 0px 3px !important;
  cursor: pointer;
}


.card-info .card-body {
  padding: 10px 15px 5px 15px !important;
}


.card-info .card-title {
  color: #C1DEFF !important
}

.card-info .card-subtitle {
  color: #C1DEFF !important
}


/* card match */

.card-match {
  background: #131C47 !important;
  border-radius: 15px !important;
  color: #C1DEFF !important;
  margin: 0px 3px !important;
  cursor: pointer !important;
}


.card-match .card-body {
  /* padding: 30px 15px 20px 15px !important; */
  height: 120px !important;
  padding-bottom: 5px !important;
}


.card-match .card-title {
  color: #ffffff !important;
  font-size: 25px !important;
}

.card-match .card-subtitle {
  color: #f7f7f7 !important
}



.user_other_info_container {
  background: #182252 !important;
  padding: 45px 20px !important;
  border-radius: 10px !important;
}

.user_other_info_container .title {
  color: #C1DEFF;
  font-weight: 400;
}

.user_other_info_container .title-second {
  color: #FFCBAA;
  font-weight: 400;
}

.user_other_info_container .value {
  color: #ffffff;
  font-weight: 400;
}

.buying_coin_btn {
  margin: 0 auto;
  cursor: pointer;
  background: #FFCBAA;
  color: #0C1538;
  border-radius: 20px !important;
  padding: 5px 30px !important;
  font-size: 25px !important;
  margin: 20PX 5PX !important;
}

.get_started {
  margin: 0 auto;
  cursor: pointer;
  background: #D74EF8;
  color: #ffffff;
  border-radius: 20px !important;
  padding: 5px 30px !important;
  font-size: 25px !important;
  margin: 20PX 5PX !important;
}



@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
</style>
