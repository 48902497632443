import Vue from "vue";
import Router from "vue-router";
import HomePage from "./pages/Home.vue";
import LoginPage from "./pages/Login.vue";
import LoginOTPPage from "./pages/LoginOTP.vue";
import SignUpPage from "./pages/SignUp.vue";
import SignUpPersonalDataPage from "./pages/SignUpPersonalData.vue";
import SignUpSecurityQuestionsPage from "./pages/SignUpSecurityQuestions.vue";
import ForgotPasswordPhonePage from "./pages/ForgotPasswordPhone.vue";
import ForgotPasswordOtpPage from "./pages/ForgotPasswordOtp.vue";
import ForgotPasswordCreatePasswordPage from "./pages/ForgotPasswordCreatePassword.vue";
import ForgotPasswordSecurityQuestionsPage from "./pages/ForgotPasswordSecurityQuestions.vue";
import QuizPage from "./pages/Quiz.vue";
import PlayMatchPage from "./pages/PlayMatch.vue";
import CheckoutPage from "./pages/Checkout.vue";
import TermsPage from "./pages/Terms.vue";
import PrivacyPolicyPage from "./pages/PrivacyPolicy.vue";
import StatusPage from "./pages/Status.vue";
import StatusDetailPage from "./pages/StatusDetail.vue";
import ContactPage from "./pages/Contact.vue";
import FaqsPage from "./pages/Faqs.vue";
import AboutPage from "./pages/About.vue";
import ComingSoonPage from "./pages/ComingSoon.vue";
import ManageAccountPage from "./pages/ManageAccount.vue";
import RankingPage from "./pages/Ranking.vue";
import LeagueStandingPage from "./pages/LeagueStanding.vue";
import GetCoinsPage from "./components/GetCoins.vue"
import FavouritesPage from "./pages/Favourites.vue";
import WinnersPage from "./pages/Winners.vue";
import HeadToHeadPage from "./pages/HeadToHead.vue";
import ProfilePage from "./pages/ProfilePage.vue";
import RankingHeadToHeadPage from "./pages/RankingHeadToHead.vue";
import i18n from './translation'


Vue.use(Router);

export default new Router({
  mode: "history",

  routes: [
    {
      path: '/',
      name: 'login',
      component: LoginPage,
      meta: {
        public: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginPage,
      meta: {
        public: true
      }
    },
    {
      path: '/login-otp',
      name: 'login-otp',
      component: LoginOTPPage,
      meta: {
        public: true
      }
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignUpPage,
      meta: {
        public: true
      }
    },
    {
      path: '/signup-personal-data',
      name: 'signup-personal-data',
      component: SignUpPersonalDataPage,
      meta: {
        public: true
      }
    },
    {
      path: '/signup-security-questions',
      name: 'signup-security-questions',
      component: SignUpSecurityQuestionsPage,
      meta: {
        public: true
      }
    },
    {
      path: '/forgot-password-phone',
      name: 'forgot-password-phone',
      component: ForgotPasswordPhonePage,
      meta: {
        public: true
      }
    },
    {
      path: '/forgot-password-otp',
      name: 'forgot-password-otp',
      component: ForgotPasswordOtpPage,
      meta: {
        public: true
      }
    },
    {
      path: '/forgot-password-create-password',
      name: 'forgot-password-create-password',
      component: ForgotPasswordCreatePasswordPage,
      meta: {
        public: true
      }
    },
    {
      path: '/forgot-password-security-questions',
      name: 'forgot-password-security-questions',
      component: ForgotPasswordSecurityQuestionsPage,
      meta: {
        public: true
      }
    },
    {
      path: '/home',
      name: 'home',
      component: HomePage,
      meta: {
        public: false
      }
    },
    {
      path: '/status',
      name: 'status',
      component: StatusPage,
      meta: {
        public: false,
        pageTitle: 'status.title'
      }
    },
    {
      path: '/status-detail',
      name: 'status-detail',
      props: true,
      component: StatusDetailPage,
      meta: {
        public: false,
        pageTitle: 'status.title' ,
        backPage: true
      }
    },
    {
      path: '/get-coins',
      name: 'get-coins',
      component: GetCoinsPage,
      meta: {
        public: false
      }
    },
    {
      path: '/terms-and-conditions',
      name: 'terms-and-conditions',
      component: TermsPage,
      meta: {
        public: true,
        pageTitle: 'more.terms' ,
      }
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: PrivacyPolicyPage,
      meta: {
        public: true,
        pageTitle: 'footer.policy' ,
      },
    },
    {
      path: "/quiz",
      name: "quiz",
      component: QuizPage,
      meta: {
        public: false,
        pageTitle: 'footer.quiz' ,
        backPage: true
      },
    },
    {
      path: "/play-match",
      name: "play-match",
      component: PlayMatchPage,
      meta: {
        public: false,
        pageTitle: 'footer.playMatch',
      },
    },
    {
      path: "/checkout",
      name: "checkout",
      component: CheckoutPage,
      props: true,
      meta: {
        public: false,
        pageTitle: 'playMatch.checkout' ,
      },
    },
    {
      path: "/contact-us",
      name: "contact-us",
      component: ContactPage,
      meta: {
        public: false,
        pageTitle: 'more.contact_us' ,
      },
    },
    {
      path: "/faqs",
      name: "faqs",
      component: FaqsPage,
      meta: {
        public: false,
      },
    },
    {
      path: "/about-us",
      name: "about-us",
      component: AboutPage,
      meta: {
        public: false,
        pageTitle: 'more.about_us' ,
      },
    },
    {
      path: "/coming-soon",
      name: "coming-soon",
      component: ComingSoonPage,
      meta: {
        public: false,
      },
    },
    {
      path: "/manage-account",
      name: "manage-account",
      component: ManageAccountPage,
      meta: {
        public: false,
        pageTitle: 'manage_account.title' ,
      },
    },
    {
      path: "/ranking",
      name: "ranking",
      component: RankingPage,
      meta: {
        public: false,
        pageTitle: 'ranking.title' ,
        backPage: true
      },
    },

    {
      path: "/league-standing",
      name: "league-standing",
      component: LeagueStandingPage,
      props: true,
      meta: {
        public: false,
        pageTitle:'league_list.title'
      },
    },
    {
      path: "/favourites",
      name: "favourites",
      component: FavouritesPage,
      meta: {
        public: true,
        pageTitle: 'playMatch.my_favourites' ,
      },
      props: true
    },
    {
      path: "/winners",
      name: "winners",
      component: WinnersPage,
      meta: {
        public: false,
      },
    },
    {
      path: "/head-to-head",
      name: "head-to-head",
      component: HeadToHeadPage,
      meta: {
        public: false,
        pageTitle: 'home.headToHead' ,
      },
    },
    {
      path: "/ranking-head-to-head",
      name: "ranking-head-to-head",
      component: RankingHeadToHeadPage,
      meta: {
        public: false,
      },
    }   ,
    {
      path: "/profile",
      name: "profilePage",
      component: ProfilePage,
      meta: {
        public: false,
        pageTitle: 'Profile' ,
      },
    }
  ],
});