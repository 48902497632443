<template>
  <div v-if="showFooter" >

    <GrowBottomNavigation :options="options" v-model="selected" :style="this.$i18n.locale == 'en' ? 'direction:ltr !important' : 'direction:rtl !important' " />

    <!-- <div @click="setItemActive('home')" :class="checkActive('home')">
      <img :src="require('@/assets/footer/home.svg')" />
      <span>{{ $t("footer.home") }}</span>
    </div>
    <div @click="setItemActive('quiz')" :class="checkActive('quiz')">
      <img :src="require('@/assets/footer/quiz.svg')" />
      <span>{{ $t("footer.quiz") }}</span>
    </div>
    <div
      @click="setItemActive('play-match')"
      :class="checkActive('play-match')"
    >
      <img :src="require('@/assets/footer/play-match.svg')" />
      <span>{{ $t("footer.playMatch") }}</span>
    </div>
    <div @click="setItemActive('status')" :class="checkActive('status')">
      <img :src="require('@/assets/footer/status.svg')" />
      <span>{{ $t("footer.status") }}</span>
    </div>
    -->

  </div>
</template>

<script>
import { store } from "../store";
import i18n from "@/translation";
import { GrowBottomNavigation } from "bottom-navigation-vue";


export default {

  name: "FooterHome",
  components: {
    GrowBottomNavigation,
  },
  data() {
    return {
      selected: 1,
    options: [
      { id: 1, icon: 'fa-solid fa-house-window', title: this.$t("footer.home")  },
      { id: 2, icon: 'fa-solid fa-circle-question', title: this.$t("footer.quiz") },
      { id: 3, icon: 'fa-duotone fa-futbol', title: this.$t("footer.playMatch") },
      { id: 4, icon: 'fa-solid fa-airplay', title: this.$t("footer.status") }
    ],
      showModalLogout:false,
      showChangeLanguage: false,
      showChangeTheme: false,
    };
  },
  methods: {
    changeLanguage(){
      this.showChangeLanguage = true;
    },
    changeTheme(){
      this.showChangeTheme = true;
    },
    goToRoute(route){
      this.$router.push(route);
    },
    setItemActive(item) {
      store.layout.showGetCoins = false;
      store.layout.footerItem = item;
      if(item != 'more'){
        if (this.$route.name != item) this.$router.push(item);
        store.layout.showModalMore = false
      }
      else{
        store.layout.showModalMore = true
      }
    },
    checkActive(item) {
      return [
        "item-footer",
        this.activeItem === item ? "item-footer-active" : "",
      ];
    },
  },
  watch:{
    selected(val){
      if(val == 1){
        this.setItemActive('home')
      }
      else if(val == 2){
        this.setItemActive('quiz')
      }
      else if(val == 3){
        this.setItemActive('play-match')
      }
      else if(val == 4){
        this.setItemActive('status')
      }
    }
  },
  computed: {
    showFooter() {
      return store.layout.footerType ;
    },
    activeItem() {
      return store.layout.footerItem;
    },
    language() {
      return i18n.locale;
    },
    themeLayout(){
      // console.log(store.layout.theme);
      return store.layout.theme;
    }
  },
  mounted(){

    this.$root.$on('bv', (text) => {
     this.selected = text;
    })

    if(this.$router.currentRoute.name == 'home'){
      this.selected = 1
    }else if(this.$router.currentRoute.name == 'quiz'){
      this.selected = 2
    }else if(this.$router.currentRoute.name == 'play-match'){
      this.selected = 3
    }else if(this.$router.currentRoute.name == 'status'){
      this.selected = 4
    }



    document.addEventListener('mousedown', (event) => {
      if(event.target.className != 'items' && event.target.className != 'list-item' && event.target.className != 'text-item' && event.target.className !='el-icon-right')
      {
        this.modal = false
        this.showChangeLanguage = false;
        this.showChangeTheme = false;
      }
    })
  }
};
</script>

<style scoped>
.footer-content {

  height: 55px;
  background-color: #121E48;
  max-width: 650px;
  width: 100%;
  margin: 0 auto  !important;
  position: fixed;
            bottom: 0;
            width: 100%;
  padding: 0px 0px;
  display: flex;
  justify-content: space-evenly;
  z-index: 2;
}

.item-footer {
  margin-top: 3px;
  /* border-bottom: v-bind(themeLayout) solid 3px; */
  border-bottom:#D74EF8;
  padding: 2px;
  width: 20%;
  align-self: center;
  color: rgb(224, 224, 224) !important;
}

.item-footer-active {
  border-bottom: #D74EF8 solid 3px;
}

.item-footer img {
  width: 30px;
  height: 22px;
  margin-top: 5px;
  margin-bottom: 0px;
  vertical-align: middle;
}

.item-footer span {
  font-size: 12px;
  margin-top: 0px;
  display: block;
}

.more {
  flex-direction: column;
  display: flex;
  height: 100%;
}

.items {
  padding-top: 20px;
  background-color: #232c41;
  color: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 20px;
}

.text-item {
  align-self: center;
  margin-left: 15px;
  font-family: 'Rabar_031';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
}
.text-item-change-language{
  display: flex;justify-content: space-evenly; width: 100%;
  color: #232C40;
  margin-left: 0px;
}

.list-item {
  display: flex;
  justify-content: space-between;
  background-color: #2a3757;
  border-radius: 6px;
  padding: 5px 10px 7px 10px;
  width: 70%;
  margin: 0 auto;
  cursor: pointer;
}

.change-list-item-inline{
  background-color: v-bind(themeLayout);
}

.rtl .list-item {
  flex-direction: row-reverse;
}

.list-item i {
  font-size: 22px;
 /* margin-left: auto; */
  align-self: center;
  padding-right: 5px;
  margin-top: 0 !important;
}
.separator {
  margin: 0px !important;
  border-left: #000000 1px solid;
}

.rtl .list-item i {
  transform:  rotate(180deg);;
}

.items :not(:first-child) {
  margin-top: 10px;
}


.gr-btn-container-foreground{
  background: #121E48 !important;
  color: #3D4676 !important;
}
 .fa-solid{
  color: #3D4676 !important;
}

.gr-btn-item-active{
  background: #D74EF8 !important;
  color: #ffffff !important;
}

</style>
