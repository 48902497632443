<template>
  <div class="content-page">
    <div align="center" class="favourites-content">


      <div class="fixed-header col-10 mx-auto">
        <div class="status-header">
          <div @click="changeToNational()" class="nationalTeams" :class="{ active: typeOf == 0 }">
            {{ $t("favourites.national_teams") }}
          </div>
          <div @click="changeToLeague()" class="leagues" :class="{ active: typeOf == 1 }">
            {{ $t("favourites.leagues_up") }}
          </div>
        </div>
      </div>




      <!-- national team -->
      <el-tabs v-model="national_tab" class="demo-tabs " v-if="typeOf == 0">


        <!-- <div class="search-player-around-button mx-auto">
          <img :src="require('@/assets/head-to-head/search.svg')" />
          <input class="input-search text" type="text" :placeholder="$t('favourites.search')" />
        </div> -->

        <!-- national teams  -->
        <el-tab-pane>
          <template #label>
            <span class="custom-tabs-label" @click="getFavorite()">
              <img :src="require('@/assets/favorites/star.svg')" alt="star" class="star-icon">
              <span class="text-white">{{ $t("favourites.favourites") }}</span>
            </span>
          </template>
        </el-tab-pane>

        <el-tab-pane>
          <template #label>
            <span class="custom-tabs-label" @click="getNationalByContinent('ASIA')">
              <span>{{ $t("favourites.continents.ASIA") }}</span>
            </span>
          </template>
        </el-tab-pane>
        <el-tab-pane>
          <template #label>
            <span class="custom-tabs-label" @click="getNationalByContinent('AFRICA')">
              <span>{{ $t("favourites.continents.AFRICA") }}</span>
            </span>
          </template>
        </el-tab-pane>
        <el-tab-pane>
          <template #label>
            <span class="custom-tabs-label" @click="getNationalByContinent('EUROPE')">
              <span>{{ $t("favourites.continents.EUROPE") }}</span>
            </span>
          </template>
        </el-tab-pane>
        <el-tab-pane>
          <template #label>
            <span class="custom-tabs-label" @click="getNationalByContinent('NORTHAMERICA')">
              <span>{{ $t("favourites.continents.NORTHAMERICA") }}</span>
            </span>
          </template>
        </el-tab-pane>
        <el-tab-pane>
          <template #label>
            <span class="custom-tabs-label" @click="getNationalByContinent('SOUTHAMERICA')">
              <span>{{ $t("favourites.continents.SOUTHAMERICA") }}</span>
            </span>
          </template>
        </el-tab-pane>
        <el-tab-pane>
          <template #label>
            <span class="custom-tabs-label" @click="getNationalByContinent('OCEANIA')">
              <span>{{ $t("favourites.continents.OCEANIA") }}</span>
            </span>
          </template>
        </el-tab-pane>
      </el-tabs>



      <!-- leagues -->

      <el-tabs v-model="league_tab" class="demo-tabs " v-if="typeOf == 1">


        <!-- <div class="search-player-around-button mx-auto">
          <img :src="require('@/assets/head-to-head/search.svg')" />
          <input class="input-search text" type="text" :placeholder="$t('favourites.search')" />
        </div> -->

        <!-- national teams  -->
        <el-tab-pane>
          <template #label>
            <span class="custom-tabs-label" @click="getFavorite()">
              <img :src="require('@/assets/favorites/star.svg')" alt="star" class="star-icon">
              <span>{{ $t("favourites.favourites") }}</span>
            </span>
          </template>
        </el-tab-pane>

        <el-tab-pane v-for="league in leagues" :key="league.id">
          <template #label>
            <span class="custom-tabs-label" @click="getTeamsByLeagueId(league.id)">
              <span>{{ league.name_en }}</span>
            </span>
          </template>
        </el-tab-pane>


      </el-tabs>

      <!-- end leagues -->




      <!-- content -->

      <!-- is favorite -->
      <div class="teams-group" v-if="isFavorite">

        <div class="row-teams">


          <div class="items" v-if="TeamData.length == 0">
            <div class="list-item">
              <span class="text-item">No Data</span>
            </div>
          </div>


          <div v-else class="items">
            <div v-for="data in TeamData" :key="data.id" class="list-item " @click="removeOneFavorite(data.team.id)">
             <div>
              <img height="30" width="30" :src="data.team.flag" /> <span class="text-item">{{ data.team.name_en }}</span>
             </div>

              <div class="buy-item">
                <img
                  :src=" require('@/assets/star.svg')"
                  height="20" width="20" />
              </div>

            </div>
          </div>

        </div>
      </div>

      <!-- @click="favTeams.find(t => t.teamId == data.id) ? removeFavorite(data.id) : addFavorite(data.id)" -->
      <!-- other team  -->
      <div class="teams-group" v-else>

        <div class="row-teams">
          <div class="items">
            <div v-for="data in TeamData" :key="data.id" class="list-item"
            @click="favTeams.find(t => t.teamId == data.id) ? removeFavorite(data.id) : addFavorite(data.id)" >
              <img height="30" width="30" :src="data.flag" /> <span class="text-item">{{ data.name_en }}</span>
              <div class="buy-item">
                <img
                  :src="favTeams.find(t => t.teamId == data.id) ? require('@/assets/star.svg') : require('@/assets/star_notSelected.svg')"
                  height="20" width="20" />
              </div>
            </div>
          </div>
        </div>



      </div>








    </div>
  </div>
</template>

<script>
import i18n from "@/translation";
import { store, notify } from "../store";
import { Service } from "../services";


export default {
  name: "FavouritesComponent",
  props: ["showUserFavourites"],
  components: {

  },
  data() {
    return {
      teams: [],
      nationalTeams: [],
      leagues: [],
      favorites: [],
      Newtype: 0,
      isFavorite: false,
      national_tab: 0,
      league_tab: 0,
      selectedFavorites: [],



    }
  },
  methods: {

    async getNationalByContinent(continent) {
      let response = await Service.getTeamsByContinent(continent);
      if (response.status == 200) {
        this.teams = response.data;
      }
    },

    getFavorite() {
      Service.getUserFavourites().then((res) => {
        this.favorites = res.data;
        this.teams = res.data;
        this.isFavorite = true;
      });
    },


    getFavoriteTeamTolist() {
            this.selectedFavorites = [];
            Service.getUserFavourites().then((res) => {

              this.favorites.map((item) => {
                this.selectedFavorites.push({ 'teamId': item.teamId })
            });

      });
      },

    changeToNational() {
      this.Newtype = 0
      this.national_tab = "0"
      this.teams = this.favorites
      this.isFavorite = true;
    },

    changeToLeague() {
      this.Newtype = 1
      this.league_tab = "0"
      this.teams = this.favorites
      this.isFavorite = true;
    },

    async removeOneFavorite(team_id){

      try {
        this.selectedFavorites = this.selectedFavorites.filter(function (item) {
          return item.teamId != team_id
        })

        let response = await Service.sendFavourites(this.selectedFavorites)
          if (response.status == 201) {
                this.teams = this.teams.filter((item) => item.teamId !== team_id);
          }

      } catch (error) {
        alert(error)
      }

      // let response= await Service.deleteFavourites(team_id)
      // if(response.status == 200){
      //   this.teams = this.teams.filter((item) => item.id !== team_id);
      //   console.log(response);
      // }
    },

    async addFavorite(team_id) {

    this.selectedFavorites.push({ 'teamId': team_id })
    console.log(this.selectedFavorites);
      let response = await Service.sendFavourites(this.selectedFavorites)
      if (response.status == 200) {
                console.log(response);
      }
    },


    //
    async removeFavorite(team_id) {

      try {
        this.selectedFavorites = this.selectedFavorites.filter(function (item) {
          return item.teamId != team_id
        })

        let response = await Service.sendFavourites(this.selectedFavorites)
          if (response.status == 200) {
                    console.log(response);
          }

      } catch (error) {
        alert(error)
      }

    },

    async getLeagues() {
      let response = await Service.getLeagues();
      if (response.status == 200) {
        this.leagues = response.data;
      }
    },

    async getTeamsByLeagueId(league_id) {
      this.isFavorite = false;
      let response = await Service.getTeamsByLeague(league_id);
      if (response.status == 200) {
        this.teams = response.data;
      }
    },


  },
  computed: {
    language() {
      return i18n.locale;
    },
    Service() {
      return Service;
    },
    isSelectFavourites() {
      return this.isDropdownSelection
    },
    typeOf() {
      return this.Newtype
    },
    TeamData() {
      return this.teams;
    },
    favTeams() {
      return this.selectedFavorites;
    }
  },
  async mounted() {
    store.layout.showSkip = true;
    store.layout.showLanguageSwitch = true
    this.getFavorite();
    this.getLeagues();
    this.getFavoriteTeamTolist();
  },
};
</script>



<style scoped>
.content-page {
  justify-content: flex-start;
}


.status-items {
  background-color: #0C1538;
  color: #ffffff;
  padding-bottom: 0px;
  /* height: calc(100% - 90px); */
  margin-top: 20px;
  width: 100%;
  /* overflow-y: scroll; */
  padding-bottom: 15px;
}

.status-header {
  color: #2a3757;
  background-color: #182252;
  color: #ffffff;
  display: flex;
  font-size: 22px;
  padding: 2px 0;

  border-radius: 15px;
  border-radius: 15px;
}


.nationalTeams {
  width: 50%;

}

.leagues {
  width: 50%;
}

.nationalTeams.active {
  background: linear-gradient(#3046BA, #18235D);
  color: white;
  border-radius: 15px;
  border-bottom: none;
}

.leagues.active {
  background: linear-gradient(#3046BA, #18235D);
  color: white;
  border-radius: 15px;
  border-bottom: none;
}


.star-icon {
  position: relative;
  top: -2px !important
}


.favourites-content {
  padding: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.input-search {
  border-width: 0px;
  background-color: #0C1538;


}

.search-player-around-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px !important;
  align-items: center;
  background-color: #0C1538;
  height: 30px;
  width: 90%;
  border-radius: 7px;
  margin-bottom: 10px;
  border: 1px solid #2D3B4B;
}

.search-player-around-button .text {
  font-size: 14px !important;
  color: #FFFFFF !important
}



.title {
  font-size: 26px;
  color: #ffffff;
  margin-top: 20px;
}

.card-fav-team {
  background: linear-gradient(#213182, #111941) !important;
  color: #C1DEFF !important;
  cursor: pointer !important;
}


.card-fav-team .card-body {
  /* padding: 30px 15px 20px 15px !important; */
  height: 100px !important;
  padding: 15px 30px 25px 30px !important;
}


.card-fav-team .card-title {
  color: #ffffff !important;
  font-size: 18px !important;
}

.card-fav-team .card-subtitle {
  color: #f7f7f7 !important
}


.fav-team-btn {
  color: #D74EF8 !important;
  font-size: 16px;
}

.fav-header {
  color: #2a3757;
  background-color: #182252;
  color: #ffffff;
  display: flex;
  font-size: 22px;
  padding: 2px 0;
  border-radius: 15px;
}

.nationalTeams {
  width: 50%;
}

.leagues {
  width: 50%;
}

.nationalTeams.active {
  background: linear-gradient(#3046BA, #18235D);
  color: white;
  border-radius: 15px;
  border-bottom: none;
}

.leagues.active {
  background: linear-gradient(#3046BA, #18235D);
  color: white;
  border-radius: 15px;
  border-bottom: none;
}

.teams-types div {
  background-color: #445C87;
  padding: 2px 10px;
  width: 140px;
  border-radius: 5px;

}

.teams-types div:first-of-type {
  margin-right: 10px;
}

.buttons1 {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  width: 110px;
  height: 33px;
  border-radius: 50px;
  background: linear-gradient(180deg, #FFCBAA 0%, #C3B9B4 100%);
  font-family: 'Rabar_031';
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #0C1538;
  margin: 15px auto;
}

.area-double {
  display: flex;
  justify-content: space-between;
}

.area-double .custom-button {
  width: 140px;
}


.selected-option {
  background-color: #aaff01 !important;
  color: #445c87 !important;
}

.el-select {
  width: 100%;
}

.el-select>>>.el-input__inner {
  background: none;
  border: #808080 solid 1px;
  padding: 0 10px;
  height: 35px;
  margin-top: 20px;
  width: 100%;
  padding-top: 3px;
  font-size: 15px;
  line-height: 35px;
  color: #ffffff;
}

.el-select>>>.el-input__inner::placeholder {
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
}

.el-select>>>.el-select__caret {
  top: 28px;
  width: 0;
  right: 8px;
  height: 0;
  border-left: 8px solid transparent;
  border-top: 8px solid #fff;
  border-right: 8px solid transparent;
  position: relative;
  transform: none;
}

.el-select>>>.el-input.is-focus .el-input__inner {
  border-color: inherit;
}

.el-select>>>.el-icon-arrow-up:before,
.el-select>>>.el-icon-arrow-up:after {
  display: none;
}

.el-select-dropdown__item {
  font-size: 16px;
  padding: 0 15px;
  color: #606266;
  height: 30px;
  line-height: 35px;
}

.items {
  margin-top: 0px;
  /* padding-top: 20px; */
  background-color: transparent;
  border-radius: 30px;
  flex-grow: 1;
  padding-bottom: 20px;
}

.teams-group {
  margin-top: 0px !important;
}


.list-item {
  display: flex;
  background: #101A41 !important;
  border-radius: 10px;
  padding: 6px 10px 6px 10px;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between !important;
  margin-top: 12px;
}

.list-item-video {
  background-color: #FFB000 !important;
}


.text-item {
  align-self: center;
  margin-right: auto;
  padding-left: 20px;
  font-family: 'Rabar_031';
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.text-item-video {
  color: #2A3757 !important;
}

.buy-item {
  height: 25px;
  align-self: center;
  padding: 3px 22px;
  border-radius: 10px;
  padding-top: 0px;
  font-family: 'Rabar_031';
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.buy-item-video {
  background-color: #232C40 !important;
  color: #FFFFFF !important;
}

.teams-group {
  width: 100%;
  margin-top: 30px;
}

.teams-header {
  padding: 0px 25px 6px 5px;
  font-size: 18px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
}

.row-teams {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.item-team {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  background-color: #445C87;
  padding: 5px;
  border-radius: 5px;
  width: 60px;
  color: #ffffff;
  font-size: 11px;
}

.item-team span {
  white-space: normal;
  line-height: 10px;
}

.item-team img {
  margin: 0 auto;
  margin-bottom: 3px;
  margin-top: 3px;
}

.selected-team {
  background-color: #aaff01 !important;
  color: #445c87 !important;
}
</style>