import MockAdapter from 'axios-mock-adapter'
import userAnswerMockData from './user-answer.json';
import matchDayMockData from './match-day.json';
import matchResultsMockData from './match-results.json';
import matchBuysCoinsData from './buy-coins.json';
import betStatus from './bet-status.json'
import prizesData from './prizes.json'
import carouselsData from './carousel.json'
import rankingData from './ranking.json'
import favouritesData from './favourites.json'
import userData from './user.json'
import imagesData from './images.json'
import winnersData from './winners.json'
import countryData from './country.json'

const mocks = (axios) => {
  const mock = new MockAdapter(axios, 
    { 
      onNoMatch: "passthrough"
    }
  )
  
  //mock.onGet(new RegExp('/user/requestPinCode/')).reply(409, "Failed")

  mock.onGet(new RegExp('/user-answer/nextQuestion')).reply(200, userAnswerMockData.nextQuestion)
  mock.onPatch(new RegExp('/user-answer/answerQuestion')).reply(200, userAnswerMockData.answerQuestion)
  
  mock.onGet(new RegExp('/match-day/matchesResult')).reply(200, matchResultsMockData)
  mock.onGet(new RegExp('/match-day/')).reply(200, matchDayMockData)
  
  mock.onGet(new RegExp('/betCardsByUser/')).reply(200, betStatus)
  
  mock.onGet(new RegExp('/purchaseCoins/requestPinCode/')).reply(200, matchBuysCoinsData.requestPinCode)
  mock.onGet(new RegExp('/purchaseCoins/verifyPinCode/')).reply(200, matchBuysCoinsData.verifyPinCode)
  
  mock.onGet(new RegExp('/prize/active')).reply(200, prizesData)
  
  mock.onGet(new RegExp('/prize/en')).reply(200, carouselsData)
  
  mock.onGet(new RegExp('/user-ranking/')).reply(200, rankingData)

  mock.onGet(new RegExp('/favourites/popularTeams')).reply(200, favouritesData)
  
  mock.onGet(new RegExp('/user/me')).reply(200, userData.me)
  
  mock.onGet(new RegExp('/images/')).reply(200, imagesData.home)

  mock.onGet(new RegExp('/winner/')).reply(200, winnersData)
  
  mock.onGet(new RegExp('/country')).reply(200, countryData)
}

export default mocks;