var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-us"},[_c('div',{staticClass:"arrow-title"},[_c('div',{staticClass:"arrow"},[_c('img',{attrs:{"src":require('@/assets/arrow.svg')},on:{"click":_vm.goBack}})]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("faqs.title")))])]),_c('div',{staticClass:"about",style:({ direction: _vm.language != 'en' ? 'rtl' : 'ltr' })},[_c('div',{staticClass:"list-item-selected"},[_c('div',{staticClass:"text-item-selected"},[_vm._v(" "+_vm._s(_vm.$t("contact.email"))+" ")]),_vm._m(0)]),_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"text-item"},[_vm._v(_vm._s(_vm.$t("faqs.torliga")))]),_vm._m(1)]),_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"text-item"},[_vm._v(_vm._s(_vm.$t("faqs.play")))]),_vm._m(2)]),_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"text-item"},[_vm._v(_vm._s(_vm.$t("faqs.points")))]),_vm._m(3)]),_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"text-item"},[_vm._v(_vm._s(_vm.$t("faqs.win_prizes")))]),_vm._m(4)]),_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"text-item"},[_vm._v(_vm._s(_vm.$t("faqs.whats_prizes")))]),_vm._m(5)]),_c('div',{staticClass:"list-item"},[_c('div',{staticClass:"text-item"},[_vm._v(_vm._s(_vm.$t("faqs.coins")))]),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('i',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","width":"22px","height":"22px","position":"relative","float":"right"}},[_c('img',{attrs:{"src":require("@/assets/arrow-dark.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('i',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","width":"22px","height":"22px","position":"relative","float":"right","color":"#a9fb03"}},[_c('img',{staticStyle:{"transform":"rotate(180deg)"},attrs:{"src":require("@/assets/arrow.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('i',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","width":"22px","height":"22px","position":"relative","float":"right","color":"#a9fb03"}},[_c('img',{staticStyle:{"transform":"rotate(180deg)"},attrs:{"src":require("@/assets/arrow.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('i',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","width":"22px","height":"22px","position":"relative","float":"right","color":"#a9fb03"}},[_c('img',{staticStyle:{"transform":"rotate(180deg)"},attrs:{"src":require("@/assets/arrow.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('i',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","width":"22px","height":"22px","position":"relative","float":"right","color":"#a9fb03"}},[_c('img',{staticStyle:{"transform":"rotate(180deg)"},attrs:{"src":require("@/assets/arrow.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('i',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","width":"22px","height":"22px","position":"relative","float":"right","color":"#a9fb03"}},[_c('img',{staticStyle:{"transform":"rotate(180deg)"},attrs:{"src":require("@/assets/arrow.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('i',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","width":"22px","height":"22px","position":"relative","float":"right","color":"#a9fb03"}},[_c('img',{staticStyle:{"transform":"rotate(180deg)"},attrs:{"src":require("@/assets/arrow.svg"),"alt":""}})])
}]

export { render, staticRenderFns }