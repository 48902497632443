<template>
    <el-dialog
        :show-close="false"
        :visible="true"
        :append-to-body="true"
        @close="closeDialog"
        center>
        <div align="center" class="mb-3">
        <img class="mx-auto" src="../assets/logout.svg" alt="logout">
        </div>
        <div align="center">
          <div class="dialog-message"> {{ $t("logout.msg") }} </div>
        </div>
        <span slot="footer" class="container-footer-btn" >
    
            <div class="btn button-keep-me " @click="closeDialog">{{ $t("logout.no")}} </div>
   

            <div class="btn button-logout" @click="logout"> {{ $t("logout.yes")}} </div>
        </span>
    </el-dialog>
</template>

<script>
import { store } from "../store";
export default {
  name: "ModalLogoutComponent",
  data() {
    return {}
  },
  methods: {
    closeDialog(){
        this.$emit("closeDialog", true);
    },
    logout(){
        this.$emit("logout");
        this.$emit("closeDialog", true);
    }
  }
};
</script>

<style scoped>
.buttons-container{
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
}

.container-footer-btn{
  margin-top: 25px !important;
}

.button-keep-me {
  width: 80% !important;
  background-color: #D74EF8 !important;
  color: #ffffff !important;
  margin: 0 auto !important;
  padding:10px  20px !important;
  border-radius: 20px !important;
  font-size: 20px !important;
  margin-bottom: 20px !important;
}
.button-logout {
  width: 60% !important;
  background-color: transparent !important;
  color: #FFCBAA !important;
  margin: 0 auto !important;
  padding:10px  20px !important;
  border-radius: 20px !important;
  font-size: 20px !important;
  border: 1px solid #FFCBAA !important;
}

.buttons{
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 33px;
  border-radius: 5px;
  margin: 5px;
  background-color: #A9FB03;
  font-family: 'Rabar_031';
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #2A3757
}

:deep(.el-dialog) {
  /* background: rgb(236, 235, 235);
  background: linear-gradient(
    0deg,
    rgba(240, 240, 240, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  ); */
  background-color: #51105F;
  border-radius: 20px !important;
  box-shadow: -3px 3px 6px 0px black;
  /* border: 2px solid #aaff01; */
  
}

.el-dialog {
  max-width: 20% !important;
}

.dialog-message {
  width: 100%;
  font-family: 'Rabar_031';
  font-size: 26px;
  font-weight: 400;
  line-height: 39px;
  /* letter-spacing: 0em; */
  word-break: keep-all;
  text-align: center;
  color: #E8E8E8;
}

.el-dialog--center .el-dialog__body {
  padding: 25px 20px 25px 20px !important;
}

:deep(.el-input-number__decrease),
:deep(.el-input-number__increase) {
  background-color: #2A3757 !important;
  color: rgb(28, 0, 73);
  font-size: 14px;
  border: #212b42 1px solid;
}

:deep(.el-input-number) {
  width: 100%;
}

:deep(.dialog-footer) button {
  width: 100%;
  font-size: 14px;
  display: block;
  background-color: #2A3757 !important;
  border: #212b42 1px solid;
  color: #212b42;
}

.dialog-message .sub-message {
  font-size: 13px;
}

</style>
