<template>
  <div class="content-page">





    <div v-if="matchDay" class="checkout-content">
      <div class="match-resume" v-for="(m, i) in matchDay.matches.filter(m => m.selected.length > 0)" :key="i">


        <div class="d-flex justify-content-between align-items-center">

          <div class="teams col-4">
              <div class="both_team">{{ m.homeTeam["name_" + language] + ' - ' + m.awayTeam["name_" + language] }}</div>
              <div class="team">{{ getSelectedTeam(m) }}</div>
          </div>


          <div v-if="m.selected.length > 0" class="match-points col-4">
              {{ m.points + ' ' + $t('matchResults.points') }}
          </div>
          <div v-else class="match-points col-4">-</div>



          <div class="add_coin_btn col-4">
            <img :src="require('@/assets/checkout/add_coin.svg')" alt="add_coint"  @click="addPoints(m)">
          </div>




        </div>

        <div class="match-bet-separator"></div>


        <!-- <div class="match-teams">
          {{ m.homeTeam["name_" + language] + ' - ' + m.awayTeam["name_" + language] }}
        </div>

        <div class="match-bet">
          <div class="match-bet-result">{{ getSelectedTeam(m) }}</div>
          <div v-if="m.selected.length > 0" class="match-bet-points">
              {{ m.points + ' ' + $t('matchResults.points') }}
              <el-button class="add-points" @click="addPoints(m)" type="primary" icon="el-icon-plus"></el-button>
          </div>
          <div v-else class="match-bet-points">-</div>
        </div>

        <div class="match-bet-separator"></div> -->

      </div>


   <div class="fixed-rel">
    <div class="d-flex justify-content-between totalpoints">
          <div>{{ $t('playMatch.totalPoints')}}</div>
          <div>{{  matchDay.matches.filter(m => m.selected.length > 0).reduce((acc, match) => { return parseInt(acc) + parseInt(match.points)}, 0) }}</div>
      </div>

      <div class="d-flex justify-content-between totalCoin">
          <div>{{ $t('playMatch.totalCoin')}}</div>
          <div>   {{ (Math.abs(-currentCoins)) }} </div>
      </div>


      <div class="continue-btn col-10" @click="submit()">
          {{ $t('forgot_password.continue')}}
      </div>

   </div>



    </div>

    <ModalPoints v-if="showPointsDialog" @closeDialog="onCloseDialog" @setPoints="onSetPoints" :points="currentMatch.points" :minPoints="currentMatch.minPoints"></ModalPoints>
    <ModalNeedCoins v-if="showModalNeedCoins" @actionGetCoins="onActionGetCoins"></ModalNeedCoins>
    <ModalNeedSubscribe v-if="showModalNeedsubscribe"></ModalNeedSubscribe>
  </div>
</template>

<script>
//
import i18n from "@/translation";
import { store, notify } from "../store";
import { Service } from "../services";
import ModalPoints from "../components/ModalPoints.vue";
import ModalNeedCoins from "../components/ModalNeedCoins.vue";
import ModalNeedSubscribe from "../components/ModalNeedSubscribe.vue";

export default {
  name: "CheckoutComponent",
  props: {
    matchDay: Object
  },
  components: {
    ModalPoints,
    ModalNeedCoins,
    ModalNeedSubscribe
  },
  data() {
    return {
      showPointsDialog: false,
      currentMatch: null,
      currentCoins: 0,
      showModalNeedCoins: false,
      showModalNeedsubscribe: false
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },

    addPoints(match) {
      this.showPointsDialog = true;
      this.currentMatch = match;
    },

    getSelectedTeam(match){
      let selected = []
      match.selected.forEach(id => {
        if(id == match.awayTeam.id)
          selected.push(match.awayTeam["name_" + this.language] )
        else if(id == match.homeTeam.id)
          selected.push(match.homeTeam["name_" + this.language] )
        else
          selected.push(i18n.t("playMatch.draw"))
      })
      return selected.length > 0 ? selected.join(' - ') : this.$t("playMatch.notSelected")
    },

    onSetPoints(points) {
      let value = this.currentCoins + (this.currentMatch.points < points ? -1 : 1);
      this.currentCoins = value
      this.currentMatch.points = points;
    },

    onCloseDialog(){
      this.showPointsDialog = false
    },

    async sendMatchDayBet(matchDay, isEdit) {
      console.log(matchDay);
      let bets = this.getMatchDayBet(matchDay);
      let response = await Service.sendMatchDayBet(bets, isEdit);
      if (response && response.status === 201) {
        notify(
          this.$t('general.success'),
          this.$t('playMatch.success'),
          "success"
        );
        store.currentMatchDayCheckout = null;
        this.$router.push("/play-match");
      }
    },

    getMatchDayBet(matchDay) {
      return matchDay.matches.filter((m) => m.selected.length > 0).map((m) => {
        return {
          matchId: m.id,
          homeWin: m.selected.indexOf(m.homeTeam.id) > -1,
          awayWin: m.selected.indexOf(m.awayTeam.id) > -1,
          draw: m.selected.indexOf("draw") > -1,
          points: m.points.toString(),
        };
      });
    },
    submit(){
      if(!store.subscribed){
        this.showModalNeedsubscribe = true;
        return;
      }
      else if (store.userBalance + this.currentCoins < 0) {
        this.showModalNeedCoins = true
        return;
      }
     else {
        let isEdit = this.matchDay.matches.find((m) => m.preBets.length > 0);
        console.log(this.matchDay);
        this.sendMatchDayBet(this.matchDay, isEdit);
      }
    },

    onActionGetCoins(type){
      this.showModalNeedCoins = false
      // if(type === 'buy')
      //   store.layout.showGetCoins = true
    }
  },
  computed: {
    language() {
      return i18n.locale;
    },
  },
  watch: {
  },
  mounted() {

    if(!this.matchDay)
      this.$router.push("/play-match");
    else {
      store.layout.showUserWallet = true
      store.layout.footerItem = "play-match";
      store.layout.showGoBack = true;
      store.layout.footerType = "";
      store.layout.showLanguageSwitch = true

      this.currentCoins = this.matchDay.currentCoins
      document.addEventListener('mousedown', (event) => {
        if(event.target.className == 'el-dialog__wrapper')
        {
          this.showModalNeedCoins = false
        }
      })
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .content-page {
   /* height: 100% !important; */
  }


  /* new */


  .checkout-content {
    padding: 18px !important;
    margin-bottom: 147px !important;

  }


  .teams .both_team{
    font-size: 12px;
    color: #FFCBAA;
    text-align:start;
  }

  .teams .team{
    font-size: 12px;
    color: #ffffff;
    text-align:start;
  }

  .match-points{
    color: #FFC115;
    font-size: 12px;
  }

  .add_coin_btn{
    text-align: end !important;
  }


  .continue-btn{
    background: #D74EF8 !important;
    text-decoration: none !important;
    color: #ffffff;
    padding: 10px 60px  !important;
    border-radius: 20px !important;
    font-size: 16px !important;
    margin: 50px auto 0px auto !important;
    font-size: 23px !important;
  }


  .totalpoints{
    color: white !important;
  }

  .totalCoin{
    color: #D74EF8 !important;
  }


  .fixed-rel{
    padding: 20px 0px !important;
    position: relative !important;
    bottom: 0 !important;
}


  .checkout-header {
    height: 60px;
    display: flex;
    justify-content: left;
    align-content: center;
    flex-wrap: wrap;
    margin-left: 15px;
  }

  .checkout-header img {
    width: 23px;
    height: 16px;
  }



  .checkout-content::-webkit-scrollbar {
    display: none;
  }

  .match-teams {
    font-size: 12px;
    color: #ffffff;
    text-align: left;
    padding-left: 20px;
  }
  .match-bet {
    display: flex;
    padding-bottom: 2px;
  }

  .match-bet-separator {
    border-bottom: #34405F solid 1px;
    margin: 12px 0px;
  }
  .match-bet-result {
    font-size: 12px;
    color: #aaff01;
    justify-self: flex-start;
    padding-left: 20px;
  }

  .match-bet-points {
    font-size: 12px;
    color: #aaff01;
    justify-self: flex-end;
    padding-right: 20px;
    margin-left: auto;
  }
  .total-points {
    text-align: right;
    padding-right: 20px;
    color: #aaff01;
    font-size: 12px;
  }

  .add-points {
    margin-left: 3px;
    padding: 1px;
    border-color: #ffffff !important;
    margin-right: 0px;
    color: #000000;
    background-color: #aaff01;
    border: 0px;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 10px;
  }

:deep(i.el-icon-plus) {
  color: #000000;
  background-color: #aaff01;
  border: 0px;
  border-radius: 10px;
  text-transform: uppercase;
}

  .el-button-group {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .el-button-group .el-button--primary:first-child {
    min-width: 140px;
    padding: 8px 20px 8px 50px!important;
    text-align: left;
    background-color: #aaff01;
    border-right: #232C40 1px solid!important;
    color: #232C40;
    margin-right: 0!important;
  }

  .el-button-group .el-button--primary:last-child {
    padding: 8px 25px!important;
    background-color: #aaff01;
    border-right: none!important;
    color: #232C40;
    margin-right: 0!important;
  }

   :deep(.el-button){
      border: none
   }

   :deep(.el-button-group) .el-button--primary:first-child span {
    font-size: 12px!important;
    border:none;
  }

  :deep(.el-button-group) .el-button--primary:last-child span {
    font-size: 12px!important;
    border:none;
  }

</style>