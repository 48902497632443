<template>
  <div class="terms-and-conditions">

    <div class="terms" :style="{ direction: language != 'en' ? 'rtl' : 'ltr' }">
      <div class="terms-text">
        <span v-if="language == 'en'" style="text-align: left;">
          <p class="en">


            Terms of subscribing to Goal Pro <br>

            - Subscription terms are a legal agreement between Midsense and the service subscriber on this site. <br>
            - By using the service, users agrees to pay the following service subscription depending on the country and
            operator.<br>

            Service Price and Unsubscribing<br>
            For Zain Iraq, the Service cost is 240 IQD<br>
            To unsubscribe from this Service, send UGP to 3368<br> <br>


          </p>
        </span>
        <span v-if="language == 'ar'">
          <p class="ar">
            <br> شروط الإشتراك بخدمة Goal Pro
            <br> شروط الاشتراك عبارة عن إتفاق قانوني بين شركة MidSense و المشترك بالخدمة على هذا الموقع.

            <br><br> بإستخدام الخدمة، يوافق المستخدمون على دفع إشتراك الخدمة حسب البلد ومزود الخدمة.

            <br> سعر الخدمة وإلغاء الإشتراك
            <br> لمشتركي زين, سعر الخدمة 240 دينار عراقي
            <br> لإلغاء الإشتراك من الخدمة أرسل UGP إلى 3368

          </p>
        </span>
        
        <span v-if="language == 'ku'">
          <p class="ku">
            مەرجەکانی بەشداربوون لە خزمەتگوزاری Goal Pro <br>
            مەرجەکانی بەشداربوون گرێبەستێکی یاساییە لە نێوان کۆمپانیای MidSense و بەشداربووی خزمەتگوزارییەکە لەم ماڵپەڕە.
            بە بەکارهێنانی خزمەتگوزارییەکە، بەکارهێنەران ڕازین بەوەی کە پارەی بەشداریکردن لە خزمەتگوزارییەکە بدەن بەپێی
            وڵات و دابینکەری خزمەتگوزاری.  <br><br>

            نرخی خزمەتگوزاری و راگرتن <br>
            بۆ بەشداربووانی زەین نرخی خزمەتگوزارییەکە 240 دیناری عێراقییە <br>
            بۆ ڕاگرتنی بەشداریکردن لە خزمەتگوزارییەکە، UGP بنێرە بۆ 3368
            <br>
          </p>
        </span>
      </div>
      <div style="height: 350px"></div>
    </div>
  </div>
</template>

<script>
import i18n from "@/translation";
import {
  store,
  notify
} from "../store";

export default {
  name: "TermsComponent",
  data() {
    return {};
  },
  methods: {
    goToRoute(route) {
      this.$router.push("/" + route);
    },
    goBack() {
      this.$router.back();
    },
  },
  computed: {
    language() {
      return i18n.locale;
    },
  },
  mounted() {
    if (store.userToken) {
      store.layout.footerType = "FooterHome";
      store.layout.footerItem = "more";
      store.layout.showUserWallet = true;
    } else {
      store.layout.footerType = "";
      store.layout.showUserWallet = false;
    }
    store.layout.showLanguageSwitch = true

  },
};
</script>

<style scoped>
.arrow-title {
  display: flex;
  justify-items: center;
  align-items: center;
  height: 54px;
  margin-top: 10px;
}

.arrow {
  padding-left: 15px;
  width: 55px;
}

.arrow-title img {
  vertical-align: middle;
}

.arrow-title .title {
  padding-right: 55px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: cover;
  width: 100%;
  color: #D74EF8;
  padding: 0;
  font-family: 'Rabar_031';
  font-size: 26px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
}

.terms-and-conditions {
  flex-direction: column;
  display: flex;
  overflow-y: hidden;
}

.terms {
  background-color: #0C1538;
  color: #000;
  flex-grow: 1;
  padding: 0px 10px;
  overflow-y: auto;
}

.terms-text {
  font-family: 'Rabar_031';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  padding: 5px 10px;
  line-height: 27px;
  color: #FFFFFF;
  margin-top: 20px;
  height: var(--appHeight);
}

.en {
  text-align: justify;
}</style>