const utils = {
    methods: {
        getAbsoluteHeight(el) {
            try {
                el = document.querySelector(el);
                let styles = window.getComputedStyle(el);
                let margin =
                    parseFloat(styles["marginTop"]) + parseFloat(styles["marginBottom"]);
                return Math.ceil(el.offsetHeight + margin) || 0;
            } catch (e) {
                return 0;
            }
        },
        interpolate($text, $val) {
            $val.forEach((element) => {
                $text = $text.replace("#", element)
            });
            return $text
        }
    }
}

export default utils