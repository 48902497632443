<template>
  <div class="content-page">

    <UserInfoNav/>

    <div class="quiz-content" :style="{ visibility: isLoading ? 'hidden' : 'visible' }">
      <div class="question-body">
        <!-- <div class="quiz-title"> {{ $t("quiz.title") }} </div> -->
        <div class="question-title">
          {{ question["description_" + language] }}
        </div>
        <div @click="showPointsDialog = true" class="points" :style="{ visibility: !isSubmit ? 'hidden' : 'visible' }">
          {{ points + " " + $t("quiz.points") }}
          <el-button
            class="add-points"
            type="primary"
            icon="el-icon-plus">
          </el-button>
        </div>

        <div class="answer-points" v-if="!isSubmit && this.$i18n.locale !== 'en'">
          <img :src="require('@/assets/medal.svg')">
          <p style="color: #FFC008;"><b>{{  $t("quiz.points_earned") + ' : ' + pointsEarned + ' ' + $t("quiz.points") }}</b></p>
        </div>
        <div class="answer-points" v-if="!isSubmit && this.$i18n.locale === 'en'">
          <img :src="require('@/assets/medal.svg')" >
          <p style="color: #FFFFFF;"><b>{{  $t("quiz.points_earned") + ' : ' + pointsEarned + ' ' + $t("quiz.points") }}</b></p>
        </div>

        <div class="answers" align="center">
          <div
            v-for="pos in 2"
            :key="pos"
            :class="{
              answer: true,
              selectedAnswer: selectedAnswer == pos && isSubmit && released,
              wrongAnswer: !isSubmit && rightAnswer != pos && selectedAnswer == pos,
              correctAnswer: !isSubmit && rightAnswer == pos && selectedAnswer == pos,
              rtlDirection: language != 'en',
            }"
            @click="selectAnswer(pos)">
            <span> {{ question["answer" + pos + "_" + language] }} </span>
            <img v-if="!isSubmit && rightAnswer != 0 && selectedAnswer == pos" class="answer-icon" :src="rightAnswer == pos ? require('@/assets/check-quiz.svg') : require('@/assets/new_wrong_answer.svg')" />
            <img class="locked-icon" v-if="isSubmit && !released" :src="require('@/assets/locked.svg')"  />
          </div>
        </div>
        <div v-if="isSubmit && released" @click="anwserQuestion()" class="submit-content">
          <div class="total-coins"><i class="el-icon-coin"></i>  {{ currentBetCoins + ' ' +  $t("quiz.coins") }} </div>
          <div class="submit-button">{{ $t("quiz.submit") }} </div>
        </div>
        <div v-if="isSubmit && !released" @click="anwserQuestion()" class="submit-content">
          <div class="submit-full d-flex justify-content-center">
            <span>{{ $t("quiz.needCoins") }}</span>
            <img class="mx-2" :src="require('@/assets/locked.svg')"  />
          </div>
        </div>


        <div v-if="!isSubmit" @click="showNextQuestion()" class="submit-content">
          <div class="submit-full"> {{ $t("quiz.next_question") }} </div>
        </div>
      </div>
      <!-- <PrizesBanner></PrizesBanner> -->
    </div>
    <ModalPoints
      v-if="showPointsDialog"
      @closeDialog="onCloseDialog"
      @setPoints="onSetPoints"
      :points="points"
      :minPoints="100">
    </ModalPoints>
    <ModalNeedCoins v-if="showModalNeedCoins" @actionGetCoins="onActionGetCoins">
    </ModalNeedCoins>
  </div>
</template>

<script>
import { store, notify } from "../store";
import i18n from "@/translation";
import utils from "../utils";
import { Service } from "../services";
import ModalPoints from "../components/ModalPoints.vue";
import ModalNeedCoins from "../components/ModalNeedCoins.vue";
// import PrizesBanner from "../components/PrizesBanner.vue"
import UserInfoNav from "../components/UserInfoNav.vue"

export default {
  name: "QuizComponent",
  mixins: [utils],
  components: {
    ModalPoints,
    ModalNeedCoins,
    UserInfoNav,
        // PrizesBanner,
  },
  data() {
    return {
      selectedAnswer: 0,
      isSubmit: true,
      rightAnswer: 0,
      userAnswerID: 0,
      pointsEarned: 0,
      order: 0,
      question: {},
      points: 100,
      showPointsDialog: false,
      showModalNeedCoins: false,
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    async anwserQuestion() {
      if (store.userBalance - this.points / 100 < 0) {
        this.showModalNeedCoins = true;
        return;
      }
      if (this.selectedAnswer == 0) {
        notify(
          this.$t("error.error"),
          this.$t("error.select_quiz_response"),
          "error"
        );
        return;
      }
      let response = await Service.anwserQuestion(this.userAnswerID,  this.selectedAnswer, this.points);
      if (response && response.status === 200) {
        this.isSubmit = false;
        this.rightAnswer = response.data.question.rightAnswer;
        this.pointsEarned = response.data.points;
      }
    },
    async showNextQuestion() {
      await this.getNextQuestion();
      this.selectedAnswer = 0;
      this.rightAnswer = 0;
      this.isSubmit = true;
    },
    selectAnswer(answer) {
      this.selectedAnswer = answer;
    },
    async getNextQuestion() {
      let response = await Service.getNextQuestion();
      if (response && response.status === 200) {
        store.layout.showLogoutOverlay = false;
        this.userAnswerID = response.data.id;
        this.order = response.data.order;
        this.question =response.data.question;
        this.points = 100;
      } else if (response && response.status === 400) {
        notify(
          this.$t("error.error"),
          this.$t("error.maximum_quiz_reached"),
          "warning"
        );
        setTimeout(() => {
          store.layout.showLoading = false;
        }, 3000);
      }
    },
    onSetPoints(points) {
      this.points = points;
    },
    onCloseDialog() {
      this.showPointsDialog = false;
    },
    onActionGetCoins(type) {
      this.showModalNeedCoins = false;
      // if (type === "buy") store.layout.showGetCoins = true;
    },
  },
  computed: {
    isLoading() {
      return store.layout.showLoading;
    },
    released() {
      return store.userBalance - this.points / 100 >= 0;
    },
    needCoins() {
      return Math.abs(store.userBalance - this.points / 100);
    },
    currentBetCoins() {
      return this.points / 100
    },
    language() {
      return i18n.locale;
    },
    Service() {
      return Service;
    },
  },
  async mounted() {
    await this.getNextQuestion();
    store.layout.showGoBack = true;
    store.layout.showUserWallet = true;
    store.layout.footerItem = "quiz";
    store.layout.footerType = "FooterHome";
    store.layout.showLanguageSwitch = true

  },
};
</script>

<style scoped>
:deep(.v-modal){
  background: none !important;
}
.content-page {
    margin-bottom: 120px !important;
  }

.quiz-header {
  height: 60px;
  display: flex;
  justify-content: left;
  align-content: center;
  flex-wrap: wrap;
  margin-left: 15px;
}

.quiz-header img {
  width: 23px;
  height: 16px;
  margin-left: 10px;
}

.quiz-content {
  padding: 0 0px;
  background: #0C1538;
  padding: 0px 0px;
  /* padding-top: 50px; */
  padding-bottom: 0px;
  overflow-y: scroll;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  -ms-overflow-style: none;
   scrollbar-width: none;
  height: calc(100% - 30px) !important;
}

.quiz-content::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.quiz-title {
  color: #fff;
  position: absolute;
  top: 100px;
  background: #445C87;
  padding: 4px 0px;
  width: 250px;
  font-size: 22px;
  border-radius: 5px;
}

.question-body {
  border-radius: 15px;
  border: 1px #182252 solid;
  padding-bottom: 10px;
  background-color: #182252;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px 60px 20px;
}

.points {
  color: #FFFFFF;
  font-size: 22px;
  text-align: right;
  padding: 0px 0px 30px 0px;
  align-self: center;
}
.add-points {
  padding: 3px;
  margin-top: 15px;
  border-color: #ffffff !important;
  color: #000000;
  background-color: #ffffff;
  border: 0px;
  font-weight: bold;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 15px;
}
.question-title {
    padding: 25px 15px;
    text-align: center;
    font-size: 22px;
    color: #FFCBAA;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    line-height: 20px;
    align-content: center;
}
.answer-points {
  font-size: 16px;
  color: white;
  margin-top: -60px;
  padding-bottom: 15px;
}
.submit-content {
  padding: 0px;
  width: 250px;
  font-size: 20px;
  cursor: pointer;
}

.total-coins {
  width: 100%;
  text-align: center;
  color: #ffffff;
  margin-bottom: 30px;
}
.submit-full {
  width: 100%;
  padding: 4px 0;
  text-align: center;
  background: linear-gradient(180deg, #FFCBAA 0%, #C3B9B4 100%);
  border-radius: 90px 90px 90px 90px;
  margin-bottom: 10px;
  color: #ffffff;
}

.submit-button {
  padding: 8px 0;
  text-align: center;
  width: 100%;
  color: #ffffff;
  background: linear-gradient(180deg, #FFCBAA 0%, #C3B9B4 100%);
  border-radius: 90px 90px 90px 90px;
  margin-bottom: 10px;
}

.answer {
  line-height: 12px;
  white-space: nowrap;
  cursor: pointer;
  background: #51607D;
  color: #ffffff;
  text-align: center;

  margin: 0;
  font-size: 20px;
  border-radius: 100px 100px 100px 100px;
  width: 450px;
  position: relative;
  display: table;
  padding: 15px 15px;
}

@media (max-width: 500px) {
  .answer {
    width: 90%;
    display: block;
    white-space: inherit;
  }

  .answer span {
   line-height: 20px;
  }
}

.answers {
  margin: 30px 0px 75px 0px;
  width: 100%;
}

.answer:not(:first-child) {
  margin-top: 12px;
}

.selectedAnswer {
  background: #D74EF8 !important;
  color: #263049 !important;
}

.correctAnswer {
  color: #547C5E!important;
  background: #FFFFFF !important;
  border: 1px solid #547C5E!important;
}

.lockAnswer {
  background-color: #ddd;
  color: #333;
}

.locked-icon {
  width: 14px;
  position: absolute;
  right: 20px;
  bottom: 12px;
}

.el-icon-star-on {
  color: #ebb563;
  font-size: 20px;
}

.wrongAnswer {
  color: #FF4B4A!important;
  background: #FFFFFF !important;
  border: 1px solid #FF4B4A!important;
}

.answer-icon {
  width: 26px;
  position: absolute;
  right: 20px;
  bottom: 25%;
}

.dialog-message .sub-message {
  font-size: 13px;
}
</style>
