
<template>

    <div class="mb-3 profile_page">

        <!-- user name container -->
        <div>
            <div class="user_name_container col-11 mx-auto">
                <div class="row">
                    <img src="../assets/profile/profileIcon.svg" alt="profile_icon">
                </div>
                <div class="row username"><h1>{{fullName}}</h1></div>
            </div>
        </div>
        <!-- end user name container -->


  <!-- user information -->

  <div class="d-flex justify-content-between col-12 px-4 mx-auto align-items-center mb-2 mt-2">

    <div class="col-4 ">
          <div class="card-info" :class="this.$i18n.locale == 'en' ? 'text-start' : 'text-end'" @click="goToRoute('ranking')">
      <div class="card-body">
        <h5 class="card-title">{{ isSubscribed ? ranking: '-' }}</h5>
        <h6 class="card-subtitle mb-2 text-muted">{{ $t("home.ranking") }}</h6>
      </div>
    </div>
    </div>

    <div class="col-4 ">
          <div class="card-info " :class="this.$i18n.locale == 'en' ? 'text-start' : 'text-end'" >
      <div class="card-body">
        <h5 class="card-title">{{ isSubscribed ? userPoints : '-' }}</h5>
        <h6 class="card-subtitle mb-2 text-muted">{{ $t("home.points") }}</h6>
      </div>
    </div>
    </div>

    <div class="col-4 ">
          <div class="card-info " :class="this.$i18n.locale == 'en' ? 'text-start' : 'text-end'" >
      <div class="card-body">
        <h5 class="card-title">{{ userBalance }}</h5>
        <h6 class="card-subtitle mb-2 text-muted">{{ $t("home.coins") }}</h6>
      </div>
    </div>
    </div>

  </div>

  <!-- end user information -->




          <!-- user other info -->
          <div class="mb-2">


               <!-- <button @click="openGetCoins" class="buying_coin_btn btn col-9" >Buy Coin</button> -->


            <div class="user_other_info_container d-flex col-11 mx-auto">

                    <div class="col-6 ">
                        <div class="row mb-3 ">
                            <h5 class="title">{{ $t("home.ranking") }}</h5>
                            <h4 class="value">{{ isSubscribed ? ranking: '-' }}</h4>
                        </div>
                        <div class="row mb-3">
                            <h5 class="title">{{ $t("home.coins") }}</h5>
                            <h4 class="value">{{ userBalance }}</h4>
                        </div>
                        <div class="row mb-3">
                            <h5 class="title">{{ $t("home.points") }}</h5>
                            <h4 class="value">{{ isSubscribed ? userPoints : '-' }}</h4>
                        </div>
                    </div>


                    <!-- phone email country -->
                    <div class="col-6 ">
                        <div class="row mb-3">
                            <h5 class="title-second" style="direction:ltr !important">{{ $t("inputs.phonenumber_short") }}</h5>
                            <h4 class="value">{{phone_number}}</h4>
                        </div>
                        <div class="row mb-3">
                            <h5 class="title-second">{{ $t("inputs.email") }}</h5>
                            <h4 class="value">{{email_address}}</h4>
                        </div>
                        <!-- <div class="row mb-3">
                            <h5 class="title-second">Country</h5>
                            <h4 class="value">Baghdad</h4>
                        </div> -->
                    </div>
                    <!-- end phone email country -->

            </div>
        </div>
           <!-- end user other info -->



    <!--  playmatch and head to head container -->
     <div class="d-flex justify-content-between px-4 col-12 align-items-center " style="margin-bottom:100px !important">

    <div class="col-6 ">
          <div class="card-match text-start" @click="goToRoute('coming-soon')">
            <div class="card-body d-flex justify-content-between align-items-center">
              <h5 class="card-title">{{ $t("home.headToHead") }}</h5>
              <h6 class="card-title">757</h6>

            </div>
    </div>
    </div>

    <div class="col-6 ">
          <div class="card-match text-start" @click="goToRoute('play-match')">
            <div class="card-body d-flex justify-content-between align-items-center">
              <h5 class="card-title">{{ $t("footer.playMatch") }}</h5>
              <h6 class="card-title">23456</h6>
            </div>
    </div>
    </div>

  </div>
  <!-- end playmatch and head to head container -->



    </div>
</template>


<script>
import { store, notify } from "../store"
import { Service } from "../services"

export default {
    data() {
        return {
            ranking: "",
            fullName: "",
            phone_number: "",
            email_address: "",
        }
    },
    methods:{
        openGetCoins() {
      store.layout.showGetCoins = true
    },
        async getUserInfo(){
            let response = await Service.getUserInfo();
            if (response && response.status === 200) {
                console.log(response.data)
                store.userBalance = response.data.balance
                store.userPoints = response.data.currentPoints
                store.subscribed = response.data.subscribed
                this.ranking = response.data.currentRanking
                this.phone_number = response.data.phoneNumber
                this.email_address = response.data.email
                this.fullName = response.data.firstName + " " + response.data.lastName
            }
            else if (response && response.status === 409) {
                notify(this.$t("error.error"),  this.$t("error.waiting_subscription"), "warning", false)
                store.layout.showLogoutOverlay = true
                setTimeout(async () => {
                await this.getUserInfo()
                }, 5000)
            }
            }
    },
    computed:{
        userPoints() {
        return store.userPoints
        },
        userBalance() {
        return store.userBalance
        },
        isSubscribed(){
        return store.subscribed
        }
    },

    async mounted() {
    await this.getUserInfo();
    store.layout.showHeader = true
    store.layout.showUserWallet = false
    store.layout.footerItem = "home"
    store.layout.footerType = "FooterHome"
    store.layout.showGoBack = false
    store.layout.showLanguageSwitch = true
  },

}

</script>



<style scoped>


    .user_name_container{
        background: #5D2C85 !important;
        padding: 40px !important;
        border-radius: 20px !important;
    }
    .user_name_container img{
        width: 200px;
        margin: 0 auto !important;
    }

    .user_name_container .username{
        text-align: center;
        color: #FFFFFF;
        font-size: 24px;
        font-weight: 400;
    }



.card-info{
  background: #51105F !important;
  border-radius: 20px !important;
  color:#C1DEFF !important;
  margin:0px 3px !important;
  cursor: pointer;
}


.card-info .card-body{
  padding: 10px 15px 5px 15px !important;
}


.card-info .card-title{
  color:#C1DEFF !important
}
.card-info .card-subtitle{
  color:#C1DEFF !important
}


/* card match */

.card-match{
  background:#131C47 !important;
  border-radius: 15px !important;
  color:#C1DEFF !important;
  margin:0px 3px !important;
  cursor: pointer !important;
}


.card-match .card-body{
  /* padding: 30px 15px 20px 15px !important; */
  height: 120px !important;
  padding-bottom: 5px !important;
}


.card-match .card-title{
  color:#ffffff !important;
  font-size: 25px !important;
}
.card-match .card-subtitle{
  color:#f7f7f7 !important
}



.user_other_info_container{
    background: #182252 !important;
    padding: 45px 20px !important;
    border-radius: 10px !important;
}

.user_other_info_container .title{
    color: #C1DEFF;
    font-weight: 400;
}
.user_other_info_container .title-second{
    color: #FFCBAA;
    font-weight: 400;
}
.user_other_info_container .value{
    color: #ffffff;
    font-weight: 400;
}

.buying_coin_btn{
    margin: 0 auto;
    cursor: pointer;
    background:#FFCBAA ;
    color: #0C1538;
    border-radius: 20px !important;
    padding: 5px 60px !important;
    font-size: 35px !important;
}

</style>