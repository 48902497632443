<template>
  <div class="content-page">
    <div class="login-content" align="center">

      <el-select style="direction:ltr !important;" v-if="showLanguageSwitch" @change="changeLanguage"
        popper-class="popper-lang" v-model="selectedLang" placeholder="Language">
        <template slot="prefix"></template>
        <el-option value="en">{{ $t("general.en") }}</el-option>
        <el-option value="ar">{{ $t("general.ar") }}</el-option>
        <el-option value="ku">{{ $t("general.ku") }}</el-option>
      </el-select>

      <div class="logo">
        <img src="../assets/goalpro.svg" alt="logo" />
      </div>


      <div class="legend">
        {{ $t("login.enter_details") }}
      </div>
      <div class="col-10">
        <label for="">{{ $t('inputs.email_phone') }}</label>
        <CustomInput :register="true" 
          :value="this.loginKey" type="phonenumber" style="margin-top: 15px"
          :label="$t('inputs.email_phone')"
          @setInput="onInputLoginKey">
        </CustomInput>
        <div :class="{
          'custom-button': true,
          'custom-button-valid':
            this.loginKey.length > 0 && this.password.length > 0,
        }" id="cta_button" @click="login">
          {{ $t("login.login") }}
        </div>
        <div class="description" @click="toogleKeepLogged">
          <span> {{ $t('login.disclaimer') }} </span><br>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { maska } from "maska";
import i18n from "@/translation";
import { v4 as uuidv4 } from "uuid";
import { store, notify } from "../store";
import { Service } from "../services";
import moment from "moment";

import CustomInput from "../components/CustomInput.vue";

export default {
  name: "LoginComponent",
  props: {
    msg: String,
  },
  components: {
    CustomInput,
  },
  directives: { maska },
  data() {
    return {
      operator: "",
      phoneNumber: "",
      loginKey: "",
      password: "",
      buttonValid: false,
      selectedLang: "EN",
    };
  },
  methods: {
    changeLanguage() {
      i18n.locale = this.selectedLang
      localStorage.removeItem("lang");
      localStorage.setItem("lang", this.selectedLang);
      this.$i18n.locale === 'en' ? moment.locale("en") : this.$i18n.locale === 'ar' ? moment.locale("ar") : moment.locale("ku")
      this.$router.go();
    },
    goToRoute(route) {
      this.$router.push("/" + route);
    },
    onInputLoginKey(value) {
      this.loginKey = value;
    },
    onInputPassword(value) {
      this.password = value;
    },
    toogleKeepLogged() {
      store.keepLogged = !store.keepLogged;
    },
    setInputFocus() {
      setTimeout(() => {
        document.querySelector("input#phoneNumber").focus();
      }, 50);
    },
    isEmailValid() {
      const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return expression.test(this.loginKey);
    },
    containsOnlyNumbers(value) {
      return /^\d+$/.test(value);
    },
    viewTerms() {
      this.$router.push("terms-and-conditions");
    },
    viewPrivacy() {
      this.$router.push("privacy-policy");
    },
    async login() {
    console.log(this.loginKey);
      if (this.loginKey.length != 12) {
        notify("Error", this.$t("error.invalid_phone_number"), "error", !false);
        return;
      } else {
        if (this.loginKey == "1234567890") {
          store.userName = this.loginKey;
          store.ti = "WC-Web-App-" + uuidv4();
          store.phoneNumber = this.loginKey.replace(/[^0-9]/g, '');
          store.ignoreDefaultError = true
          let response = await Service.loginRequestPinCode(store.phoneNumber);
          if (response && response.status == 200) {
            this.goToRoute("login-otp");
          }

        } else {
          store.userName = this.loginKey;
          store.ti = "WC-Web-App-" + uuidv4();
          store.phoneNumber = this.loginKey.replace(/[^0-9]/g, '');
          store.ignoreDefaultError = true
          let response = await Service.loginRequestPinCode(store.phoneNumber);

          if (response.data.Code == "10204") {
            this.goToRoute("home");
          }

          if (response && response.status == 200) {
            this.goToRoute("login-otp");
          } else if (response && response.status == 419) {
            notify(this.$t("error.error"), this.$t("error.otp_request_not_subscribed"), "error");
          } else if (response && response.status == 409) {
            notify(this.$t("error.error"), this.$t("error.otp_request_exceeded"), "error");
            this.goToRoute("login-otp");
          }
          else {
            notify(this.$t("error.error"), this.$t("error.invalid_phone_number"), "error");
          }
        }
      }
    },
    forgotPassword() {
      if (this.loginKey.length > 0) {
        store.userName = this.loginKey;
        this.goToRoute("forgot-password-security-questions");
      } else {
        notify(
          this.$t("error.error"),
          this.$t("login.invalid_username"),
          "error"
        );
      }
    },
    checkToken() {
      console.log("checkToken");
      if (this.$route.query.token) {
        store.userToken = this.$route.query.token;
        localStorage.setItem("userToken", store.userToken);
        this.$router.push("/home");
      }
    },
  },
  computed: {
    language() {
      return i18n.locale;
    },
    keepLogged() {
      return store.keepLogged;
    },
    Algin() {
      return this.language == "en" ? "left" : "right";
    },
    showLanguageSwitch() {
      return store.layout.showLanguageSwitch;
    },
  },
  mounted() {
    this.checkToken();
    this.phoneNumber = store.phoneNumber;
    store.layout.footerType = "FooterInitial";
    store.layout.textHeader = "Sign Up";
    store.layout.showLanguageSwitch = true;
    store.layout.showHeader = false;

    this.selectedLang = localStorage.getItem("lang") ? localStorage.getItem("lang").toUpperCase() : "EN";
  },
};
</script>

<style scoped>
.content-page {
  background-image: url("../assets/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100% !important;
}



.logo {
  margin-top: 140px;
}


.legend {
  width: 100%;
  color: #fff;
  font-size: 35px;
  /* padding-bottom: 30px; */
  margin: 90px 0px !important;

}

.description {
  width: 100%;
  color: #FFFFFF;
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.forgot-password {
  color: #aaff03;
  display: block;
  text-align: right;
  margin-right: 20px;
  margin-top: 3px;
  font-size: 15px;
}

.keep-logged {
  display: flex;
  justify-content: left;
  margin-left: 5px;
  color: #ffffff;
  font-size: 14px;
  margin-top: 8px;
}

label {
  color: #ffffff;
  font-size: 14px;
  display: block;
  margin-bottom: -10px;
  text-align: v-bind(Algin);

}

.custom-button {
  background-color: #D74EF8;
  color: #FFFFFF;
  margin-top: 10px;
  height: 40px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  align-content: center;
}

.custom-button-valid {
  background-color: #aaff03;
  color: #26314a;
}

.keep-logged span {
  margin-left: 5px;
}

.keep-logged img {
  width: 12px;
}

.signup-text {
  margin-top: 25px;
  font-family: Poppins;
  font-size: 10px;
  color: #ffffff;
}

.signup-text span {
  color: #D74EF8;
  margin-left: 5px;
}

.footer-text {
  color: #ffffff;
}





.el-select {
  background-color: #232C40;
  margin-right: 20px;
  border-radius: 3px;
  float: inline-start !important;
  margin: 16px 23px !important;
}

.el-select>>>.el-input__inner {
  background-color: #b0aeb100 !important;
  width: 60px !important;
  color: #D74EF8;
  border: none;
  height: 25px;
  padding-right: 0 !important;
  display: flex;
  height: 28px;
  line-height: 28px;
}

.el-select>>>.el-input__prefix {
  top: 5px !important;
  left: 5px;
  cursor: pointer;
}

.el-select>>>.el-input__prefix {
  top: 5px !important;
  left: 5px;
  cursor: pointer;
}

li.el-select-dropdown__item {
  padding: 2px 0px !important;
  text-align: center;
  border-bottom: #212735;
  font-size: 16px;
}

ul.el-select-dropdown__list {
  padding: 10px 0 0 0;
}

.el-select>>>.el-input__inner,
.el-select>>>.el-input__inner:focus {
  border-color: #C0C4CC !important;
}

.el-select>>>.el-select__caret {
  top: 4px;
  width: 0;
  right: 5px;
  height: 0;
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  border-right: 6px solid #D74EF8;
  position: relative;
  transform: none;
}

.el-select>>>.el-select__caret.is-reverse {
  transform: rotateZ(-90deg) !important;
}

.el-select>>>.el-icon-arrow-up:before,
.el-select>>>.el-icon-arrow-up:after {
  display: none;
}

.el-select-dropdown__item.selected {
  color: #232c40 !important;
  font-size: 20x;
}

.el-select>>>.el-select-dropdown__wrap.el-scrollbar__wrap {
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}
</style>
