import { io } from "socket.io-client";
import { store } from "./store";

class SocketioService  {
    
    socket = null
    connectionAttempts = 0

    constructor() {
        this.socket = io(store.WSS_URL, { 
            autoConnect: false,
            transports: ['websocket'],
        })
    }

    addListener(message, callback){
        this.socket.on(message, (response) => {
            callback(response)
        })
    }

    emit(message, data){
        data["token"] = store.userToken ? store.userToken : ""    
        this.socket.emit(message, data)
    }
}

export default new SocketioService();