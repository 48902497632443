<template>
  <div class="more">
    <div class="arrow-title">
      <div class="arrow">
        <img @click="goBack" :src="require('@/assets/arrow.svg')"/>
      </div>
      <div class="title">{{ $t("comingSoon.title") }}</div>  
    </div>

    
    <div class="items">
        <!-- <p>{{ $t("comingSoon.title") }}</p> -->
        <img src="../assets/H2H_01.png" style="max-width: 80%;"/>
        <img src="../assets/H2H_02.png" style="max-width: 80%;"/>
        <img src="../assets/H2H_03.png" style="max-width: 80%;"/>
    </div>
  </div>
</template>
  
<script>
import i18n from "@/translation";
import { store, notify } from "../store";
import router from "../router";

export default {
  name: "NewsMatchResultsComponent",
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
  computed: {
  },
  mounted() {
    store.layout.showHeader = true
    store.layout.footerType = ""
    store.layout.footerItem = ""
    store.layout.showUserWallet = false
    store.layout.showLanguageSwitch = true

  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.more {
  flex-direction: column;
  display: flex;
  height: 100%;
}

.arrow-title {
    display: flex;
    justify-items: center;
    align-items: center;
    height: 54px;
  }
  .arrow {
    padding-left: 15px;
    width: 55px;
  }

  .arrow-title img {
    vertical-align: middle;
  }

  .arrow-title .title {
    padding-right: 55px;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-size: cover;
    width: 100%;
    color: #fff;
    padding: 0;
    font-family: 'Rabar_031';
    font-size: 26px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
  }

.items {
  padding-top: 70px;
  background-color: #232c41;
  color: #ffffff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  flex-grow: 1;
}

.items p {
  color:#aaff01;
  font-size: 38.492px;
}

</style>
  