<template>
  <div align="center">
    <div class="arrow-title">
      <div class="arrow">
        <img @click="goBack" :src="require('@/assets/arrow.svg')"/>
      </div>
      <div class="title">{{ $t("forgot_password.title") }}</div>            
    </div>
    <div style="max-width: 250px; padding-top:70px;">
      <CustomInput 
        style="margin-top: 15px;"
        :value="this.password"
        :type="'password'" 
        :label="$t('inputs.create_password')" 
        :placeholder="$t('inputs.create_password_placeholder')"
        @setInput="onInputPassword">
      </CustomInput>
      <CustomInput 
        style="margin-top: 15px;"
        :value="this.confirmPassword"
        :type="'password'" 
        :label="$t('inputs.confirm_password')" 
        :placeholder="$t('inputs.confirm_password_placeholder')" 
        @setInput="onInputConfirmPassword">
      </CustomInput>
      <div>
        <el-button
            @click="validatePassword"
            :class="{'button': true, 'button-valid': (this.password.length >= 8 &&this.confirmPassword.length >= 8)}"
            type="primary">
            {{ $t("forgot_password.save") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { maska } from "maska";
import i18n from "@/translation";
import { store, notify } from "../store";
import { Service } from "../services";
import CustomInput from "../components/CustomInput.vue"

export default {
  name: "ForgotPasswordCreatePasswordComponent",
  props: {
    msg: String,
  },
  components: {
    CustomInput
  },
  directives: { maska },
  data() {
    return {
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    goToRoute(route) {
      this.$router.push("/" + route);
    },
    onInputPassword(event){
      this.password = event;
    },
    onInputConfirmPassword(event){
      this.confirmPassword = event;
    },
    validatePassword(){
      let passwordValid = true

      //for password
      if(this.password.length == 0){
          notify(this.$t("error.error"),this.$t("error.password.required"),"error");
          passwordValid = false;
          return;
        }else{
          if(this.password != this.confirmPassword){
            notify(this.$t("error.error"),this.$t("error.password.confirm_identical"),"error");
            passwordValid = false;
            return;
          }else{
            // Password must be at least 8 characters long
            if (this.password.length < 8) {
              notify(this.$t("error.error"),this.$t("error.password.eight_character"),"error");
              passwordValid = false;
              return;
            }
            // Password must contain at least one lowercase letter
            if (!/[a-z]/.test(this.password)) {
              notify(this.$t("error.error"),this.$t("error.password.one_lower"),"error");
              passwordValid = false;
              return;
            }
            // Password must contain at least one uppercase letter
            if (!/[A-Z]/.test(this.password)) {
              notify(this.$t("error.error"),this.$t("error.password.one_upper"),"error");
              passwordValid = false;
              return;
            }
            // Password must contain at least one digit
            if (!/\d/.test(this.password)) {
              notify(this.$t("error.error"),this.$t("error.password.one_digit"),"error");
              passwordValid = false;
              return;
            }
            // Password must contain at least one special character
            if (!/[^a-zA-Z0-9]/.test(this.password)) {
              notify(this.$t("error.error"),this.$t("error.password.one_symbol"),"error");
              passwordValid = false;
              return;
            }
          }
        }

        if(passwordValid){
          this.changePassword();
        }
    },
    async changePassword(){
      await Service.changePassword(this.password,this.confirmPassword).then(response =>{
          if(response.status == 200){
            store.userToken = "";
            this.$router.push({ path: '/' })
          }
        });
    },
  },
  computed: {
    language() {
      return i18n.locale;
    },
    returnInnerWidth(){
      return window.innerWidth;
    }
  },
  mounted() {
    store.layout.showGoBack = false;
    store.layout.footerType = 'FooterInitial'
    store.layout.showUserWallet = false;
    store.layout.textHeader = 'Log In'
    store.layout.showLanguageSwitch = true
  },
};
</script>

<style scoped>
.arrow-title {
    display: flex;
    justify-items: center;
    align-items: center;
    height: 54px;
  }
  .arrow {
    padding-left: 15px;
    width: 55px;
  }

  .arrow-title img {
    vertical-align: middle;
  }

  .arrow-title .title {
    padding-right: 55px;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-size: cover;
    width: 100%;
    color: #fff;
    padding: 0;
    font-family: 'Rabar_031';
    font-size: 26px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
  }
.button-valid {
  background-color: #aaff03 !important;
  color: #26314A !important;
}
.button{
  width: 100%; 
  border-radius: 6px !important;
  font-family: 'Rabar_031';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #fff;
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}
.el-button--primary {
  background-color: #445C87;
  border: 0px;
  border-radius: 20px;
  line-height: 15px !important;
}

@media (max-width: 600px) {
  .login-icons-page {
    font-size: 14px;
    margin-top: 30px;
  }

  .login-icons-page > div{
    width: 100px;
  }
  .login-icons-page > div img{
    height: 20px;
    margin-left: 5px;
  }
}
</style>
