<template>
    <div class="winner">
        <div class="photo-name">
            <div :class="['photo', size]">
                <img :class="size" :src="image"/>
            </div>
            <div :class="['name', size]">{{ name }}</div>
        </div>
        <div :class="['prize', size]">
            <div :class="['prize-amount', size]">
                <div>{{ amount }}</div>
                <div> {{ $t("general.currency")}}</div>
            </div>
            <div :class="['winner-text', size]">{{ message }}</div>
        </div>
    </div>
  </template>
  
  <script>

  import i18n from "@/translation";
  
  export default {
    name: "WinneItemComponent",
    props: ["size", "image", "name", "amount", "message"],
    data() {
      return { }
    },
    methods: {       
    },
    computed: {
      language() {
        return i18n.locale;
      },
    }
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  .winner {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
  }

  .photo-name {
    display: flex;
    justify-content: center;
  }

  .photo img {
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
    position: absolute;
    left: 0;
    z-index: 20;
  }

  .photo img.large {
    width:135px;
  }
  .photo img.small {
    width:65px;
  }

  .photo img.medium {
    width:100px;
  }

  .photo {
    margin: 10px;
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
    position: relative;
  }
  .photo.large {
    width:135px;
    height: 135px;
    border:12px solid #ffffff;
  }

  .photo.small {
    width:65px;
    height: 65px;
    border:7px solid #ffffff;
  }

  .photo.medium {
    width:100px;
    height: 100px;
    border:10px solid #ffffff;
  }

  .name {
    position: absolute;
    background-color: #aaff01;
    color: #232c41;
    border-radius: 9px;
    white-space: nowrap;
    z-index: 10;
    border: 2px solid #ffffff;
  }

  .name.large {
    font-size: 28px;
    padding: 16px 10px 2px 10px;
    border-radius: 9px;
    top: calc(50% + 0px);
    box-shadow: 6px 6px 3px 3px rgba(0, 0, 0, 0.521);
  }

  .name.small {
    font-size: 16px;
    padding: 3px 3px;
    top: calc(50% - 0px);
    box-shadow: 4px 4px 2px 2px rgba(0, 0, 0, 0.521);
  }

  .name.medium {
    font-size: 22px;
    padding: 10px 15px 0px 15px;
    top: calc(50% + 5px);
    box-shadow: 5px 5px 3px 3px rgba(0, 0, 0, 0.521);
  }

  .prize.large {
    display: flex;
    margin-top: 25px;
  }

  .prize.small {
    display: flex;
    margin-top: 15px;
  }

  .prize.medium {
    display: flex;
    margin-top: 20px;
  }
  
  .prize-amount div:nth-of-type(1){
    color: #aaff01;
  }

  .prize-amount div:nth-of-type(2){
    color: #aaff01;
    text-align: right;
  }

  .prize-amount.large div:nth-of-type(1){
    font-size: 36px;
    line-height: 28px;
  }

  .prize-amount.small div:nth-of-type(1){
    font-size: 18px;
    line-height: 15px;
  }

  .prize-amount.medium div:nth-of-type(1){
    font-size: 26px;
    line-height: 20px;
  }

  .prize-amount.large div:nth-of-type(2){
    font-size: 20px;
    margin-right: 5px;
  }
 
  .prize-amount.small div:nth-of-type(2){
    font-size: 12px;
    margin-right: 5px;
    line-height: 20px;
  }

  .prize-amount.medium div:nth-of-type(2){
    font-size: 16px;
    margin-right: 5px;
    line-height: 22px;
  }

  .winner-text.large {
    width: 50px;
    margin-left: 10px;
  }

  .winner-text.small {
    width: 35px;
    font-size: 10px;
    margin-left: 0px;
  }

  .winner-text.medium {
    width: 45px;
    font-size: 12px;
    margin-left: 0px;
  }

  </style>
  