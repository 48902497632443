<template>
  <div class="content-page">
    
    <UserInfoNav/>
    
    <div class="play-match-content" :style="{ visibility: isLoading ? 'hidden' : 'visible' }">
      <div class="fixed-header">
        
        <div class="match-days-tabs">
          <div v-for="day in days" :key="day" 
            :class="{'match-day-item': true, 'selected': day == currentMatchDay.day}" @click="showMatchDay(day)">
            <div>{{ moment(day, "YYYY-MM-DD").format("ddd") }}</div>
            <div>{{ moment(day, "YYYY-MM-DD").format("DD MMM") }}</div>
          </div>
      </div>
      </div>
      <div class="matches-content">             
        <div class="my-favourites">
          <div class="label-favourites">
            <img :src="require('@/assets/star-white.svg')">
            <div>{{ $t("playMatch.my_favourites")}}</div>
          </div>
          <div @click="showFavourites" class="edit-favourites">{{ $t("playMatch.edit_favourites") }}</div>
        </div>
        
        <div class="matches">
          
          <MatchComponent
            v-for="(match, j) in currentMatchFavourites"
            :key="j"
            :matchDay="currentMatchDay"
            :isMatchResult="match.readonly"
            @setSecondChoice="onSetSecondChoice"
            @setMatchDayCurrentCoins="onUpdateMatchDayDebit"
            @setMatchSelected="onSetMatchSelected"
            @setMatchPoints="onSetMatchPoints"
            :match="match"
            :index="j">
          </MatchComponent>
        </div>
        
        <div v-for="(season, j) in currentMatchDay.seasons" :key="j">         
          <div class="season-title">
            {{ season.description }}
          </div>
          <div class="matches">
            <MatchComponent
              v-for="(match, j) in season.matches"
              :key="j"
              :matchDay="currentMatchDay"
              :isMatchResult="match.readonly"
              @setSecondChoice="onSetSecondChoice"
              @setMatchDayCurrentCoins="onUpdateMatchDayDebit"
              @setMatchSelected="onSetMatchSelected"
              @setMatchPoints="onSetMatchPoints"
              :match="match"
              :index="j">
            </MatchComponent>
          </div>
        </div>
        
        <div v-if="!isMatchDayResult" class="checkout-container">
          <div v-if="currentMatchDay.matches.filter((m) => m.bets.length < 1).length == currentMatchDay.matches.length || currentMatchDay.matches.filter((m) => m.editing).length > 0" @click="checkoutMatch(currentMatchDay)" class="checkout">
            <span> {{ $t("playMatch.checkout") }} </span>
          </div>
          <div v-else @click="editMatch(currentMatchDay)" class="checkout">
            {{ $t("playMatch.edit_prediction") }}
          </div>
        </div>
        
      </div>  
      
          
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { store, notify } from "../store";
import i18n from "@/translation";
import utils from "../utils";
import moment from "moment";
import { Service } from "../services";
import MatchComponent from "../components/Match.vue";
import UserInfoNav from "../components/UserInfoNav.vue";

export default {
  name: "PlayMatchComponent",
  mixins: [utils],
  components: {
    MatchComponent,
    UserInfoNav
  },
  data() {
    return {
      matchDays: [],
      days: [],
      currentMatchDay: {matches: []},
      userFavourites: [],
      currentMatchFavourites: []
    }
  },
  methods: {
    moment: (date, format) => {
      
      return moment(date, format);
    },

    showFavourites() {
      this.$router.push({name: "favourites", params: { showUserFavourites: true }});
    },

    goBack() {
      this.$router.back();
    },

    checkoutMatch(matchDay) {
      if(matchDay.matches.filter(m => m.selected.length > 0).length < 1){
        notify(this.$t("error.error"), this.$t("playMatch.select_match"),"error");
        return
      }
      store.currentMatchDayCheckout = matchDay
      store.currentMatchDayCoins = matchDay.currentCoins
      
      //let mockChechout = matchDay.matches.map(m => { delete m.season; return m })
      //localStorage.setItem('mockChechout', JSON.stringify(mockChechout))

      this.$router.push({name: "checkout", params: { matchDay: matchDay }});
    },

    editMatch(matchDay){
      let allowSecondChoices = matchDay.matches.filter((m) => {
        m.editing = true
        let totalBets = 0
        if(m.bets.length > 0){
          if(m.bets[0].homeWin) totalBets++
          if(m.bets[0].draw) totalBets++
          if(m.bets[0].awayWin) totalBets++
        }
        return (totalBets < 2)
      });
      allowSecondChoices.forEach((m) => {
        m.bets = []
      })
    },

    setPlayMatchData(data) {
      data.forEach((d) => {
        d.matches = []
        if(d.seasons && d.seasons.length > 0){
          d.seasons.forEach(s => {
            s.matches.forEach((m) => {
              d.matches.push(m)
              m.secondChoice = !m.decisive
              m.bets = m.bets ? m.bets : []
              m.preBets = m.bets
              m.selected = m.bets && m.bets.length > 0 ? this.getBetsSelectedTeams(m) : []
              m.points = m.bets && m.bets.length > 0 ? m.bets[0].points : 100
              m.minPoints = m.preBets && m.preBets.length > 0 ? m.preBets[0].points : 100
              m.editing = false
            })
          })  
          d.currentCoins = 0;
        }
      });
      this.matchDays = data;
      this.days = this.matchDays.map(md => md.day)
      this.currentMatchDay = this.matchDays[Math.floor(this.days.length/2)]
      this.currentMatchFavourites = this.currentMatchDay.matches.filter(m => this.isFavouriteMatch(m))
    },

    getBetsSelectedTeams(match) {
      let selectedID = [];
      if (match.bets[0].awayWin) selectedID.push(match.awayTeam.id);
      if (match.bets[0].draw) selectedID.push("draw");
      if (match.bets[0].homeWin) selectedID.push(match.homeTeam.id);
      return selectedID;
    },

    onSetSecondChoice(match) {
      match.secondChoice = true;
    },

    onUpdateMatchDayDebit(matchDay, amount) {
      matchDay.currentCoins = amount;
    },

    onSetMatchSelected(match, team_id) {
      let i = match.selected.indexOf(team_id);
      i < 0 ? match.selected.push(team_id) : match.selected.splice(i, 1);
    },

    onSetMatchPoints(match, points) {
      match.points = points;
    },
    
    async getMatchDay() {
      let response = await Service.getMatchDay();
      if (response && response.status === 200) {
        this.setPlayMatchData(response.data);
      }
    },

    isFavouriteMatch(match){
      return this.userFavourites.includes(match.awayTeam.id) || this.userFavourites.includes(match.homeTeam.id)  
    },

    showMatchDay(day){
      this.currentMatchDay = this.matchDays.find(md => md.day == day)
      this.currentMatchFavourites = this.currentMatchDay.matches.filter((m) => { return this.isFavouriteMatch(m) })
    },

    setCurrentMatchDay(){
      let current = this.matchDays.find(md => {
        return md.id == store.currentMatchDayCheckout.id
      })
      current.matches = store.currentMatchDayCheckout.matches
      current.matches.forEach((m) => { m.bets = m.preBets; m.editing = false });
      setTimeout(() => {
        current.currentCoins = store.currentMatchDayCoins
      },100)
    },

    setMatchDaysMiddlePage(){
      Vue.nextTick(() => {
        document.querySelector('.match-days-tabs').scrollLeft = ((document.querySelector('.match-days-tabs').scrollWidth / 2) - (document.querySelector('.content').clientWidth / 2))
      });
    },
    
    async getUserFavourites(){
      let response = await Service.getUserFavourites();      
      this.userFavourites = response.data.map(d => { return d.teamId })
    }
  },
  computed: {
    
    isLoading() {
      return store.layout.showLoading;
    },
    language() {
      return i18n.locale;
    },
    Service() {
      return Service;
    },
    userBalance() {
      return store.userBalance;
    },
    isMatchDayResult(){
      return !(this.currentMatchDay && this.currentMatchDay.matches.filter(m => !m.readonly).length > 0)
    }
  },
  async mounted() {
    store.layout.showGoBack = true
    store.layout.showUserWallet = true
    store.layout.footerItem = "play-match"
    store.layout.footerType = "FooterHome"
    store.layout.showLanguageSwitch = true

    await this.getUserFavourites()
    await this.getMatchDay()
    this.setMatchDaysMiddlePage()
    
    this.$i18n.locale === 'en' ? moment.locale("en") : this.$i18n.locale === 'ar' ? moment.locale("ar") : moment.locale("ku")

  },
  watch: {
    language() {
      Vue.nextTick(() => {});
    },
  },
};
</script>

<style scoped>

  .content-page {
    margin-bottom: 120px !important;
  }
  .checkout-container{
    margin-bottom: 120px !important;
  }
  
  
  
  .play-match-content {
    padding: 0px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .fixed-header {
    /* position: absolute; */
    width: 100%;
    z-index: 100;
  }

  .arrow-title {
    display: flex;
    justify-items: center;
    align-items: center;
    background: #ffffff
  }

  .arrow {
    padding-left: 15px;
    width: 45px;
  }

  .arrow-title img {
    vertical-align: middle;
    transform: rotate(180deg);
  }

  .arrow-title .title {
    padding-right: 45px;
  }
  .title {
    width: 100%;
    color: #232C40;
    font-size: 26px;
    padding: 0;
  
    padding: 3px 0;
  }

  .match-header-title {
    margin: 0px 0px 0px 15px;
    color: #ffffff;
  
    display: block;
    padding: 0px 0px 5px 0px;
    font-size: 18px;
  }

  .match-days-tabs {
    display: flex;
    color: #fff;
    background: #182252;
    justify-content: space-between;
    font-size: 14px;
    padding: 4px 20px;
    height: 60px;
    /* margin-bottom: 100px; */
  }

  .match-days-tabs {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  .match-days-tabs::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }

  .match-days-tabs {
    border: none;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: space-between;
  }

  .match-day-item {
    text-transform: capitalize;   
    margin: 3px 12px;
    flex: 0 0 auto;
    padding: 0px 5px;
  }

.matches-content {
  /* background: #232c40; */
  margin-bottom: 5px;
  overflow-y: scroll;
  /* height: calc(100% - 100px);
  padding-top: 100px; */
  -ms-overflow-style: none;
  scrollbar-width: none; 
}
.matches-content::-webkit-scrollbar { 
    display: none;  
}

.season-title {
  background-color: #3D3A4F;
  text-align: left;
  font-size: 13px;
  color: #ffffff;
  padding: 8px 12px;
}
.my-favourites {
  background-color: #3D3A4F;
  display: flex;
  font-size: 14px;
  color: #ffffff;
  padding: 8px 12px;
  justify-content: space-between;
  margin-bottom: 5px;
}

.label-favourites {
  display: flex;
  align-items: center;
}

.label-favourites img {
  margin-right: 5px;
}

.label-favourites div {
  padding-top: 2px;
}

.edit-favourites {
  padding-top: 2px;
}

.matches {
  border-top-left-radius: 10px;
}

.match-day {
  margin-top: 0px;
}

.match-day-item {
  /* padding: 0 10px; */
}

.match-day-item.selected {
  border:1px solid #D74EF8;
  height: fit-content;
  border-radius: 15px;
  padding: 0px 15px;
}

.match-date {
  font-size: 24px;
  padding: 6px;

  line-height: 26px;
  color: rgb(0, 0, 0);
  letter-spacing: 1px;
  text-align: right;
}

.checkout {
  width: 120px;
  font-size: 16px;
  color: #ffffff;
  background:linear-gradient(#F5C8AB,#CABBB2);
  border-radius: 15px;
  cursor: pointer;
  margin: 20px auto 10px auto;
  padding: 1px 1px;
}

</style>
