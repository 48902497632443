<template>
  <div class="content-page">
    <div v-if="statusItem" class="status-detail-content">
      <div class="fixed-header col-10 mx-auto">

        <div class="status-header">
          <div :class="{ active: type == 'current', current: true }">
            {{ $t("status.current") }}
          </div>
          <div :class="{ active: type == 'history', history: true }">
            {{ $t("status.history") }}
          </div>
        </div>
      </div>
      <div class="status-items">
        <div class="list-item">
          <div class="text-item">
            {{ moment(statusItem.day, "YYYY-MM-DD").format("DD/MM/YYYY") }}
          </div>
          <div class="points-item">
            {{ statusItem.points + " " + $t("status.points") }}
          </div>
        </div>
        <div class="detail-group-resume-info" v-for="(d, i) in statusItem.detail" :key="i">
          <div class="detail-match-resume">
            <div class="detail-match-teams" >
              {{ d.match }}
            </div>
            <div class="detail-match-bet" >
              <div class="detail-match-bet-result">{{ d.betTeams }}</div>
              <div class="detail-match-bet-text">{{ getMatchDayScore(d) }}</div>
            </div>
          </div>
          <div class="detail-match-bet-info">
            <img v-if="d.betWin === true" :src="require('@/assets/status/checked.png')"/>
            <img v-else-if="d.betWin === false" :src="require('@/assets/status/cancel.png')"/>
            <img v-else-if="!d.processed" :src="require('@/assets/status/awaiting.png')"/>
            <!--div class="detail-match-bet-warning">{{ 'Postponed' }}</div-->
          </div>
        </div>
        <div class="detail-match-bet-separator"></div>

        <div class="detail-resume mt-2" v-if="this.$i18n.locale === 'ku'" >
          {{
            $t("status.congratulations_kurdish") +
            " " +
            statusItem.points +
            " " +
            $t("status.collect_kurdish")
          }}
        </div>

        <div v-else class="detail-resume mt-2">
          {{
            $t("status.congratulations") +
            " " +
            statusItem.points +
            " " +
            $t("status.points")
          }}
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/translation";
import { store, notify } from "../store";
import moment from "moment";

export default {
  name: "StatusDetailComponent",
  props: ["statusItem", "type"],
  data() {
    return {};
  },
  methods: {
    moment: (date) => {
      return moment(date);
    },

    goBack() {
      this.$router.back();
    },

    getMatchDayScore(detail) {
      if (detail.processed) return detail.scores;
      else
        return "(" + this.$t("general.daysOfWeek[" + moment(detail.matchStart).day() + "]") + moment(detail.matchStart).format(" HH:mm A)")
    },
  },
  computed: {},
  mounted() {
    if (!this.statusItem)
      this.$router.push("/status");
    else {
      store.layout.showUserWallet = true;
      store.layout.footerItem = "status";
      store.layout.showGoBack = true;
      store.layout.footerType = "FooterHome";
      store.layout.showLanguageSwitch = true

    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.content-page {
  justify-content: flex-start;
}
.status-detail-content {
  padding: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.arrow-title {
  display: flex;
  justify-items: center;
  align-items: center;
}

.arrow {
  padding-left: 15px;
  width: 55px;
}

.arrow-title img {
  vertical-align: middle;
}

.arrow-title .title {
  padding-right: 55px;
}

.title {
  background-size: cover;
  width: 100%;
  color: #ffffff;
  font-size: 26px;
  padding: 0;

  padding: 8px 0;
  background: none
}

.status-items {
  background-color: #3D3A4F;
  color: #ffffff;
  padding-bottom: 0px;
  /* height: calc(100% - 90px); */
  margin-top: 20px;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 15px;
  direction: ltr !important;
}

.status-header {
  color: #2a3757;
  background-color: #182252;
  color: #ffffff;
  display: flex;
  font-size: 22px;
  padding: 2px 0;

  border-radius: 15px;
  border-radius: 15px;
}

.current {
  width: 50%;
}

.history {
  width: 50%;
}

.current.active {
  background: linear-gradient(#3046BA,#18235D);
  color: white;
  border-radius: 15px;
  border-bottom: none;
}

.history.active {
  background: linear-gradient(#3046BA,#18235D);
  color: white;
  border-radius: 15px;
  border-bottom: none;
}

.text-item {
  align-self: center;
  margin-right: auto;
  font-size: 15px;
  color: #ffffff;
  padding-left: 20px;
}

.points-item {
  align-self: center;
  padding-right: 30px;
  font-size: 15px;
  color: #D74EF8;
}

.list-item {
  display: flex;
  background-color:transparent;
  border-radius: 6px;
  padding: 5px 10px 7px 10px;
  /* width: 65%; */
  margin: 0 auto;
  justify-content: right;
  margin-top: 12px;
}

.list-item i {
  font-size: 16px;
  align-self: center;
  padding-right: 10px;
  margin-top: 0 !important;
}
.detail-group-resume-info {
  margin-top: 20px;
  display: flex;
}
.detail-match-teams {
  font-size: 12px;

  color: #ffffff;
  text-align: left;
  padding-left: 20px;
}
.detail-match-bet {
  display: flex;
  padding-bottom: 2px;
}

.detail-match-bet-separator {
  border-bottom: #FFCBAA solid 1px;
  margin: 4px 12px;
}
.detail-match-bet-result {
  font-size: 11px;
  color: #D74EF8;

  justify-self: flex-start;
  padding-left: 20px;
}

.detail-match-bet-text {
  color: #cfd1cb;
  font-size: 11px;
  margin-left: 12px;

}

.detail-match-bet-info {
  display: flex;
  align-self: center;
  margin-left: auto;
  padding-right: 25px;
}
.detail-match-bet-info-rtl {
  display: flex;
  align-self: center;
  margin-right: auto;
  padding-left: 25px;
}

.detail-match-bet-rtl {
  display: flex;
  padding-bottom: 2px;
  padding-right:20px !important;
}

.detail-match-teams {
  font-size: 12px;

  color: hsl(0, 0%, 100%);
  text-align: left;
  padding-right: 20px;
}
.detail-match-teams-rtl {
  font-size: 12px;

  color: #ffffff;
  text-align: right;
  padding-left: 20px;
}

.detail-match-bet-warning {
  font-size: 11px;
  color: #D74EF8;

}

.detail-resume {
  font-size: 13px;

  text-align: right;
  margin-right: 18px;
}
</style>
